<template>
  <div class="msg_detail">
    <back-step
      :goList="[
        {
          url: '/',
          name: `${$t('home.personal.personal.zy')}`
        }
      ]"
      :currentTxt="`${$t('home.personal.msgDetail.ggxq')}`"
    />
    <div class="detail_box">
      <div class="det_head">
        <h5 class="det__h5">{{ details.title }}</h5>
        <p class="det__p">{{ details.updateDatetime }}</p>
      </div>
      <div class="det_con" v-html="details.content"></div>
      <div class="flex-x-y-c footer">
        <div class="submit_btn flex-x-y-c curp " @click="subRead">
          {{ $t("home.personal.personal.qd") }}
        </div>
      </div>
      <div
        :style="language === 'en_US' ? 'right: 6.5rem' : ''"
        class="no_p flex-y-c"
      >
        <el-checkbox v-model="isChecked">
          <span class="main_theme_color_333">{{
            $t("home.personal.personal.bztx")
          }}</span>
        </el-checkbox>
      </div>
    </div>
    <FullLoading v-show="isLoading" />
  </div>
</template>

<script>
import BackStep from "../../cases/cases-detail/childrenPublic/backStep";
import FullLoading from "components/full-loading/full-loading";
import { querySmsDetail, smsRead } from "common/api/system";
import { mapState } from "vuex";
// import TYPES  from "../src/store/mutationTypes";
import TYPES  from "../../../store/mutationTypes";

export default {
  data() {
    return {
      details: {},
      isLoading: false,
      isChecked: false
    };
  },
  computed: {
    ...mapState({
      language: state => state.language
    })
  },
  created() {
    document.title = this.$t("home.personal.msgDetail.title");
    const announcementId = sessionStorage.getItem("announcementId");
    if (announcementId) {
      this.isLoading = true;
      querySmsDetail(announcementId)
        .then(data => {
          this.isLoading = false;
          this.details = data;
        })
        .catch(() => {
          this.isLoading = false;
        });
    }
  },
  methods: {
    subRead() {
      const announcementId = sessionStorage.getItem("announcementId");
      if (this.isChecked) {
        sessionStorage.setItem("isShowNews", false)
        this.$store.commit(TYPES.GET_IS_SHOW_NEWS, false);
        smsRead(announcementId).then(() => {
          this.$router.push("/");
        });
      } else {
        this.$router.push("/");
      }
    }
  },
  components: {
    BackStep,
    FullLoading
  }
};
</script>

<style scoped lang="scss">
.msg_detail {
  width: 16rem;
  margin: 0 auto;
  padding-top: 28px;
  .detail_box {
    height: 626px;
    background-color: #fff;
    .det_head {
      height: 86px;
      border-bottom: 0.01rem solid #e5e5e5;
      text-align: center;
      display: flex;
      align-items: center;
      flex-flow: column;
      justify-content: center;
      position: relative;
      .det__h5 {
        font-size: 0.2rem;
        height: 28px;
        color: $main_theme_color_333;
        margin-bottom: 0.04rem;
      }
      .det__p {
        color: $main_theme_color_999;
        height: 22px;
        font-size: 0.16rem;
      }
    }
    .det_con {
      color: $main_theme_color_333;
      width: 1224px;
      height: 319px;
      font-size: 16px;
      margin: 30px auto 0 auto;
      overflow: hidden;
    }
    .footer {
      margin-top: 88px;
    }
    .no_p {
      color: $main_theme_color_333;
      position: absolute;
      right: 7rem;
      bottom: 0.73rem;
    }
    .submit_btn {
      width: 176px;
      height: 42px;
      background: $main_theme_color;
      border-radius: 6px;
      font-size: 16px;
      color: #fff;
    }
  }
}
/deep/ .el-checkbox__inner {
  width: 16px !important;
  height: 16px !important;
  color: $main_theme_color_333;
}
</style>
