var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"preview_single"},[_c('div',{staticClass:"preview_s_left"},[_c('h5',{staticClass:"s_l_h5",class:{ main_theme_color_333: _vm.isOldCase }},[_c('span',{staticClass:"s_l_icon"}),_vm._v(" "+_vm._s(_vm.$t("casesDetail.publicCases.casesDiagnosis.zdyq"))+" ")]),_c('div',{staticClass:"s_l_box"},[_vm._l((_vm.casesData),function(item,index){return [(!item.isHide)?_c('div',{key:index,staticClass:"s_l_con"},[_c('div',{staticClass:"s_l_con_left txt-ellipsis2",style:(_vm.language === 'en_US'
                ? 'min-width: 2.4rem !important'
                : 'min-width: 1.7rem !important')},[_vm._v(" "+_vm._s(item.fIndex ? item.fIndex + ". " : "")+_vm._s(item.title ? item.title + ": " : "")+" ")]),_c('div',{staticClass:"s_l_con_right"},_vm._l((item.pList),function(pItem,pIndex){
                var _obj;
return _c('div',{key:pIndex,staticClass:"s_l_con_right_p",class:( _obj = {
                dib: pItem.dib
              }, _obj[pItem.parentName] = pItem.parentName, _obj )},[(pItem.value)?_c('p',{staticClass:"fz16"},[_vm._v(" "+_vm._s(_vm.details[pItem.filed])+" ")]):_vm._e(),(
                  pItem.onlyHeaderText &&
                    pItem.isShow &&
                    _vm.isShowHeaderText(pItem.childOptions)
                )?_c('p',{staticClass:"c-white fz16"},[_vm._v(" "+_vm._s(pItem.superiorText)+" ")]):_vm._e(),(
                  +item.fIndex === 8 &&
                    !_vm.isIRDT &&
                    ['A', 'E', 'I'].includes(_vm.productType)
                )?[(_vm.details[pItem.filed])?_c('div',{class:{
                    main_theme_color_333: _vm.isOldCase,
                    'r_strong sublevel': !pItem.secondLevel
                  }},[_c('div',{},[_vm._v(" "+_vm._s(pItem.secondLevel ? pItem.secondLevel + ":" : "")+" "+_vm._s(pItem.secondLevel ? _vm.disposeStair(pItem.filed, pItem.inquireKey) : "")+" ")]),(_vm.details[pItem.filed] && pItem.threeOptions)?_c('div',_vm._l((_vm.disposeSagittal({
                        inquireKey: pItem.inquireKey,
                        key: pItem.filed,
                        threeOptions: pItem.threeOptions
                      })),function(i){return _c('div',{domProps:{"innerHTML":_vm._s(i)}})}),0):_vm._e()]):_vm._e()]:[(
                    (+item.fIndex === 10 &&
                    pItem.sublevel &&
                    ['B', 'G', 'H'].includes(_vm.productType)
                      ? _vm.split(_vm.details[pItem.filed], ',').length > 1
                        ? true
                        : _vm.details[pItem.filed] === pItem.differentiateKey
                      : true) ||
                      [
                        'upHorizontalRelationshipValue',
                        'downHorizontalRelationshipValue'
                      ].includes(pItem.describeInputKey)
                  )?[(
                      (pItem.type !== 'note' && _vm.details[pItem.filed]) ||
                        pItem.identification
                    )?_c('span',{staticClass:"r_strong",class:{
                      sublevel:
                        pItem.sublevel &&
                        _vm.details[pItem.filed].includes(pItem.differentiateKey),
                      dib: pItem.identification,
                      main_theme_color_333: _vm.isOldCase,
                      sublevel_old:
                        _vm.isOldCase &&
                        pItem.sublevel &&
                        _vm.details[pItem.filed].includes(pItem.differentiateKey)
                    }},[(
                        pItem.identification &&
                          _vm.isShowHeaderText(pItem.childOptions)
                      )?_c('span',{class:pItem.className},[_vm._v(" "+_vm._s(pItem.identification)+" ")]):_vm._e(),(pItem.secondLevel)?_c('span',{staticClass:"mb1rem"},[_vm._v(" "+_vm._s(pItem.secondLevel)+" ")]):_vm._e(),(pItem.superiorText)?_c('span',{class:pItem.className},[_vm._v(" "+_vm._s(pItem.superiorText)+"： ")]):_vm._e(),(!pItem.identification)?[_c('div',{staticClass:"dib",class:pItem.answerClass},[(pItem.filed === 'gapCorrect')?_c('div',_vm._l((_vm.gapCorrectList),function(p,px){return _c('div',{key:px},[_vm._v(" "+_vm._s(_vm.resolutionDisposeText(pItem.inquireKey, p))+" ")])}),0):_c('span',{domProps:{"innerHTML":_vm._s(
                            _vm.disposeText({
                              inquireKey: pItem.inquireKey,
                              inquireKeys: pItem.inquireKeys,
                              key: pItem.filed,
                              differentiateKey: pItem.differentiateKey,
                              threeOptions: pItem.threeOptions,
                              parentText: pItem.parentText,
                              describeInputKey: pItem.describeInputKey
                            })
                          )}}),(pItem.describeInputKey)?_c('span',[_vm._v(" "+_vm._s(_vm.details[pItem.describeInputKey] ? (![ "downjawMiddleLineNote", "upjawMiddleLineNote" ].includes(pItem.filed) ? " - " : "") + _vm.details[pItem.describeInputKey] : "")+" "),_vm._v(" "+_vm._s([ "specify", "improve_middle_move_left_right", "improve_middle_move_left" ].includes(_vm.details[pItem.filed]) && pItem.isShowMM && _vm.details[pItem.describeInputKey] ? "mm" : "")+" ")]):_vm._e()])]:_vm._e()],2):_vm._e()]:_vm._e(),(pItem.type === 'note' && _vm.details[pItem.filed])?_c('div',{staticClass:"s_l_con_note wsp",class:{ s_l_con_note_note: _vm.isOldCase },style:(index === _vm.casesData.length - 1 ? { top: '0.3rem' } : {})},[_c('span',{staticClass:"r_strong",class:{ main_theme_color_333: _vm.isOldCase }},[_vm._v(" "+_vm._s(_vm.details[pItem.filed])+" "+_vm._s(pItem.input ? "mm" : "")+" ")])]):_vm._e(),(
                    (pItem.key === 'teeth' &&
                      (_vm.details[pItem.filed]
                        ? JSON.parse(_vm.details[pItem.filed])
                        : '')) ||
                      pItem.showTeeth
                  )?_c('ChildrenTeeth',{staticClass:"mb40 mt40",attrs:{"commonTeeth":_vm.commonTeeth,"readOnly":true,"patientType":pItem.type,"teethType":pItem.teethType}}):_vm._e(),(
                    pItem.key === 'interval' &&
                      ((_vm.details[pItem.filed] &&
                        _vm.isArray(_vm.details[pItem.filed].split(',')) &&
                        _vm.details[pItem.filed]
                          .split(',')
                          .includes('reserved_gap')) ||
                        pItem.filed === 'teeth')
                  )?_c('IntervalCorrect',{ref:"intervalCorrect",refInFor:true,staticClass:"mt10",attrs:{"readOnly":true,"commonTeeth":_vm.commonTeeth,"productType":_vm.productType,"uploadIntervalList":_vm.uploadIntervalList}}):_vm._e(),(
                    pItem.key === 'adjoin' &&
                      _vm.get(_vm.details, 'crowdCorrectSide') === 'chooseable'
                  )?_c('AdjoinDislodge',{ref:"adjoinDislodge",refInFor:true,staticClass:"mt20",attrs:{"isOldCase":_vm.isOldCase,"productType":_vm.productType,"readOnly":true,"crowdTeethList":_vm.crowdTeethList}}):_vm._e(),(pItem.veneer === 'veneer' && pItem.showVeneer)?_c('VeneerTeeth',{staticClass:"mb40 mt40",attrs:{"commonTeeth":_vm.veneerList,"readOnly":true,"patientType":pItem.type,"teethType":pItem.teethType}}):_vm._e(),(
                    pItem.filed === 'whetherToPrepareTeeth'
                      ? _vm.get(_vm.details, 'whetherToPrepareTeeth') === 'true' &&
                        pItem.showYysd
                      : pItem.showYysd
                  )?_c('YysdTeeth',{attrs:{"commonTeeth":_vm.commonTeeth,"isDark":_vm.isDark,"readOnly":true,"teethType":pItem.teethType,"childKey":pItem.childKey}}):_vm._e(),(pItem.key === 'reserve' && _vm.details[pItem.filed])?_c('ReserveDinterval',{ref:"intervalCorrect",refInFor:true,staticClass:"mt20",attrs:{"readOnly":true,"isOldCase":_vm.isOldCase,"crowdTeethList":_vm.uploadIntervalList,"commonTeeth":_vm.commonTeeth}}):_vm._e()]],2)}),0)]):_vm._e()]})],2)]),_c('full-loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}]})],1)}
var staticRenderFns = []

export { render, staticRenderFns }