<template>
  <ul :class="'w65'" class="teeth_ul" @click.stop="selectedTeeth">
    <li class="teeth_single">
      <div v-if="['veneer'].includes(patientType)" class="single_02">
        <p
          v-for="item in [11, 12, 13, 14, 15, 16, 17, 18]"
          :key="`teeth_${item}`"
          :data-key="item"
          :class="{
            is_active: selectList.indexOf(item) !== -1,
          }"
        >
          <span :data-key="item" class="single_02_txt">{{ item }}</span>
          <span class="tag_sp" :data-key="item"></span>
        </p>
      </div>
    </li>
    <li
      class="teeth_single"
      :class="{ children_height: patientType === 'children' }"
    >
      <div v-if="['veneer'].includes(patientType)" class="single_02">
        <p
          v-for="item in [21, 22, 23, 24, 25, 26, 27, 28]"
          :key="`teeth_${item}`"
          :data-key="item"
          :class="{
            is_active: selectList.indexOf(item) !== -1,
          }"
        >
          <span class="single_02_txt" :data-key="item">{{ item }}</span>
          <span class="tag_sp" :data-key="item"></span>
        </p>
      </div>
    </li>
    <li
      class="teeth_single"
      :class="{
        children_height: patientType === 'children',
        children_padding_top: patientType === 'children',
      }"
    >
      <div
        v-if="['youth', 'adult', 'veneer'].includes(patientType)"
        class="single_02"
      >
        <p
          v-for="item in [41, 42, 43, 44, 45, 46, 47, 48]"
          :key="`teeth_${item}`"
          :data-key="item"
          :class="{
            is_active: selectList.indexOf(item) !== -1,
          }"
        >
          <span :data-key="item" class="single_02_txt">{{ item }}</span>
          <span :data-key="item" class="tag_sp"></span>
        </p>
      </div>
    </li>
    <li
      class="teeth_single"
      :class="{
        children_height: patientType === 'children',
        children_padding_top: patientType === 'children',
      }"
    >
      <div
        v-if="['youth', 'adult', 'veneer'].includes(patientType)"
        class="single_02"
      >
        <p
          v-for="item in [31, 32, 33, 34, 35, 36, 37, 38]"
          :key="`teeth_${item}`"
          :data-key="item"
          :class="{
            is_active: selectList.indexOf(item) !== -1,
          }"
        >
          <span class="single_02_txt" :data-key="item">{{ item }}</span>
          <span
            class="tag_sp"
            :data-key="item"
          ></span>
        </p>
      </div>
    </li>
  </ul>
</template>

<script>
import { filter, map, isArray, each, get, pickBy, extend } from "lodash";

export default {
  data() {
    return {
      selectObjList: [],
    };
  },
  computed: {
    selectList() {
      return map(this.selectObjList, (item) => item && item.number);
    },
    veneerList() {
      return map(
        filter(this.selectObjList, (item) => item.veneer === "veneer"),
        (it) => it.number
      );
    },
    queryPort() {
      return this.$route.query.port;
    },
  },
  props: {
    //adult  youth  children
    patientType: {
      type: String,
      default: "youth",
    },
    teethType: {
      //牙齿种类 1: 选择牙齿 2不可移动 3不可设计 4 拔除
      type: Number | String,
      default: "",
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    commonTeeth: {
      type: Array,
      required: true,
    },
  },
  methods: {
    selectedTeeth(ev) {
      this.$forceUpdate();
      if (this.readOnly) return;
      const target = ev.target;
      if (
        target.tagName.toLocaleLowerCase() === "p" ||
        target.tagName.toLocaleLowerCase() === "span"
      ) {
        const key = +target.getAttribute("data-key");
        if (this.selectList.indexOf(key) !== -1) {
          this.selectList.splice(this.selectList.indexOf(key), 1);
        } else {
          this.selectList.push(key);
        }
      }
      this.updateTeeth()
    },
    updateTeeth() {
      if (this.readOnly) return;
      let list = [];
      each(this.selectList, item => {
        list.push({
          number: item,
          type: 10,
          veneer: 'veneer'
        })
      })
      this.$emit("updateTeeth", list);
    }
  },
  watch: {
    commonTeeth(val) {
      this.selectObjList = val;
    },
  },
  created() {
    if (isArray(this.commonTeeth)) {
      this.selectObjList = this.commonTeeth;
    }
  },
};
</script>

<style scoped lang="scss">
.teeth_ul {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.18rem;
  color: #999999;
  margin: 0 auto;
  .teeth_single {
    width: 50%;
    box-sizing: border-box;
    &:first-child {
      border-right: 0.01rem #c1c1c1 dashed;
      border-bottom: 0.01rem #c1c1c1 dashed;
      padding-bottom: 0.11rem;
      padding-right: 0.09rem;
      .single_01 {
        margin-bottom: 0.26rem;
        flex-direction: row-reverse;
      }
      .single_02 {
        flex-direction: row-reverse;
      }
      p {
        margin-left: 0.18rem;
      }
    }
    &:last-child {
      border-left: 0.01rem #c1c1c1 dashed;
      border-top: 0.01rem #c1c1c1 dashed;
      padding-top: 0.14rem;
      padding-left: 0.09rem;
      transform: translate(-0.01rem, -0.01rem);
      .single_01 {
        margin-top: 0.26rem;
      }
      p {
        margin-right: 0.18rem;
      }
    }
    &:nth-of-type(2) {
      padding-bottom: 0.11rem;
      padding-left: 0.09rem;
      .single_01 {
        margin-bottom: 0.26rem;
      }
      p {
        margin-right: 0.18rem;
      }
    }
    &:nth-of-type(3) {
      padding-right: 0.09rem;
      padding-top: 0.14rem;
      .single_01 {
        margin-top: 0.26rem;
        flex-direction: row-reverse;
      }
      .single_02 {
        flex-direction: row-reverse;
      }
      p {
        margin-left: 0.18rem;
      }
    }
    .single_01 {
      display: flex;
      align-items: center;
      p {
        border: 0.01rem solid #c8c8c8;
        box-sizing: border-box;
        overflow: hidden;
        cursor: pointer;
      }
      .single_ac_p {
        border: 0.01rem solid #FCC80E;
        color: #FCC80E;
      }
    }
    .single_02 {
      display: flex;
      align-items: center;
      p {
        border: 0.01rem solid #c8c8c8;
        box-sizing: border-box;
        cursor: pointer;
        overflow: hidden;
      }
    }
    p {
      min-width: 0.32rem;
      min-height: 0.32rem;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.06rem;
      .single_02_txt {
        width: 100%;
        text-align: center;
        font-size: 14px;
        position: absolute;
        right: 50%;
        top: 50%;
        transform: translate(50%, -50%);
        font-size: 0.2rem;
      }
      .txt_active {
        color: #FCC80E;
        top: 35%;
        right: 35%;
        font-size: 0.2rem;
        z-index: 2;
      }
      .tag_sp {
        position: absolute;
        left: -1px;
        bottom: -1px;
        right: -1px;
        top: -1px;
        display: inline-block;
        background-size: (100% 100%);
      }
    }
    .single_mdl {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 9;
      cursor: pointer;
    }
  }
  .teeth_missing {
    background-image: url("/static/images/createCases/teeth_defect.png");
  }
  .teeth_no_move {
    background-image: url("/static/images/createCases/teeth_fixed.png");
  }
  .teeth_deficiency {
    background-image: url("/static/images/createCases/teeth_defect.png");
  }

  .teeth_no_design {
    background-image: url("/static/images/createCases/teeth_notdesigned.png");
  }

  .teeth_extraction {
    background-image: url("/static/images/createCases/teeth_extraction.png");
  }

  .t__s_tag_fj {
    background-image: url("/static/images/teeth_fj_tag.png");
    right: 45% !important;
    top: 45% !important;
  }
}
.w65 {
  width: 7.8rem !important;
}
.w46 {
  width: 4.8rem !important;
}
.children_height {
  height: 0.5rem !important;
}
.children_padding_top {
  padding-top: 0 !important;
}
.single_01_mt {
  margin-top: 0.18rem !important;
}

.is_active {
  color: #FCC80E !important;
  border: 0.01rem solid #FCC80E !important;
}
</style>