<template>
  <div class="exit_email">
    <back-step
      :goList="[{
        url: '/',
        name: `${$t('home.personal.personal.zy')}`
      }, {
        url: '/home/personal',
        name: `${$t('home.personal.personal.grzx')}`
      }]"
      :currentTxt="`${$t('home.personal.bandEmail.bdyx')}`"
    />
    <div class="children_con" v-if="!isExitOk">
      <h5 class="c_con_title">{{$t('home.personal.bandEmail.bdyx')}}</h5>
      <ul class="c_form">
        <li class="f_single">
          <span class="s_label">{{$t('home.personal.bandEmail.yxh')}}</span>
          <div class="iup_box">
            <input type="email" v-model="bindConfig.email">
          </div>
        </li>
        <li class="f_single">
          <span class="s_label">{{$t('home.personal.bandEmail.yzm')}}</span>
          <div class="iup_box">
            <input type="number" v-model="bindConfig.smsCaptcha">
            <span
              class="yzm_sp"
              :class="isYzm ? 'yzm_sp_active' : ''"
              @click="getYzm"
            >{{isYzm ? `${$t('home.personal.bandEmail.cxhq')}${yzmTime}s` : `${$t('home.personal.bandEmail.hqyzm')}`}}</span>
          </div>
        </li>
      </ul>
      <div class="c_foo">
        <div class="cancel_btn flex-x-y-c curp" @click="goBack">{{$t('common.message.qx')}}</div>
        <div class="confirm_btn flex-x-y-c curp main_theme_color_333" @click="submitForm">{{$t('common.message.qr')}}</div>
      </div>
    </div>
    <exit-success
      v-else
      :isExitOk="isExitOk"
      :successTit="`${$t('common.message.bdcg')}`"
      :successTxt="`${$t('common.message.yxbdcg')}`"
    />
    <FullLoading v-show="isLoading"/>
  </div>
</template>

<script>
  import BackStep from '../../../cases/cases-detail/childrenPublic/backStep';
  import FullLoading from 'components/full-loading/full-loading';
  import ExitSuccess from './exitSuccess';
  import {bindEmail, sendEmailCaptcha} from 'common/api/user';
  import {notifyMsg, getUserId} from 'common/js/util';
  import {mapState} from 'vuex';

  export default {
    data() {
      return {
        isLoading: false,
        isExitOk: false,
        isYzm: false,
        yzmTime: 59,
        interTime: null,
        bindConfig: {
          email: '',
          smsCaptcha: ''
        }
      }
    },
    methods: {
      getYzm() {
        if(this.isYzm) {
          return false;
        }
        const {email} = this.bindConfig;
        if(!email || !/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(email)) {
          return notifyMsg(this, 'warning', this.$t('common.message.qtxzqyx'));
        }
        if(this.interTime) {
          clearInterval(this.interTime);
        }
        this.isLoading = true;
        sendEmailCaptcha({
          email,
          bizType: 'BIND_EMAIL'
        }).then(() => {
          this.isLoading = false;
          this.isYzm = true;
          this.interTime = setInterval(() => {
            if(this.yzmTime < 1) {
              this.isYzm = false;
              this.yzmTime = 59;
              clearInterval(this.interTime);
            }
            this.yzmTime--;
          }, 1000);
        }).catch(() => {
          this.isLoading = false;
        });
      },
      submitForm() {
        const {email, smsCaptcha} = this.bindConfig;
        if(!email || !smsCaptcha) {
          return notifyMsg(this, 'warning', '请填写完整');
        }else if(!/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(email)) {
          return notifyMsg(this, 'warning', this.$t('common.message.qtxzqyx'));
        }
        this.isLoading = true;
        bindEmail({
          id: getUserId(),
          email,
          smsCaptcha
        }).then(() => {
          this.isLoading = false;
          this.isExitOk = true;
        }).catch(() => {
          this.isLoading = false;
        });
      },
      goBack() {
        window.history.go(-1);
      }
    },
    components: {
      BackStep,
      ExitSuccess,
      FullLoading
    },
    computed:{
      ...mapState({
        language: state => state.language,
      }),
    },
    beforeDestroy() {
      if(this.interTime) {
        clearInterval(this.interTime);
      }
    }
  }
</script>

<style scoped lang="scss">
  .exit_email{
    width: 16rem;
    margin: 0 auto;
    padding-top: 40px;
    .children_con{
      border-radius: 6px;
      background-color: #fff;
      display: flex;
      align-items: center;
      flex-direction: column;
      overflow: hidden;
      .c_con_title{
        font-size: 0.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 0.6rem;
        color: $main_theme_color_333;
        border-bottom: 1px solid #E5E5E5;
      }
      .c_form{
        width: 5.44rem;
        margin-top: 0.6rem;
        margin-bottom: 0.6rem;
        .f_single{
          display: flex;
          align-items: center;
          margin-bottom: 0.4rem;
          .s_label{
            width: 0.8rem;
            margin-right: 0.1rem;
            white-space: nowrap;
            display: flex;
            flex-flow: row-reverse;
            font-size: 16px;
            color: $main_theme_color_333;
          }
          .iup_box{
            flex: 1;
            display: flex;
            align-items: center;
            position: relative;
            height: 0.52rem !important;
            input {
              flex: 1;
              border-radius: 4px;
              border: 0.01rem solid #BBBBBB;
              padding: 16px 20px;
              font-size: 14px;
              color: $main_theme_color_333;
            }
            .yzm_sp {
              margin-left: 0.1rem;
              width: 1.2rem;
              height: 0.4rem;
              border-left: 1px solid #E5E5E5;
              color: $main_theme_color;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              right: 0;
              cursor: pointer;
              font-size: 14px;
            }
            .yzm_sp_active {
              color: $main_theme_color_666;
              cursor: default;
            }
          }
        }
      }
    }
    .c_foo{
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 0.64rem;
      .cancel_btn{
        width: 176px;
        height: 42px;
        background: #FFFFFF;
        border-radius: 6px;
        border: 1px solid #BBBBBB;
        font-size: 16px;
        color: $main_theme_color_333;
      }
      .confirm_btn{
        width: 176px;
        height: 42px;
        background: $main_theme_color;
        border-radius: 6px;
        font-size: 16px;
        margin-left: 32px;
      }
    }
  }
</style>
