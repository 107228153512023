<template>
  <div class="container">
    <ul
      v-if="selectObjList.length"
      :class="'w65'"
      class="teeth_ul"
      @click.stop="selectedTeeth"
    >
      <li class="teeth_single">
        <div class="single_02">
          <div
            v-for="item in [11, 12, 13, 14, 15, 16, 17, 18]"
            :key="`teeth_${item}`"
            :data-key="item"
            class="teeth_container"
            :class="{
              is_active: selectList.indexOf(item) !== -1
            }"
          >
            <el-popover
              placement="top"
              trigger="click"
              :popper-options="{
                boundariesElement: 'body'
              }"
              v-model:visible="visibles[item]"
              v-if="!readOnly && childKey"
            >
              <div class="popover_con flex-y-c">
                <div
                  class="common_button"
                  v-for="(it, idx) in teethKeys"
                  :key="idx"
                  :class="{
                    active_button: renderActiveButton(item, it)
                  }"
                  @click="changeTeethKey(item, it)"
                >
                  {{ it.text }}
                </div>
              </div>
              <div slot="reference" @click="showPopover(item)">
                <span
                  :data-key="item"
                  :class="isSelectedText(item, 'text')"
                  class="single_02_txt"
                  >{{ item }}</span
                >
                <span
                  class="tag_sp"
                  :class="isSelectedText(item, 'img')"
                  :data-key="item"
                ></span>
              </div>
            </el-popover>
            <div v-if="!childKey || readOnly">
              <span
                :data-key="item"
                :class="isSelectedText(item, 'text')"
                class="single_02_txt"
                >{{ item }}</span
              >
              <span
                class="tag_sp"
                :class="isSelectedText(item, 'img')"
                :data-key="item"
              ></span>
            </div>
          </div>
        </div>
      </li>
      <li
        class="teeth_single"
        :class="{ children_height: patientType === 'children' }"
      >
        <div class="single_02">
          <div
            v-for="item in [21, 22, 23, 24, 25, 26, 27, 28]"
            :key="`teeth_${item}`"
            :data-key="item"
            class="teeth_container"
            :class="{
              is_active: selectList.indexOf(item) !== -1
            }"
          >
            <el-popover
              placement="bottom-start"
              trigger="click"
              :popper-options="{
                boundariesElement: 'body'
              }"
              v-model:visible="visibles[item]"
              v-if="!readOnly && childKey"
            >
              <div class="popover_con flex-y-c">
                <div
                  class="common_button"
                  v-for="(it, idx) in teethKeys"
                  :key="idx"
                  :class="{
                    active_button: renderActiveButton(item, it)
                  }"
                  @click="changeTeethKey(item, it)"
                >
                  {{ it.text }}
                </div>
              </div>
              <div slot="reference" @click="showPopover(item)">
                <span
                  :data-key="item"
                  :class="isSelectedText(item, 'text')"
                  class="single_02_txt"
                  >{{ item }}</span
                >
                <span
                  class="tag_sp"
                  :class="isSelectedText(item, 'img')"
                  :data-key="item"
                ></span>
              </div>
            </el-popover>
            <div v-if="!childKey || readOnly">
              <span
                class="single_02_txt"
                :class="isSelectedText(item, 'text')"
                :data-key="item"
                >{{ item }}</span
              >
              <span
                class="tag_sp"
                :class="isSelectedText(item, 'img')"
                :data-key="item"
              ></span>
            </div>
          </div>
        </div>
      </li>
      <li
        class="teeth_single"
        :class="{
          children_height: patientType === 'children',
          children_padding_top: patientType === 'children'
        }"
      >
        <div
          v-if="['youth', 'adult', 'veneer'].includes(patientType)"
          class="single_02"
        >
          <div
            v-for="item in [41, 42, 43, 44, 45, 46, 47, 48]"
            :key="`teeth_${item}`"
            :data-key="item"
            class="teeth_container"
            :class="{
              is_active: selectList.indexOf(item) !== -1
            }"
          >
            <el-popover
              placement="bottom-start"
              trigger="click"
              :popper-options="{
                boundariesElement: 'body'
              }"
              v-model:visible="visibles[item]"
              v-if="!readOnly && childKey"
            >
              <div class="popover_con flex-y-c">
                <div
                  class="common_button"
                  v-for="(it, idx) in teethKeys"
                  :key="idx"
                  :class="{
                    active_button: renderActiveButton(item, it)
                  }"
                  @click="changeTeethKey(item, it)"
                >
                  {{ it.text }}
                </div>
              </div>
              <div slot="reference" @click="showPopover(item)">
                <span
                  :data-key="item"
                  :class="isSelectedText(item, 'text')"
                  class="single_02_txt"
                  >{{ item }}</span
                >
                <span
                  class="tag_sp"
                  :class="isSelectedText(item, 'img')"
                  :data-key="item"
                ></span>
              </div>
            </el-popover>
            <div v-if="!childKey || readOnly">
              <span
                :data-key="item"
                :class="isSelectedText(item, 'text')"
                class="single_02_txt"
                >{{ item }}</span
              >
              <span
                :data-key="item"
                :class="isSelectedText(item, 'img')"
                class="tag_sp"
              ></span>
            </div>
          </div>
        </div>
      </li>
      <li
        class="teeth_single"
        :class="{
          children_height: patientType === 'children',
          children_padding_top: patientType === 'children'
        }"
      >
        <div
          v-if="['youth', 'adult', 'veneer'].includes(patientType)"
          class="single_02"
        >
          <div
            v-for="item in [31, 32, 33, 34, 35, 36, 37, 38]"
            :key="`teeth_${item}`"
            :data-key="item"
            class="teeth_container"
            :class="{
              is_active: selectList.indexOf(item) !== -1
            }"
          >
            <el-popover
              placement="bottom-start"
              trigger="click"
              :popper-options="{
                boundariesElement: 'body'
              }"
              v-model:visible="visibles[item]"
              v-if="!readOnly && childKey"
            >
              <div class="popover_con flex-y-c">
                <div
                  class="common_button"
                  v-for="(it, idx) in teethKeys"
                  :key="idx"
                  :class="{
                    active_button: renderActiveButton(item, it)
                  }"
                  @click="changeTeethKey(item, it)"
                >
                  {{ it.text }}
                </div>
              </div>
              <div slot="reference" @click="showPopover(item)">
                <span
                  :data-key="item"
                  :class="isSelectedText(item, 'text')"
                  class="single_02_txt"
                  >{{ item }}</span
                >
                <span
                  class="tag_sp"
                  :class="isSelectedText(item, 'img')"
                  :data-key="item"
                ></span>
              </div>
            </el-popover>
            <div v-if="!childKey || readOnly">
              <span
                class="single_02_txt"
                :class="isSelectedText(item, 'text')"
                :data-key="item"
                >{{ item }}</span
              >
              <span
                class="tag_sp"
                :class="isSelectedText(item, 'img')"
                :data-key="item"
              ></span>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div
      class="options_tips"
      :class="[isDark && 'dark_options_tips']"
      v-show="childKey && readOnly"
    >
      <p v-if="this.selectObjList.some(item => item.hasOwnProperty('VENEER'))">
        {{
          this.selectObjList
            .filter(item => item.hasOwnProperty("VENEER") && item.number)
            .map(it => it.number)
            .sort()
            .join("，") + ` ${$t("cases.createDiagnosis.tm")}`
        }}
      </p>
      <p v-if="this.selectObjList.some(item => item.hasOwnProperty('CROWN'))">
        {{
          this.selectObjList
            .filter(item => item.hasOwnProperty("CROWN") && item.number)
            .map(it => it.number)
            .sort()
            .join("，") + ` ${$t("cases.createDiagnosis.guan")}`
        }}
      </p>
      <p v-if="this.selectObjList.some(item => item.hasOwnProperty('INLAY'))">
        {{
          this.selectObjList
            .filter(item => item.hasOwnProperty("INLAY") && item.number)
            .map(it => it.number)
            .sort()
            .join("，") + ` ${$t("cases.createDiagnosis.qt")}`
        }}
      </p>
    </div>
  </div>
</template>

<script>
import { filter, map, isArray, each, get, pickBy, extend, find } from "lodash";

export default {
  data() {
    return {
      selectObjList: [],
      currentTeethKey: {
        1: "LOSS", // 缺失牙
        10: "VENEER", // 贴面
        11: "DENTURE", // 假牙
        12: "CROWN", // 冠
        13: "INLAY", // 嵌体
        14: "GRIND" // 备牙
      }[this.teethType],
      nowChooseKey: "",
      teethKeys: [
        { idx: 0, text: this.$t("cases.createDiagnosis.tm"), key: "VENEER" },
        { idx: 0, text: this.$t("cases.createDiagnosis.guan"), key: "CROWN" },
        { idx: 0, text: this.$t("cases.createDiagnosis.qt"), key: "INLAY" }
      ],
      visibles: []
    };
  },
  computed: {
    selectList() {
      if (this.teethType) {
        return map(this.selectObjList, item => item && item.number);
      } else {
        return map(
          filter(
            this.selectObjList,
            item =>
              item.hasOwnProperty("VENEER") ||
              item.hasOwnProperty("CROWN") ||
              item.hasOwnProperty("INLAY")
          ),
          it => it.number
        );
      }
    },
    currentSelectedList() {
      return map(
        filter(this.selectObjList, item =>
          item.hasOwnProperty(this.currentTeethKey)
        ),
        it => it.number
      );
    },
    queryPort() {
      return this.$route.query.port;
    }
  },
  props: {
    //adult  youth  children
    patientType: {
      type: String,
      default: "youth"
    },
    teethType: {
      //牙齿种类 1: 选择牙齿 2不可移动 3不可设计 4 拔除
      type: Number | String,
      default: "",
      required: false
    },
    isDark: {
      required: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    commonTeeth: {
      type: Array,
      required: true
    },
    childKey: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    renderActiveButton(key, childKey) {
      const current = find(this.selectObjList, ["number", key]);
      if (current.hasOwnProperty(childKey.key)) {
        return true;
      }
    },
    isSelectedText(item, returnType) {
      const current = find(this.selectObjList, ["number", item]);
      const result = {
        text: "selected_text",
        img: this.currentTeethKey,
        boolean: true
      }[returnType];
      if (this.teethType) {
        if (current.hasOwnProperty(this.currentTeethKey)) {
          return result;
        }
      } else {
        if (current.hasOwnProperty("VENEER")) {
          return returnType == "img" ? "VENEER" : result;
        } else if (current.hasOwnProperty("CROWN")) {
          return returnType == "img" ? "CROWN" : result;
        } else if (current.hasOwnProperty("INLAY")) {
          return returnType == "img" ? "INLAY" : result;
        }
      }
    },
    clearTeethType(type) {
      let teethType = {
        11: "GRIND"
      }[type];
      this.selectObjList = map(this.selectObjList, item => {
        return pickBy(extend({}, item, { [teethType]: "" }));
      });
      this.updateTeeth();
    },
    showPopover() {
      this.visibles = [];
    },
    selectedTeeth(ev) {
      this.$forceUpdate();
      if (this.readOnly) return;
      const target = ev.target;
      if (
        target.tagName.toLocaleLowerCase() === "p" ||
        target.tagName.toLocaleLowerCase() === "span"
      ) {
        const key = +target.getAttribute("data-key");
        this.nowChooseKey = key;
        const current = find(this.selectObjList, ["number", key]);
        if (this.childKey) {
          if (
            !current.hasOwnProperty("VENEER") &&
            !current.hasOwnProperty("CROWN") &&
            !current.hasOwnProperty("INLAY")
          ) {
            this.$set(current, "VENEER", "VENEER");
          }
          return;
        }
        if (current.hasOwnProperty(this.currentTeethKey)) {
          this.$delete(current, this.currentTeethKey);
        } else {
          this.$set(current, this.currentTeethKey, this.currentTeethKey);
        }
      }
      this.updateTeeth();
    },
    changeTeethKey(key, childKey) {
      this.$forceUpdate();
      const current = find(this.selectObjList, ["number", key]);
      this.teethKeys.map(item => {
        if (item.key !== childKey.key && current.hasOwnProperty(item.key)) {
          this.$delete(current, item.key);
        }
      });
      if (current.hasOwnProperty(childKey.key)) {
        this.$delete(current, childKey.key);
      } else {
        this.$set(current, childKey.key, childKey.key);
      }
    },
    updateTeeth() {
      if (this.readOnly) return;
      this.$emit("updateTeeth", this.selectObjList);
    }
  },
  watch: {
    commonTeeth(val) {
      this.selectObjList = val;
    }
  },
  created() {
    if (isArray(this.commonTeeth)) {
      this.selectObjList = this.commonTeeth;
    }
  }
};
</script>

<style scoped lang="scss">
.teeth_ul {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.18rem;
  color: #999999;
  margin: 20px auto 0;

  .teeth_single {
    width: 50%;
    box-sizing: border-box;

    &:first-child {
      border-right: 0.01rem #c1c1c1 dashed;
      border-bottom: 0.01rem #c1c1c1 dashed;
      padding-bottom: 0.11rem;
      padding-right: 0.09rem;

      .single_01 {
        margin-bottom: 0.26rem;
        flex-direction: row-reverse;
      }

      .single_02 {
        flex-direction: row-reverse;
      }

      .teeth_container {
        margin-left: 0.18rem;
      }
    }

    &:last-child {
      border-left: 0.01rem #c1c1c1 dashed;
      border-top: 0.01rem #c1c1c1 dashed;
      padding-top: 0.14rem;
      padding-left: 0.09rem;
      transform: translate(-0.01rem, -0.01rem);

      .single_01 {
        margin-top: 0.26rem;
      }

      .teeth_container {
        margin-right: 0.18rem;
      }
    }

    &:nth-of-type(2) {
      padding-bottom: 0.11rem;
      padding-left: 0.09rem;

      .single_01 {
        margin-bottom: 0.26rem;
      }

      .teeth_container {
        margin-right: 0.18rem;
      }
    }

    &:nth-of-type(3) {
      padding-right: 0.09rem;
      padding-top: 0.14rem;

      .single_01 {
        margin-top: 0.26rem;
        flex-direction: row-reverse;
      }

      .single_02 {
        flex-direction: row-reverse;
      }

      .teeth_container {
        margin-left: 0.18rem;
      }
    }

    .single_01 {
      display: flex;
      align-items: center;

      .teeth_container {
        border: 0.01rem solid #c8c8c8;
        box-sizing: border-box;
        overflow: hidden;
        cursor: pointer;
      }

      .single_ac_p {
        border: 0.01rem solid #FCC80E;
        color: #FCC80E;
      }
    }

    .single_02 {
      display: flex;
      align-items: center;

      .teeth_container {
        border: 0.01rem solid #c8c8c8;
        box-sizing: border-box;
        cursor: pointer;
        overflow: hidden;
      }
    }

    .teeth_container {
      min-width: 0.32rem;
      min-height: 0.32rem;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.06rem;

      .single_02_txt {
        width: 100%;
        text-align: center;
        font-size: 14px;
        position: absolute;
        right: 50%;
        top: 50%;
        transform: translate(50%, -50%);
        font-size: 0.2rem;
      }

      .selected_text {
        top: 30%;
        transform: translate(71%, -50%);
        font-size: 14px;
      }

      .txt_active {
        color: #FCC80E;
        top: 35%;
        right: 35%;
        font-size: 0.2rem;
        z-index: 2;
      }

      .tag_sp {
        position: absolute;
        left: -1px;
        bottom: -1px;
        right: -1px;
        top: -1px;
        display: inline-block;
        background-size: 100% auto;
      }

      .LOSS {
        background-image: url("./img/LOSS.png");
        background-position: -1 -1;
      }

      .DENTURE {
        background-image: url("./img/DENTURE.png");
      }

      .VENEER {
        background-image: url("./img/VENEER.png");
      }

      .CROWN {
        background-image: url("./img/CROWN.png");
      }

      .INLAY {
        background-image: url("./img/INLAY.png");
      }

      .GRIND {
        background-image: url("./img/GRIND.png");
      }
    }

    .single_mdl {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 9;
      cursor: pointer;
    }
  }

  .teeth_missing {
    background-image: url("/static/images/createCases/teeth_defect.png");
  }

  .teeth_no_move {
    background-image: url("/static/images/createCases/teeth_fixed.png");
  }

  .teeth_deficiency {
    background-image: url("/static/images/createCases/teeth_defect.png");
  }

  .teeth_no_design {
    background-image: url("/static/images/createCases/teeth_notdesigned.png");
  }

  .teeth_extraction {
    background-image: url("/static/images/createCases/teeth_extraction.png");
  }

  .t__s_tag_fj {
    background-image: url("/static/images/teeth_fj_tag.png");
    right: 45% !important;
    top: 45% !important;
  }
}

.options_tips {
  width: 100%;
  padding: 10px 20px;
  background-color: #f4f4f4;
  border-radius: 4px;
  margin-top: 20px;

  p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
  }

  &:last-child {
    margin-bottom: 0px;
  }
}

.dark_options_tips {
  background-color: #58595a !important;
}

.w65 {
  width: 7.8rem !important;
}

.w46 {
  width: 4.8rem !important;
}

.children_height {
  height: 0.5rem !important;
}

.children_padding_top {
  padding-top: 0 !important;
}

.single_01_mt {
  margin-top: 0.18rem !important;
}

.is_active {
  color: #FCC80E !important;
  border: 0.01rem solid #FCC80E !important;
}

.popover_con {
  width: 360px;
  display: flex;
  justify-content: space-between;
}

.common_button {
  width: 96px;
  position: relative;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $main_theme_color_666;
  border-radius: 4px;
  border: 0.01rem solid #bbbbbb;
  cursor: pointer;
  font-size: 0.14rem;
  white-space: nowrap;
  user-select: none;
  background-color: #fff;
}

.active_button {
  background-color: #FCC80E;
  color: $main_theme_color_666 !important;
  border: 0.01rem solid #FCC80E;
}
</style>
