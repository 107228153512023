<template>
  <div class="container">
    <div class="header-title">
      <div class="done-case">{{ $t("cases.maintain.wcqdb") }}</div>
      <div class="clinic-name">
        {{ $t("cases.maintain.zsmc") }}: {{ caseDetail.clinicName }}
      </div>
      <div class="product-name">
        {{ $t("cases.maintain.cpmc") }}: {{ caseDetail.productName }}
      </div>
    </div>
    <div class="content">
      <div class="option">
        <div class="tabs">
          <div
            v-for="(item, index) in tabs"
            :key="index"
            :class="['tabs-item', nowTabIndex === index && 'active-tabs-item']"
            :style="{ width: language === 'zh_CN' ? '1.18rem' : '2.5rem' }"
            @click="changeTabIndex(index)"
          >
            <img
              :src="`/static/images/maintain/${item.icon}.png`"
              alt=""
              class="tabs-icon"
            />
            {{ item.name }}
          </div>
        </div>
        <div class="option-control">
          <div
            v-if="nowTabIndex === 0"
            class="next-page"
            @click="changeTabIndex(1)"
          >
            {{ $t("cases.maintain.xyy") }}
          </div>
          <div
            v-if="nowTabIndex === 1"
            class="previous-page"
            @click="changeTabIndex(0)"
          >
            {{ $t("cases.maintain.syy") }}
          </div>
          <div
            :class="[nowTabIndex === 0 ? 'staging' : 'submit']"
            @click="stagingOrSubmit(nowTabIndex === 1 ? 1 : 2)"
          >
            {{
              nowTabIndex === 1
                ? $t("cases.maintain.tj")
                : $t("cases.maintain.zc")
            }}
          </div>
        </div>
      </div>
      <div v-if="isLoading" class="loading-content">
        <img
          alt=""
          class="loading-icon"
          src="../../../../components/loading/loading.gif"
        />
      </div>
      <div v-else>
        <MaintainTable
          v-if="nowTabIndex === 0"
          ref="tableData"
          :caseInfo="caseDetail"
          :caseOptions="caseOptions"
          :diaKeyObj="diaKeyObj"
          :plasticsSheetList="plasticsSheetList"
          :questionName="questionName"
        />
        <PreviewTable
          v-if="nowTabIndex === 1"
          :caseInfo="caseDetail"
          :diaKeyObj="diaKeyObj"
          :plasticsSheetList="plasticsSheetList"
          @jumpQuestion="jumpQuestion"
        />
      </div>
    </div>
    <!--    <el-dialog
          :visible.sync="dialogVisible"
          custom-class="dialog-submit"
          top="35vh"
          width="25%"
        >
          <div class="dialog-title">
            {{ $t("cases.casesCreate.sftj") }}
          </div>
          <div slot="footer" class="dialog-footer">
            <div class="cancel" @click="dialogVisible = false">
              {{ $t("cases.theDelivery.qx") }}
            </div>
            <div class="submit" @click="submitTable">
              {{ $t("cases.casesCreate.qdtj") }}
            </div>
          </div>
        </el-dialog>-->
    <confirm-modal
      :confirmObj="{
        tipText: $t('cases.casesCreate.sftj'),
        backText: $t('casesDetail.casesChildren.common.qx'),
        okText: $t('cases.casesCreate.qdtj')
      }"
      @confirmSubmit="submitTable()"
      @confirmHide="dialogVisible = false"
      v-show="dialogVisible"
    />
    <FullLoading v-show="isShowConfirm" />
  </div>
</template>

<script>
import { getDictAllList } from "common/api/public";
import {
  PublicPlasticSheetList,
  casesDetail,
  curePlanCompletion_save, caseDiagnosisDetail, detailCurePlan
} from "common/api/cases";
import MaintainTable from "./components/maintain-table.vue";
import PreviewTable from "./components/preview.vue";
import { curePlanCompletion } from "common/api/cases";
import { notifyMsg } from "common/js/util";
import { mapState } from "vuex";
import FullLoading from "components/full-loading/full-loading.vue";
import ConfirmModal from "components/confirm-modal/confirm.vue";

export default {
  components: {
    ConfirmModal,
    FullLoading,
    MaintainTable,
    PreviewTable
  },
  data() {
    return {
      tabs: [
        {
          name: this.$t("cases.maintain.wcqdb"),
          idx: 0,
          icon: "complete"
        },
        {
          name: this.$t("cases.maintain.lltj"),
          idx: 1,
          icon: "preview"
        }
      ],
      nowTabIndex: 0,
      questionName: "",
      diaKeyObj: {},
      caseOptions: {},
      plasticsSheetList: [],
      dialogVisible: false,
      caseDetail: {},
      isLoading: false,
      isShowConfirm: false
    };
  },
  computed: {
    ...mapState({
      language: state => state.language,
      isShowNews: (state) => JSON.parse(state.isShowNews),
    })
  },
  mounted() {
    this.queryDictData();
    this.queryCaseDetail();
  },
  methods: {
    jumpQuestion(val) {
      this.questionName = val;
      this.nowTabIndex = 0;
    },
    queryCaseDetail() {
      this.isLoading = true;
      casesDetail(this.$route.query.caseId).then(res => {
        this.caseDetail = res;
        const plasticProductId =
          res["bindProductId"] !== "0"
            ? res["bindProductId"]
            : res["productId"];
        this.getPublicPlasticSheetList(plasticProductId);
      });
    },
    callApi(action) {
      let result = {};
      if (action === 1) {
        result = {
          id: this.$route.query.curePlanId,
          // action: action
        };

        return curePlanCompletion(result);
      } else {
        const data = this.$refs.tableData.stagingData();
        // const columnData={}
        result = {
          // id: this.$route.query.curePlanId,
          // action: action,
          ...data
        };
        result["sideProfile"] =
          result["sideProfile"] !== ""
            ? JSON.stringify(result["sideProfile"])
            : "";
        result["frontal"] =
          result["frontal"] !== "" ? JSON.stringify(result["frontal"]) : "";
        result["frontalSmile"] =
          result["frontalSmile"] !== ""
            ? JSON.stringify(result["frontalSmile"])
            : "";
        result["upperArch"] =
          result["upperArch"] !== "" ? JSON.stringify(result["upperArch"]) : "";
        result["anteriorOverjet"] =
          result["anteriorOverjet"] !== ""
            ? JSON.stringify(result["anteriorOverjet"])
            : "";
        result["lowerArch"] =
          result["lowerArch"] !== "" ? JSON.stringify(result["lowerArch"]) : "";
        result["intraOralRight"] =
          result["intraOralRight"] !== ""
            ? JSON.stringify(result["intraOralRight"])
            : "";
        result["intraOralFrontal"] =
          result["intraOralFrontal"] !== ""
            ? JSON.stringify(result["intraOralFrontal"])
            : "";
        result["intraOralLeft"] =
          result["intraOralLeft"] !== ""
            ? JSON.stringify(result["intraOralLeft"])
            : "";
        result["panoramicRadiograph"] =
          result["panoramicRadiograph"] !== ""
            ? JSON.stringify(result["panoramicRadiograph"])
            : "";
        result["lateralCephalogram"] =
          result["lateralCephalogram"] !== ""
            ? JSON.stringify(result["lateralCephalogram"])
            : "";
        result.attachmentList=result?.attachmentInfoList
        delete result?.attachmentInfoList
        const params = {
          columnData: result,
          caseId: this.$route.query.caseId,
          curePlanId: this.$route.query.curePlanId,
          type:'completion'
        };
        return curePlanCompletion_save(params);
      }
    },
    submitTable() {
      this.isShowConfirm = true;
      this.caseDetail;
      this.callApi(1)
        .then(res => {
          notifyMsg(this, "success", this.$t("cases.createPreview.tjcg"));
          this.isShowConfirm = false;
          this.goPage("/cases/detail");
        })
        .catch(() => {
          this.isShowConfirm = false;
        });
    },
    stagingOrSubmit(action) {
      if (action == 1) {
        this.dialogVisible = true;
      } else {
        this.callApi(action).then(res => {
          notifyMsg(this, "success", this.$t("cases.createUser.yzc"));
        });
      }
    },
    goPage(url) {
      this.$router.replace({
        path: url,
        query: {
          caseId: this.$route.query.caseId,
          curePlanId: this.$route.query.curePlanId
        }
      });
    },
    changeTabIndex(idx) {
      if (idx === 1) {
        this.questionName = "";
        this.callApi(2).then(res => {
          notifyMsg(this, "success", this.$t("cases.createUser.yzc"));
          this.nowTabIndex = idx;
        });
        return;
      }
      this.nowTabIndex = idx;
    },
    getPublicPlasticSheetList(productId) {
      PublicPlasticSheetList({
        productId,
        stage: "D"
      }).then(res => {
        this.plasticsSheetList = res;
        this.isLoading = false;
      });
    },
    queryDictData() {
      getDictAllList([
        "treatment_status",
        "need",
        "aligner_based_on",
        "reserved_position",
        "true_false",
        "submit"
      ]).then(data => {
        let diaKeyObj = {},
          dictObj = {};

        data.forEach(item => {
          if (!dictObj[item.parentKey]) {
            dictObj[item.parentKey] = [item];
          } else {
            dictObj[item.parentKey].push(item);
          }
          diaKeyObj[item.key] = item.value;
        });
        this.diaKeyObj = diaKeyObj;
        this.caseOptions = dictObj;
      });
    }
  }
  // beforeRouteUpdate(to, from, next) {
  //   // 路由更新前的处理逻辑
  //   console.log('路由更新', to);
  //   console.log('beforeRouteUpdate', from);
  //   // this.fetchData(); // 例如更新数据
  //   next();
  // },
};
</script>

<style lang="scss" scoped>
//.container::-webkit-scrollbar{
//  display: none;
//}
.container {
  //width: 16rem;
  height: 87.5vh;
  overflow-y: scroll;
  position: relative;
  margin: auto;
  //scrollbar-width: none; /* Firefox */
  //-ms-overflow-style: none; /* IE 10+ */
  padding: 0 100px;
  //top: -20px;

  .loading-content {
    display: flex;
    height: 100%;
    justify-content: center;
    padding-top: 100px;

    .loading-icon {
      width: 48px;
      height: 48px;
    }
  }

  .header-title {
    font-weight: 700;
    margin: 0 0 20px 0;
    font-size: 16px;
    line-height: 28px;
    color: #333;
    display: flex;
    align-items: center;
    width: 100%;

    .clinic-name {
      max-width: 240px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .done-case {
      font-size: 20px;
      line-break: 28px;
      //font-weight: 500;
    }

    > div {
      padding-right: 10px;
      margin-left: 10px;
      position: relative;

      &::after {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        content: "";
        width: 1px;
        height: 16px;
        background-color: #333;
        margin-left: 10px;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }

  .content {
    min-height: 100%;
    background-color: #fff;
    border-radius: 6px;

    .option {
      height: 72px;
      padding: 0 30px;
      border-bottom: 4px solid #e5e5e5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 99;

      .tabs {
        height: 100%;
        display: flex;
        align-items: center;

        .tabs-item {
          white-space: nowrap;
          cursor: pointer;
          height: 100%;
          width: 118px;
          margin-right: 80px;
          display: flex;
          align-items: center;
          font-size: 18px;
          line-height: 26px;
          color: #333;
          position: relative;

          .tabs-icon {
            width: 24px;
            height: 24px;
            margin-right: 4px;
          }

          &:last-child {
            margin-right: 0px;
          }
        }

        .active-tabs-item {
          &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 4px;
            background-color: $main_theme_color;
            left: 0;
            bottom: -4px;
          }
        }
      }

      .option-control {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #333;

        > div {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 36px;
          padding: 20px;
          min-width: 96px;
          border-radius: 4px;
        }

        .next-page,
        .previous-page,
        .submit {
          background-color: $main_theme_color;
          margin-right: 10px;
          color: #fff;
        }

        .submit {
          margin-right: 0px;
        }

        .staging {
          border: 1px solid #e5e5e5;
        }
      }
    }
  }

  .dialog-title {
    font-size: 18px;
    line-height: 26px;
    color: #333;
    text-align: center;
  }

  .dialog-footer {
    display: flex;
    justify-content: center;
    align-items: center;

    .cancel,
    .submit {
      width: 96px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      color: #333;
      border-radius: 4px;
      cursor: pointer;
    }

    .cancel {
      border: 1px solid #bbb;
      margin-right: 32px;
    }

    .submit {
      background: $main_theme_color;
    }
  }

  /deep/ .el-dialog {
    border-radius: 8px;
  }
}
</style>
