<template>
  <div class="wrap">
    <div class="header">
      <div class="transverse_line"></div>
      <div class="wrap_top"></div>
      <back-step
        :currentTxt="currentTxtObj[$route.query.tabKey]"
        :goList="[
          {
            url: '/cases',
            name: $t('casesDetail.casesChildren.common.bl')
          },
          {
            url: `/cases/detail?caseId=${$route.query.caseId}&curePlanId=${$route.query.curePlanId}`,
            name: $t('casesDetail.casesChildren.common.blxq')
          }
        ]"
        class="wrap_back"
      />
      <div class="content_header_info">
        <div class="flex-y-c">
          <div class="user_pic">
            <img
              v-if="defaultAvatar"
              :src="
                get(caseInfo, 'userInfo.photoModify') === '1'
                  ? $PicPrefix + defaultAvatar.nanoId
                  : defPhoto
              "
              alt=""
            />
            <!--                :style="
                                      get(caseInfo, 'userInfo.photoModify') === '1'
                                        ? `transform: rotate(${defaultAvatar['degree'] ||
                                            0}deg) scaleX(${
                                            defaultAvatar['horizontal'] ? -1 : 1
                                          }) scaleY(${defaultAvatar['vertical'] ? -1 : 1})`
                                        : 'transform: none'
                                    "-->
          </div>
          <p class="content_title">
            {{ get(caseInfo, "userInfo.realName") }}
            {{ $t("casesDetail.casesDetail.zlk") }}
          </p>
        </div>
      </div>
      <div>
        <div class="tabs">
          <div
            :class="{ tab_active: $route.query.tabKey === '1' }"
            @click="tabsChange('1')"
          >
            {{ $t("casesDetail.casesDetail.blzl") }}
          </div>
          <div
            :class="{ tab_active: $route.query.tabKey === '2' }"
            @click="tabsChange('2')"
          >
            {{ $t("casesDetail.casesChildren.operationChildren.yxzl") }}
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content_left">
        <el-scrollbar style="height: 100%">
          <div v-for="(item, index) in leftList" :key="index" class="case_item">
            <div
              :class="index === leftListActive ? 'case_item_active' : ''"
              class="case_item"
              @click="leftItemClick(item, index)"
            >
              <div>{{ item.createDate }}</div>
              <div class="mt1rem">
                {{ stageRender[item.type] }}
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
      <div>
        <div v-if="$route.query.tabKey === '1'" class="content_right">
          <public-cases-user :caseInfo="caseInfo" :userInfo="userInfo" />
          <!-- <div v-if="['A', 'B', 'G', 'H'].includes(caseStage)"> -->
          <div v-if="['1', '2'].includes(dataType)">
            <PreviewDiagnosis
              :key="1"
              :casesData="casesData"
              :commonTeeth="commonTeeth"
              :crowdTeethList="crowdTeethList"
              :details="details"
              :productType="productType"
              :uploadIntervalList="uploadIntervalList"
              :veneerList="commonVeneerList"
            />
            <public-cases-pic
              :caseInfo="caseInfo"
              :details="details"
              :surfacePicList="
                productType === 'F' ? yueyaPicList : surfacePicList
              "
              :xPicList="xPicList"
            />
            <public-teeth-model
              :details="details"
              :fileList="details.attachmentList"
            ></public-teeth-model>
          </div>
          <!-- <div v-if="caseStage === 'D'"> -->
          <div v-if="dataType === '3'">
            <caseDonePreview
                :details="details"
                :diaKeyObj="diaKeyObj"
                :isCaseData="true"
                :isShowPic="true"
                :isDatabase="true"
                :plasticsSheetList="plasticsSheetList"
            />
          </div>
          <div v-if="dataType === '4'">
            <stageAdjustmentPreview
              :caseDetail="caseInfo"
              :diaKeyObj="diaKeyObj"
              :dictObj="dictObj"
              :isDatabase="true"
              :previewCom="['table', 'image']"
              :previewData="details"
            />
          </div>
        </div>
        <div v-if="$route.query.tabKey === '2'" class="content_right">
          <!-- <div v-if="['A', 'B', 'G', 'H'].includes(caseStage)"> -->
          <div v-if="['1', '2'].includes(dataType)">
            <public-cases-pic
              :details="details"
              :surfacePicList="
                productType === 'F' ? yueyaPicList : surfacePicList
              "
              :xPicList="xPicList"
            />
            <public-teeth-model
              :details="details"
              :fileList="details.attachmentList"
            ></public-teeth-model>
          </div>
          <!-- <div v-if="caseStage === 'D'"> -->
          <div v-if="dataType === '3'">
            <caseDonePreview
              :details="details"
              :diaKeyObj="diaKeyObj"
              :isCaseData="true"
              :isShowPic="true"
              :isDatabase="true"
              :plasticsSheetList="plasticsSheetList"
            />
          </div>
          <div v-if="dataType === '4'">
            <stageAdjustmentPreview
              :caseDetail="caseInfo"
              :diaKeyObj="diaKeyObj"
              :dictObj="dictObj"
              :isDatabase="true"
              :previewCom="['image']"
              :previewData="details"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import BackStep from "../childrenPublic/backStep";
import PublicCasesUser from "../../publicCases/casesUser";
import PublicCasesDiagnosis from "../../publicCases/casesDiagnosis";
import PublicCasesPic from "../../publicCases/casesPic";
import PreviewDiagnosis from "../../../../components/treatment-table/preview-table/index";
import caseDonePreview from "./components/preview.vue";
import { each, filter, get, extend, map } from "lodash";
import stageAdjustmentPreview from "../../../cases/cases-detail/stageAdjustment/components/preview.vue";
import { defPhoto } from "common/js/requireImage";
import {
  caseDiagnosisList,
  caseDiagnosisDetail,
  casesDetail,
  PublicPlasticSheetList
} from "common/api/cases";
import { getDictAllList } from "common/api/public";

import PublicTeethModel from "../../../../components/treatment-table/preview-teeth-model/index";

const adultTeethList = [
  { number: 11 },
  { number: 12 },
  { number: 13 },
  { number: 14 },
  { number: 15 },
  { number: 16 },
  { number: 17 },
  { number: 18 },
  { number: 21 },
  { number: 22 },
  { number: 23 },
  { number: 24 },
  { number: 25 },
  { number: 26 },
  { number: 27 },
  { number: 28 },
  { number: 48 },
  { number: 47 },
  { number: 46 },
  { number: 45 },
  { number: 44 },
  { number: 43 },
  { number: 42 },
  { number: 41 },
  { number: 31 },
  { number: 32 },
  { number: 33 },
  { number: 34 },
  { number: 35 },
  { number: 36 },
  { number: 37 },
  { number: 38 }
];
let lang = localStorage.getItem("user_lang") || "zh_CN";
export default {
  data() {
    return {
      defPhoto,
      userInfo: {},
      details: {},

      commonTeeth: [],
      commonVeneerList: [],
      uploadIntervalList: [],
      crowdTeethList: [],
      plasticsSheetList: [],

      isLoading: false,

      casesData: [],
      babyTeethKeyObj: {},
      teethKeyObj: {},

      defaultAvatar: "",
      diaKeyObj: {},
      dictObj: {},

      caseStage: "",
      dataType: "",

      stageRender: {
        "1": "收集阶段",
        "2": "设计阶段",
        "3": "完成阶段",
        "4": "治疗阶段"
      },

      yueyaPicList: [
        {
          key: "sideFacePicture",
          name: this.$t("cases.createImage.zcmwxx")
        },
        {
          key: "frontFacePicture",
          name: this.$t("cases.createImage.zmwxx")
        },
        {
          key: "smilePicture",
          name: this.$t("cases.createImage.ycmwxx")
        },
        {
          key: "bitePicture",
          name: this.$t("cases.createImage.yhx")
        },
        {
          key: "openAndClosePicture",
          name: this.$t("cases.createImage.khx")
        }
      ],

      surfacePicList: [
        {
          key: "sideFacePicture",
          name: this.$t("cases.createImage.cmx")
        },
        {
          key: "frontFacePicture",
          name: this.$t("cases.createImage.zmx")
        },
        {
          key: "smilePicture",
          name: this.$t("cases.createImage.wxx")
        },
        {
          key: "upToothPicture",
          name: this.$t("cases.createImage.sylx")
        },
        {
          key: "anteriorOverjetPicture",
          name: this.$t("cases.createImage.qyfgx")
        },
        {
          key: "downToothPicture",
          name: this.$t("cases.createImage.xylx")
        },
        {
          key: "mouthRightPicture",
          name: this.$t("cases.createImage.knzcyx")
        },
        {
          key: "mouthFrontPicture",
          name: this.$t("cases.createImage.knzwx")
        },
        {
          key: "mouthLeftPicture",
          name: this.$t("cases.createImage.knycyx")
        }
      ],
      xPicList: [
        { key: "xrayCurvePicture", name: this.$t("cases.createImage.dcp") },
        { key: "xraySidePicture", name: this.$t("cases.createImage.dwp") }
      ],
      otherPic: "otherPicture",

      currentTxtObj: {
        1: this.$t("cases.inviteFriends.blxq"),
        2: this.$t("casesDetail.casesChildren.operationChildren.yxzl")
      },
      leftList: [],
      leftListActive: 0,
      loading: false,
      carouselList: [],
      caseInfo: {},
      productType: ""
    };
  },
  computed: {
    ...mapState({
      isShowNews: state => JSON.parse(state.isShowNews)
    }),
    ...mapGetters({
      commonCaseCureDetail: "getCommonCaseCureDetail" //治疗表详情
    }),
    needRdt() {
      return this.details["helpFlag"] == "1";
    },
    gap() {
      return [
        {
          fIndex: 1,
          title: this.$t("cases.createDiagnosis.zyjzmb"),
          pList: [
            {
              filed: "toothProblemTwo",
              superiorText: this.$t("cases.createPreview.ycwt"),
              inquireKey: "tooth_problem_i"
            },
            {
              filed: "toothOtherProblemTwo",
              type: "note"
            }
          ]
        },
        {
          fIndex: 2,
          title: this.$t("cases.createPreview.njzye"),
          pList: [
            {
              filed: "toCorrectToothJaw",
              inquireKey: "to_correct_tooth_jaw"
            },
            {
              filed: "toCorrectToothUpjaw",
              inquireKey: "to_correct_tooth_upjaw",
              sublevel: true
            },
            {
              filed: "toCorrectToothDownjaw",
              inquireKey: "to_correct_tooth_downjaw",
              sublevel: true
            }
          ]
        },
        {
          fIndex: 3,
          title: this.$t("cases.createPreview.ycxx"),
          key: "teeth",
          teethType: 1,
          type: "gap",
          pList: [
            {
              showTeeth: true,
              type: "gap",
              key: "teeth",
              teethType: 1
            }
          ]
        },
        {
          fIndex: 4,
          title: this.$t("cases.createDiagnosis.txt5"),
          teethType: 2,
          pList: [
            {
              filed: "nonMoveableTeeth",
              inquireKey: "non_moveable_teeth",
              type: "gap",
              key: "teeth",
              teethType: 2
            }
          ]
        },
        {
          fIndex: 5,
          title: this.$t("cases.createDiagnosis.txt6"),
          key: "teeth",
          teethType: 3,
          type: "gap",
          pList: [
            {
              filed: "notAvailableAttachments",
              inquireKey: "not_available_for_attachments",
              type: "gap",
              key: "teeth",
              teethType: 3
            }
          ]
        },
        {
          fIndex: 6,
          title: this.$t("cases.createPreview.jxzl"),
          pList: [
            {
              tit: this.$t("cases.createDiagnosis.txt4"),
              filed: "teeth",
              key: "interval"
            }
          ]
        },
        {
          fIndex: 7,
          title: this.$t("cases.createPreview.tssm"),
          pList: [
            {
              filed: "specialInstructions",
              type: "note"
            }
          ]
        }
      ];
    },
    adult() {
      return [
        {
          fIndex: 1,
          title: this.$t("cases.createPreview.zs"),
          pList: [
            {
              filed: "toothProblem",
              superiorText: this.$t("cases.createPreview.ycwt"),
              inquireKey: "tooth_problem"
            },
            {
              filed: "jawProblem",
              superiorText: this.$t("cases.createPreview.egwt"),
              inquireKey: "jaw_problem"
            },
            {
              filed: "toothOtherProblem",
              type: "note"
            }
          ]
        },
        {
          fIndex: 2,
          title: this.$t("cases.createDiagnosis.zyjzmb"),
          pList: [
            {
              filed: "toothProblemTwo",
              superiorText: this.$t("cases.createPreview.ycwt"),
              inquireKey: "tooth_problem_two"
            },
            {
              filed: "jawProblemTwo",
              superiorText: this.$t("cases.createPreview.egwt"),
              inquireKey: "jaw_problem_two"
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "toothOtherProblemTwo",
              type: "note"
            }
          ]
        },
        {
          fIndex: 3,
          title: this.$t("cases.createDiagnosis.txt2"),
          pList: [
            {
              filed: "angleClassification",
              superiorText: this.$t("cases.createDiagnosis.asfl"),
              inquireKey: "angle_classification"
            },
            {
              filed: "sagittalMaxillomandibularClassification",
              superiorText: this.$t("cases.createDiagnosis.gxfl"),
              inquireKey: "sagittal_maxillomandibular_classification"
            },
            {
              filed: "clinicInfo",
              superiorText: this.$t("cases.createDiagnosis.chlx"),
              inquireKey: "clinic_info"
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "clinicOtherInfo",
              type: "note"
            }
          ]
        },
        {
          fIndex: 4,
          title: this.$t("cases.createPreview.njzye"),
          pList: [
            {
              filed: "toCorrectToothJaw",
              inquireKey: "to_correct_tooth_jaw"
            },
            {
              filed: "toCorrectToothUpjaw",
              inquireKey: "to_correct_tooth_upjaw",
              sublevel: true
            },
            {
              filed: "toCorrectToothDownjaw",
              inquireKey: "to_correct_tooth_downjaw",
              sublevel: true
            }
          ]
        },
        {
          fIndex: 5,
          title: this.$t("cases.createPreview.ycxx"),
          key: "teeth",
          teethType: 1,
          type: "adult",
          pList: [
            {
              showTeeth: true,
              type: "adult",
              key: "teeth",
              teethType: 1
            }
          ]
        },
        {
          fIndex: 6,
          title: this.$t("cases.createDiagnosis.txt5"),
          teethType: 2,
          pList: [
            {
              filed: "nonMoveableTeeth",
              inquireKey: "non_moveable_teeth",
              type: "adult",
              key: "teeth",
              teethType: 2
            }
          ]
        },
        {
          fIndex: 7,
          title: this.$t("cases.createDiagnosis.txt6"),
          key: "teeth",
          teethType: 3,
          type: "adult",
          pList: [
            {
              filed: "notAvailableAttachments",
              inquireKey: "not_available_for_attachments",
              type: "adult",
              key: "teeth",
              teethType: 3
            }
          ]
        },

        {
          fIndex: 8,
          title: this.$t("cases.createPreview.jzgx"),
          pList: [
            {
              filed: "rightSagittalRelation",
              inquireKey: "right_sagittal_relation",
              secondLevel: this.$t("cases.createDiagnosis.you")
            },
            {
              filed: "rightSagittalRelationNote",
              inquireKey: "improve_tip",
              threeOptions: [
                {
                  key: "rightSagittalRelationFar",
                  inquireKey: "precision_cut",
                  parentKey: "far_grind"
                },
                {
                  key: "rightSagittalRelationBitJump",
                  inquireKey: "precision_cut",
                  parentKey: "bit_jump"
                },
                {
                  key: "rightSagittalRelationExtraction",
                  inquireKey: "tooth_extraction",
                  parentKey: "tooth_extraction"
                }
              ],
              sublevel: true
            },

            {
              filed: "leftSagittalRelation",
              inquireKey: "left_sagittal_relation",
              secondLevel: this.$t("cases.createPreview.zuo")
            },
            {
              filed: "leftSagittalRelationNote",
              inquireKey: "improve_tip",
              threeOptions: [
                {
                  key: "leftSagittalRelationFar",
                  inquireKey: "precision_cut",
                  parentKey: "far_grind"
                },
                {
                  key: "leftSagittalRelationBitJump",
                  inquireKey: "precision_cut",
                  parentKey: "bit_jump"
                },
                {
                  key: "leftSagittalRelationExtraction",
                  inquireKey: "tooth_extraction",
                  parentKey: "tooth_extraction"
                }
              ],
              sublevel: true
            }
          ]
        },
        {
          fIndex: 9,
          title: this.$t("cases.createPreview.fg"),
          pList: [
            {
              filed: "coverInfo",
              inquireKey: "cover_info"
            }
          ]
        },
        {
          fIndex: 10,
          title: this.$t("cases.createPreview.fh"),
          pList: [
            {
              filed: "overbiteInfo",
              inquireKey: "overbite_info"
            },

            {
              filed: "overbiteInfoOpen",
              inquireKey: "correct_open",
              sublevel: true
            },
            {
              filed: "overbiteInfoDeep",
              inquireKey: "correct_deep_cover",
              sublevel: true,
              threeOptions: [
                {
                  key: "overbiteInfoIntrudeAnterior",
                  inquireKey: "updown_jaw",
                  parentKey: "press_front"
                },
                {
                  key: "overbiteInfoPosteriorsExrusion",
                  inquireKey: "updown_jaw",
                  parentKey: "press_front_rise_back"
                }
              ]
            },

            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "overbiteOtherInfo",
              type: "note"
            }
          ]
        },
        {
          fIndex: 11,
          title: this.$t("cases.createPreview.yd"),
          pList: [
            {
              filed: "frontToothInfo",
              inquireKey: "front_tooth_info"
            },

            {
              filed: "correctFrontReverse",
              inquireKeys: [
                "correct_front_reverse",
                "correct_front_reverse_two"
              ],
              parentText: this.$t("cases.createPreview.jzqyfh"),
              sublevel: true
            },
            {
              filed: "correctFrontOpposite",
              inquireKeys: ["correct_front_opposite"],
              parentText: this.$t("cases.createPreview.jzqydrh"),
              sublevel: true
            }
          ]
        },
        {
          fIndex: 12,
          title: this.$t("cases.createPreview.hsh"),
          pList: [
            {
              filed: "backToothInfo",
              inquireKey: "back_tooth_info"
            },
            {
              filed: "backToothInfoCorrect",
              inquireKey: "back_tooth_info_correct",
              sublevel: true
            }
          ]
        },
        {
          fIndex: 13,
          title: this.$t("cases.createPreview.zx"),
          pList: [
            {
              filed: "middleLine",
              inquireKey: "middle_line"
            },
            {
              filed: "upjawMiddleLineNote",
              inquireKey: "improve_middle_move",
              describeInputKey: "upjawMiddleLine",
              superiorText: this.$t("cases.createDiagnosis.se"),
              isShowMM: true,
              sublevel: true
            },

            {
              filed: "downjawMiddleLineNote",
              inquireKey: "improve_middle_move",
              describeInputKey: "downjawMiddleLine",
              isShowMM: true,
              superiorText: this.$t("cases.createDiagnosis.xe"),
              sublevel: true
            }
          ]
        },
        {
          fIndex: 14,
          title: this.$t("cases.createPreview.jxzl"),
          pList: [
            {
              filed: "upjawGapCorrect",
              inquireKey: "updown_jaw_gap_correct",
              secondLevel: this.$t("cases.createPreview.she")
            },
            {
              filed: "downjawGapCorrect",
              inquireKey: "updown_jaw_gap_correct",
              secondLevel: this.$t("cases.createPreview.xhe")
            },
            {
              filed: "gapCorrect",
              inquireKey: "gap_correct",
              key: "reserve"
            },
            {
              tit: this.$t("cases.createDiagnosis.txt4"),
              filed: "teeth"
            }
          ]
        },
        {
          fIndex: 15,
          title: this.$t("cases.createPreview.yjzl"),
          pList: [
            {
              onlyHeaderText: true,
              isShow: this.needRdt,
              childOptions: [
                "acceptWisdomTeethExtraction",
                "acceptPremolarsExtraction",
                "acceptDecayedTeethExtraction",
                "acceptIpr"
              ],
              superiorText: "15.1 " + this.$t("cases.createDiagnosis.jcyjsx")
            },
            {
              filed: "acceptWisdomTeethExtraction",
              inquireKey: "accept_wisdom_teeth_extraction",
              superiorText: this.$t("cases.createDiagnosis.hzsfjsbczc"),
              className: lang === "en_US" ? "ml36" : "ml30"
            },
            {
              filed: "acceptPremolarsExtraction",
              inquireKey: "accept_premolars_extraction",
              superiorText: this.$t("cases.createDiagnosis.hzsfjsbcqmy"),
              className: lang === "en_US" ? "ml36" : "ml30"
            },
            {
              filed: "acceptDecayedTeethExtraction",
              inquireKey: "accept_decayed_teeth_extraction",
              superiorText: this.$t("cases.createDiagnosis.hzsfjsbchy"),
              className: lang === "en_US" ? "ml36" : "ml30"
            },
            {
              filed: "acceptIpr",
              inquireKey: "accept_ipr",
              superiorText: this.$t("cases.createDiagnosis.hzsfjslmqy"),
              className: lang === "en_US" ? "ml36" : "ml30"
            },

            {
              onlyHeaderText: true,
              isShow: this.needRdt,
              childOptions: [
                "undergoTeethRestoration",
                "implantAnchoragePlacement",
                "cbctPhotography",
                "orthodonticsTreatmentBefore"
              ],
              superiorText: "15.2 " + this.$t("cases.createDiagnosis.qtlczc")
            },
            {
              filed: "undergoTeethRestoration",
              inquireKey: "undergo_teeth_restoration",
              superiorText: this.$t("cases.createDiagnosis.sfyycxf"),
              className: lang === "en_US" ? "ml36" : "ml30"
            },
            {
              filed: "implantAnchoragePlacement",
              inquireKey: "implant_anchorage_placement",
              superiorText: this.$t("cases.createDiagnosis.zssfjb"),
              className: lang === "en_US" ? "ml36" : "ml30"
            },
            {
              filed: "cbctPhotography",
              inquireKey: "cbct_photography",
              superiorText: this.$t("cases.createDiagnosis.zssfjbps"),
              className: lang === "en_US" ? "ml36" : "ml30"
            },
            {
              filed: "orthodonticsTreatmentBefore",
              inquireKey: "orthodontics_treatment_before",
              superiorText: this.$t("cases.createDiagnosis.hzsfyzjs"),
              className: lang === "en_US" ? "ml36" : "ml30"
            },
            {
              onlyHeaderText: true,
              isShow: this.needRdt,
              childOptions: [
                "upjawExt",
                "upjawLip",
                "upjawSide",
                "upjawSideAnterior",
                "upjawSideRight",
                "upjawSideLeft",
                "upjawFar"
              ],
              superiorText: "15.3 " + this.$t("cases.createDiagnosis.jcyjjzfs")
            },

            {
              identification: this.$t("cases.createDiagnosis.se"),
              childOptions: [
                "upjawExt",
                "upjawLip",
                "upjawSide",
                "upjawSideAnterior",
                "upjawSideRight",
                "upjawSideLeft",
                "upjawFar"
              ],
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml34 preview_pos_2"
                    : "preview_pos_8"
                  : this.needRdt
                  ? "ml38 preview_pos0"
                  : "preview_pos_8"
            },
            {
              filed: "upjawExt",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.kk"),
              parentName: "body_up",
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 preview_pos8"
                  : "ml38 mt12",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? "preview_pos8"
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : ""
            },
            {
              filed: "upjawLip",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.cq"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 preview_pos8"
                  : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? "preview_pos8"
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : ""
            },
            {
              filed: "upjawSide",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.lmqz"),
              parentName: "body_up",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? "preview_pos8"
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : "",
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 preview_pos8"
                  : "ml38 mt12"
            },
            {
              filed: "upjawSideAnterior",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.qy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120 preview_pos8"
                  : "ml60",
              parentName: "body_up",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? ""
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : ""
            },
            {
              filed: "upjawSideRight",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.ychy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120 preview_pos8"
                  : "ml60",
              parentName: "body_up",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? ""
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : ""
            },
            {
              filed: "upjawSideLeft",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.zchy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120 preview_pos8"
                  : "ml60",
              parentName: "body_up",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? ""
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : ""
            },
            {
              filed: "upjawFar",
              inquireKey: "upjaw_far",
              superiorText: this.$t("cases.createDiagnosis.yymy"),
              parentName: "body_up",
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 mt12"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 preview_pos8"
                  : "ml38 mt12",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? ""
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : ""
            },

            {
              identification: this.$t("cases.createDiagnosis.xe"),
              childOptions: [
                "downjawExt",
                "downjawLip",
                "downjawSide",
                "downjawSideAnterior",
                "downjawSideRight",
                "downjawSideLeft",
                "downjawFar"
              ],
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml34 preview_pos_2"
                    : "preview_pos_8"
                  : this.needRdt
                  ? "ml38 preview_pos_8"
                  : "preview_pos_8"
            },
            {
              filed: "downjawExt",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.kk"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : ""
            },
            {
              filed: "downjawLip",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.cq"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : ""
            },
            {
              filed: "downjawSide",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.lmqz"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : ""
            },
            {
              filed: "downjawSideAnterior",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.qy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120"
                  : "ml60",
              parentName: "body_up"
            },
            {
              filed: "downjawSideRight",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.ychy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120"
                  : "ml60",
              parentName: "body_up"
            },
            {
              filed: "downjawSideLeft",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.zchy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120"
                  : "ml60",
              parentName: "body_up"
            },
            {
              filed: "downjawFar",
              inquireKey: "downjaw_far",
              superiorText: this.$t("cases.createDiagnosis.yymy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 mt12"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up"
            },

            {
              filed: "crowdCorrect",
              inquireKey: "crowd_correct",
              className: lang === "en_US" ? "ml34 mt12" : "ml38 mt12"
            },
            {
              filed: "toothExtraction",
              key: "teeth",
              teethType: 4,
              type: "adult",
              superiorText: this.$t("cases.createDiagnosis.by"),
              inquireKey: "tooth_extraction_options"
            }
          ]
        },
        {
          fIndex: 16,
          title: this.$t("cases.createPreview.sfphz"),
          pList: [
            {
              filed: "growNailFlag",
              inquireKey: "grow_nail_flag"
            },
            {
              filed: "upGrowNailPosition",
              inquireKey: "grow_nail_position",
              superiorText:
                "16.1 " +
                this.$t("cases.createDiagnosis.nzrzzkd") +
                " - " +
                this.$t("cases.createDiagnosis.se")
            },
            {
              filed: "downGrowNailPosition",
              inquireKey: "grow_nail_position",
              superiorText:
                "16.1 " +
                this.$t("cases.createDiagnosis.nzrzzkd") +
                " - " +
                this.$t("cases.createDiagnosis.xe")
            },
            {
              filed: "growNailPurpose",
              inquireKey: "grow_nail_purpose",
              superiorText: "16.2 " + this.$t("cases.createDiagnosis.nzrzzkdzy")
            },
            {
              filed: "growNailFlagNote",
              type: "note"
            }
          ]
        },
        {
          fIndex: 17,
          title: this.$t("cases.createPreview.hgj"),
          pList: [
            {
              filed: "underjawAbnormalFlag",
              inquireKey: "underjaw_abnormal_flag"
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "underjawAbnormalFlagNote",
              type: "note"
            }
          ]
        },
        {
          fIndex: 18,
          title: this.$t("cases.createDiagnosis.sfxybdjzq"),
          pList: [
            {
              filed: "needPassiveAppliance",
              inquireKey: "need_passive_appliance"
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "needPassiveApplianceNote",
              type: "note"
            }
          ]
        },
        {
          fIndex: 19,
          title: this.$t("cases.createPreview.tssm"),
          pList: [
            {
              filed: "specialInstructions",
              type: "note"
            }
          ]
        }
      ];
    },
    youthList() {
      return [
        {
          fIndex: 1,
          title: this.$t("cases.createPreview.zs"),
          pList: [
            {
              filed: "toothProblem",
              superiorText: this.$t("cases.createPreview.ycwt"),
              inquireKey: "tooth_problem_b"
            },
            {
              filed: "jawProblem",
              superiorText: this.$t("cases.createPreview.egwt"),
              inquireKey: "jaw_problem_b"
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "toothOtherProblem",
              type: "note"
            }
          ]
        },
        {
          fIndex: 2,
          title: this.$t("cases.createDiagnosis.zyjzmb"),
          pList: [
            {
              filed: "toothProblemTwo",
              superiorText: this.$t("cases.createPreview.ycwt"),
              inquireKey: "tooth_problem_two"
            },
            {
              filed: "jawProblemTwo",
              superiorText: this.$t("cases.createPreview.egwt"),
              inquireKey: "jaw_problem_two"
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "toothOtherProblemTwo",
              type: "note"
            }
          ]
        },
        {
          fIndex: 3,
          title: this.$t("cases.createDiagnosis.txt2"),
          pList: [
            {
              filed: "angleClassification",
              superiorText: this.$t("cases.createDiagnosis.asfl"),
              inquireKey: "angle_classification"
            },
            {
              filed: "sagittalMaxillomandibularClassification",
              superiorText: this.$t("cases.createDiagnosis.gxfl"),
              inquireKey: "sagittal_maxillomandibular_classification"
            },
            {
              filed: "clinicInfo",
              superiorText: this.$t("cases.createDiagnosis.chlx"),
              inquireKey: "clinic_info"
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "clinicOtherInfo",
              type: "note"
            }
          ]
        },
        {
          fIndex: 4,
          title: this.$t("cases.createDiagnosis.blxg"),
          pList: [
            {
              filed: "badHabits",
              inquireKey: "bad_habits"
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "badHabitsNote",
              type: "note"
            }
          ]
        },
        // {
        //   fIndex: 5,
        //   title: this.$t('cases.createDiagnosis.jzzk'),
        //   pList: [
        //     {
        //       filed: 'dentition',
        //       inquireKey: 'dentition'
        //     },
        //   ]
        // },
        {
          fIndex: 5,
          title: this.$t("cases.createDiagnosis.txt3"),
          pList: [
            {
              filed: "toCorrectToothJaw",
              inquireKey: "to_correct_tooth_jaw"
            },
            {
              filed: "toCorrectToothUpjaw",
              inquireKey: "to_correct_tooth_upjaw_b",
              sublevel: true
            },
            {
              filed: "toCorrectToothDownjaw",
              inquireKey: "to_correct_tooth_downjaw_b",
              sublevel: true
            }
          ]
        },
        {
          fIndex: 6,
          title: this.$t("cases.createDiagnosis.txt4"),
          pList: [
            {
              showTeeth: true,
              type: "adult",
              key: "teeth",
              teethType: 1
            }
          ]
        },
        {
          fIndex: 7,
          title: this.$t("cases.createDiagnosis.txt5"),
          key: "teeth",
          teethType: 2,
          pList: [
            {
              filed: "nonMoveableTeeth",
              inquireKey: "non_moveable_teeth",
              key: "teeth",
              type: "youth",
              teethType: 2
            }
          ]
        },
        {
          fIndex: 8,
          title: this.$t("cases.createDiagnosis.txt6"),
          key: "teeth",
          teethType: 3,
          type: "youth",
          pList: [
            {
              filed: "notAvailableAttachments",
              inquireKey: "not_available_for_attachments",
              key: "teeth",
              type: "youth",
              teethType: 3
            }
          ]
        },
        {
          fIndex: 9,
          title: this.$t("cases.createDiagnosis.txt7"),
          pList: [
            {
              filed: "sagittalRelation",
              inquireKey: "sagittal_relation"
            },
            {
              filed: "sagittalRelationMolar",
              inquireKey: "improve_molar",
              differentiateKey: "left",
              describeInputKey: "leftSagittalRelationMolarNote",
              sublevel: true
            },
            {
              filed: "sagittalRelationMolar",
              inquireKey: "improve_molar",
              differentiateKey: "right",
              describeInputKey: "rightSagittalRelationMolarNote",
              sublevel: true
            }
          ]
        },
        {
          fIndex: 10,
          title: this.$t("cases.createDiagnosis.hxgx"),
          pList: [
            {
              filed: "upHorizontalRelationship",
              inquireKey: "up_horizontal_relationship",
              secondLevel: this.$t("common.common.up")
            },
            {
              filed: "upHorizontalRelationshipNote",
              inquireKey: "up_horizontal_expansion",
              describeInputKey: "upHorizontalRelationshipValue",
              isShowMM: true,
              sublevel: true
            },

            {
              filed: "downHorizontalRelationship",
              inquireKey: "down_horizontal_relationship",
              secondLevel: this.$t("common.common.down")
            },
            {
              filed: "downHorizontalRelationshipNote",
              inquireKey: "down_horizontal_expansion",
              describeInputKey: "downHorizontalRelationshipValue",
              isShowMM: true,
              sublevel: true
            }
          ]
        },
        {
          fIndex: 11,
          title: this.$t("cases.createDiagnosis.txt8"),
          pList: [
            {
              filed: "coverInfo",
              inquireKey: "cover_info"
            }
          ]
        },
        {
          fIndex: 12,
          title: this.$t("cases.createDiagnosis.txt9"),
          pList: [
            {
              filed: "overbiteInfo",
              inquireKey: "overbite_info"
            },

            {
              filed: "overbiteInfoOpen",
              inquireKey: "correct_open_b",
              sublevel: true,
              threeOptions: [
                {
                  key: "overbiteInfoExtrudeAnterior",
                  inquireKey: "updown_jaw",
                  parentKey: "extend_front_tooth"
                }
              ]
            },
            {
              filed: "overbiteInfoDeep",
              inquireKey: "correct_deep_cover_b",
              sublevel: true,
              threeOptions: [
                {
                  key: "overbiteInfoIntrudeAnterior",
                  inquireKey: "updown_jaw",
                  parentKey: "press_front"
                },
                {
                  key: "overbiteInfoPosteriorsExrusion",
                  inquireKey: "updown_jaw",
                  parentKey: "press_front_rise_back"
                }
              ]
            },

            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "overbiteOtherInfo",
              type: "note"
            }
          ]
        },
        {
          fIndex: 13,
          title: this.$t("cases.createDiagnosis.qyfh"),
          pList: [
            {
              filed: "frontToothInfo",
              inquireKey: "front_tooth_info_b"
            },
            {
              filed: "correctFrontReverse",
              inquireKeys: [
                "correct_front_reverse",
                "correct_front_reverse_two"
              ],
              parentText: "",
              sublevel: true
            }
          ]
        },
        {
          fIndex: 14,
          title: this.$t("cases.createDiagnosis.txt11"),
          pList: [
            {
              filed: "backToothInfo",
              inquireKey: "back_tooth_info"
            },
            {
              filed: "backToothInfoCorrect",
              inquireKey: "back_tooth_info_correct_b",
              sublevel: true
            }
          ]
        },
        {
          fIndex: 15,
          title: this.$t("cases.createPreview.zx"),
          pList: [
            {
              filed: "middleLine",
              inquireKey: "middle_line"
            },
            {
              filed: "upjawMiddleLineNote",
              inquireKey: "improve_middle_move",
              describeInputKey: "upjawMiddleLine",
              superiorText: this.$t("cases.createDiagnosis.se"),
              isShowMM: true,
              sublevel: true
            },

            {
              filed: "downjawMiddleLineNote",
              inquireKey: "improve_middle_move",
              describeInputKey: "downjawMiddleLine",
              isShowMM: true,
              superiorText: this.$t("cases.createDiagnosis.xe"),
              sublevel: true
            }
          ]
        },
        {
          fIndex: 16,
          title: this.$t("cases.createDiagnosis.txt13"),
          pList: [
            {
              tit: this.$t("cases.createDiagnosis.txt4"),
              filed: "teeth",
              key: "interval"
            }
          ]
        },
        {
          fIndex: 17,
          title: this.$t("cases.createDiagnosis.txt14"),
          pList: [
            {
              onlyHeaderText: true,
              isShow: this.needRdt,
              childOptions: [
                "acceptWisdomTeethExtraction",
                "acceptPremolarsExtraction",
                "acceptDecayedTeethExtraction",
                "acceptIpr"
              ],
              superiorText: "17.1 " + this.$t("cases.createDiagnosis.jcyjsx")
            },
            {
              filed: "acceptWisdomTeethExtraction",
              inquireKey: "accept_wisdom_teeth_extraction",
              superiorText: this.$t("cases.createDiagnosis.hzsfjsbczc"),
              className: lang === "en_US" ? "ml36" : "ml30"
            },
            {
              filed: "acceptPremolarsExtraction",
              inquireKey: "accept_premolars_extraction",
              superiorText: this.$t("cases.createDiagnosis.hzsfjsbcqmy"),
              className: lang === "en_US" ? "ml36" : "ml30"
            },
            {
              filed: "acceptDecayedTeethExtraction",
              inquireKey: "accept_decayed_teeth_extraction",
              superiorText: this.$t("cases.createDiagnosis.hzsfjsbchy"),
              className: lang === "en_US" ? "ml36" : "ml30"
            },
            {
              filed: "acceptIpr",
              inquireKey: "accept_ipr",
              superiorText: this.$t("cases.createDiagnosis.hzsfjslmqy"),
              className: lang === "en_US" ? "ml36" : "ml30"
            },

            {
              onlyHeaderText: true,
              isShow: this.needRdt,
              childOptions: [
                "undergoTeethRestoration",
                "implantAnchoragePlacement",
                "cbctPhotography",
                "orthodonticsTreatmentBefore"
              ],
              superiorText: "17.2 " + this.$t("cases.createDiagnosis.qtlczc")
            },
            {
              filed: "undergoTeethRestoration",
              inquireKey: "undergo_teeth_restoration",
              superiorText: this.$t("cases.createDiagnosis.sfyycxf"),
              className: lang === "en_US" ? "ml36" : "ml30"
            },
            {
              filed: "implantAnchoragePlacement",
              inquireKey: "implant_anchorage_placement",
              superiorText: this.$t("cases.createDiagnosis.zssfjb"),
              className: lang === "en_US" ? "ml36" : "ml30"
            },
            {
              filed: "cbctPhotography",
              inquireKey: "cbct_photography",
              superiorText: this.$t("cases.createDiagnosis.zssfjbps"),
              className: lang === "en_US" ? "ml36" : "ml30"
            },
            {
              filed: "orthodonticsTreatmentBefore",
              inquireKey: "orthodontics_treatment_before",
              superiorText: this.$t("cases.createDiagnosis.hzsfyzjs"),
              className: lang === "en_US" ? "ml36" : "ml30"
            },
            {
              onlyHeaderText: true,
              isShow: this.needRdt,
              childOptions: [
                "upjawExt",
                "upjawLip",
                "upjawSide",
                "upjawSideAnterior",
                "upjawSideRight",
                "upjawSideLeft",
                "upjawFar"
              ],
              superiorText: "17.3 " + this.$t("cases.createDiagnosis.jcyjjzfs")
            },

            {
              identification: this.$t("cases.createDiagnosis.se"),
              childOptions: [
                "upjawExt",
                "upjawLip",
                "upjawSide",
                "upjawSideAnterior",
                "upjawSideRight",
                "upjawSideLeft",
                "upjawFar"
              ],
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml34 preview_pos_2"
                    : "preview_pos_8"
                  : this.needRdt
                  ? "ml38 preview_pos0"
                  : "preview_pos_8"
            },
            {
              filed: "upjawExt",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.kk"),
              parentName: "body_up",
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 preview_pos8"
                  : "ml38 mt12",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? "preview_pos8"
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : ""
            },
            {
              filed: "upjawLip",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.cq"),
              parentName: "body_up",
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 preview_pos8"
                  : "ml38 mt12",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? "preview_pos8"
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : ""
            },
            {
              filed: "upjawSide",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.lmqz"),
              parentName: "body_up",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? "preview_pos8"
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : "",
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 preview_pos8"
                  : "ml38 mt12"
            },
            {
              filed: "upjawSideAnterior",
              inquireKey: "choose_option",
              superiorText:
                this.$t("cases.createDiagnosis.lmqz") +
                " - " +
                this.$t("cases.createDiagnosis.qy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120"
                  : "ml60",

              parentName: "body_up"
            },
            {
              filed: "upjawSideRight",
              inquireKey: "choose_option",
              superiorText:
                this.$t("cases.createDiagnosis.lmqz") +
                " - " +
                this.$t("cases.createDiagnosis.ychy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120"
                  : "ml60",

              parentName: "body_up"
            },
            {
              filed: "upjawSideLeft",
              inquireKey: "choose_option",
              superiorText:
                this.$t("cases.createDiagnosis.lmqz") +
                " - " +
                this.$t("cases.createDiagnosis.zchy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120"
                  : "ml60",
              parentName: "body_up"
            },
            {
              filed: "upjawFar",
              inquireKey: "upjaw_far",
              superiorText: this.$t("cases.createDiagnosis.yymy"),
              parentName: "body_up",
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 mt12"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 preview_pos8"
                  : "ml38 mt12",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? ""
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : ""
            },

            {
              identification: this.$t("cases.createDiagnosis.xe"),
              childOptions: [
                "downjawExt",
                "downjawLip",
                "downjawSide",
                "downjawSideAnterior",
                "downjawSideRight",
                "downjawSideLeft",
                "downjawFar"
              ],
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml34 preview_pos_2"
                    : "preview_pos_8"
                  : this.needRdt
                  ? "ml38 preview_pos_8"
                  : "preview_pos_8"
            },
            {
              filed: "downjawExt",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.kk"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : ""
            },
            {
              filed: "downjawLip",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.cq"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : ""
            },
            {
              filed: "downjawSide",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.lmqz"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : ""
            },
            {
              filed: "downjawSideAnterior",
              inquireKey: "choose_option",
              superiorText:
                this.$t("cases.createDiagnosis.lmqz") +
                " - " +
                this.$t("cases.createDiagnosis.qy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 mt12"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up"
            },
            {
              filed: "downjawSideRight",
              inquireKey: "choose_option",
              superiorText:
                this.$t("cases.createDiagnosis.lmqz") +
                " - " +
                this.$t("cases.createDiagnosis.ychy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 mt12"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up"
            },
            {
              filed: "downjawSideLeft",
              inquireKey: "choose_option",
              superiorText:
                this.$t("cases.createDiagnosis.lmqz") +
                " - " +
                this.$t("cases.createDiagnosis.zchy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 mt12"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up"
            },
            {
              filed: "downjawFar",
              inquireKey: "downjaw_far",
              superiorText: this.$t("cases.createDiagnosis.yymy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 mt12"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up"
            },

            {
              filed: "crowdCorrect",
              inquireKey: "crowd_correct"
            },
            {
              filed: "crowdCorrectSide",
              superiorText: this.$t("cases.createDiagnosis.lmqz"),
              inquireKey: "choose_option_b",
              className: lang === "en_US" ? "ml34 mt12" : "ml38 mt12"
            },
            {
              key: "adjoin"
            }
          ]
        },
        {
          fIndex: 18,
          title: this.$t("cases.createDiagnosis.by"),
          key: "teeth",
          teethType: 4,
          type: "youth",
          pList: [
            {
              filed: "toothExtraction",
              key: "teeth",
              type: "youth",
              inquireKey: "tooth_extraction_options",
              teethType: 4
            }
          ]
        },
        {
          fIndex: 19,
          title: this.$t("cases.createDiagnosis.txt16"),
          pList: [
            {
              filed: "underjawAbnormalFlag",
              inquireKey: "underjaw_abnormal_flag"
            },
            {
              filed: "underjawAbnormalFlagNote",
              type: "note"
            }
          ]
        },
        {
          fIndex: 20,
          title: this.$t("cases.createDiagnosis.sfxybdjzq"),
          pList: [
            {
              filed: "needPassiveAppliance",
              inquireKey: "need_passive_appliance"
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "needPassiveApplianceNote",
              type: "note"
            }
          ]
        },
        {
          fIndex: 21,
          title: this.$t("cases.createDiagnosis.txt17"),
          pList: [
            {
              filed: "specialInstructions",
              type: "note"
            }
          ]
        }
      ];
    },
    babyList() {
      return [
        {
          fIndex: 1,
          title: this.$t("cases.createPreview.zs"),
          pList: [
            {
              filed: "toothProblem",
              superiorText: this.$t("cases.createPreview.ycwt"),
              inquireKey: "tooth_problem_c"
            },
            {
              filed: "jawProblem",
              superiorText: this.$t("cases.createPreview.egwt"),
              inquireKey: "jaw_problem_b"
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "toothOtherProblem",
              type: "note"
            }
          ]
        },
        {
          fIndex: 2,
          title: this.$t("cases.createDiagnosis.zyjzmb"),
          pList: [
            {
              filed: "toothProblemTwo",
              inquireKey: "tooth_problem_two_c",
              superiorText: this.$t("cases.createPreview.ycwt")
            },
            {
              filed: "jawProblemTwo",
              inquireKey: "jaw_problem_two",
              superiorText: this.$t("cases.createPreview.egwt")
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "toothOtherProblemTwo",
              type: "note"
            }
          ]
        },
        // {
        //   fIndex: 3,
        //   title: this.$t('cases.createDiagnosis.txt2'),
        //   pList: [
        //     {
        //       filed: 'sagittalMaxillomandibularClassification',
        //       superiorText: this.$t('cases.createDiagnosis.gxfl'),
        //       inquireKey: 'sagittal_maxillomandibular_classification'
        //     },
        //     {
        //       filed: 'clinicInfo',
        //       inquireKey: 'clinic_info',
        //       superiorText: this.$t('cases.createDiagnosis.chlx'),
        //     },
        //     {
        //       tit: this.$t('cases.createDiagnosis.txt17'),
        //       filed: 'clinicOtherInfo',
        //       type: 'note'
        //     }
        //   ]
        // },
        {
          fIndex: 3,
          title: this.$t("cases.createDiagnosis.blxg"),
          pList: [
            {
              filed: "badHabits",
              inquireKey: "bad_habits"
            },
            {
              filed: "badHabitsNote",
              tit: this.$t("cases.createDiagnosis.txt17"),
              type: "note"
            }
          ]
        },
        {
          fIndex: 4,
          title: this.$t("cases.createDiagnosis.txt3"),
          pList: [
            {
              filed: "toCorrectToothJaw",
              inquireKey: "to_correct_tooth_jaw"
            },
            {
              filed: "toCorrectToothUpjaw",
              inquireKey: "to_correct_tooth_upjaw_b",
              sublevel: true
            },
            {
              filed: "toCorrectToothDownjaw",
              inquireKey: "to_correct_tooth_downjaw_b",
              sublevel: true
            }
          ]
        },
        {
          fIndex: 5,
          title: this.$t("cases.createDiagnosis.txt4"),
          key: "teeth",
          type: "children",
          pList: [
            {
              showTeeth: true,
              type: "children",
              key: "teeth",
              teethType: 1
            }
          ]
        },
        {
          fIndex: 6,
          title: this.$t("cases.createDiagnosis.txt5"),
          key: "teeth",
          type: "children",
          pList: [
            {
              type: "children",
              key: "teeth",
              filed: "nonMoveableTeeth",
              inquireKey: "non_moveable_teeth",
              teethType: 2
            }
          ]
        },
        {
          fIndex: 7,
          title: this.$t("cases.createDiagnosis.txt6"),
          key: "teeth",
          type: "children",
          pList: [
            {
              type: "children",
              key: "teeth",
              filed: "notAvailableAttachments",
              inquireKey: "not_available_for_attachments",
              teethType: 3
            }
          ]
        },
        {
          fIndex: 8,
          title: this.$t("cases.createDiagnosis.txt7"),
          pList: [
            {
              filed: "sagittalRelation",
              inquireKey: "sagittal_relation"
            },
            {
              filed: "sagittalRelationMolar",
              inquireKey: "improve_molar",
              differentiateKey: "left",
              describeInputKey: "leftSagittalRelationMolarNote",
              sublevel: true
            },
            {
              filed: "sagittalRelationMolar",
              inquireKey: "improve_molar",
              differentiateKey: "right",
              describeInputKey: "rightSagittalRelationMolarNote",
              sublevel: true
            }
          ]
        },
        {
          fIndex: 9,
          title: this.$t("cases.createDiagnosis.hxgx"),
          pList: [
            {
              filed: "upHorizontalRelationship",
              inquireKey: "up_horizontal_relationship",
              secondLevel: this.$t("common.common.up")
            },
            {
              filed: "upHorizontalRelationshipNote",
              inquireKey: "up_horizontal_expansion",
              describeInputKey: "upHorizontalRelationshipValue",
              isShowMM: true,
              sublevel: true
            },

            {
              filed: "downHorizontalRelationship",
              inquireKey: "down_horizontal_relationship",
              secondLevel: this.$t("common.common.down")
            },
            {
              filed: "downHorizontalRelationshipNote",
              inquireKey: "down_horizontal_expansion",
              describeInputKey: "downHorizontalRelationshipValue",
              isShowMM: true,
              sublevel: true
            }
          ]
        },
        {
          fIndex: 10,
          title: this.$t("cases.createDiagnosis.txt8"),
          pList: [
            {
              filed: "coverInfo",
              inquireKey: "cover_info"
            }
          ]
        },
        {
          fIndex: 11,
          title: this.$t("cases.createDiagnosis.txt9"),
          pList: [
            {
              filed: "overbiteInfo",
              inquireKey: "overbite_info"
            },

            {
              filed: "overbiteInfoOpen",
              inquireKey: "correct_open_b",
              sublevel: true,
              threeOptions: [
                {
                  key: "overbiteInfoExtrudeAnterior",
                  inquireKey: "updown_jaw",
                  parentKey: "extend_front_tooth"
                }
              ]
            },
            {
              filed: "overbiteInfoDeep",
              inquireKey: "correct_deep_cover_b",
              sublevel: true,
              threeOptions: [
                {
                  key: "overbiteInfoIntrudeAnterior",
                  inquireKey: "updown_jaw",
                  parentKey: "press_front"
                },
                {
                  key: "overbiteInfoPosteriorsExrusion",
                  inquireKey: "updown_jaw",
                  parentKey: "press_front_rise_back"
                }
              ]
            },

            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "overbiteOtherInfo",
              type: "note"
            }
          ]
        },
        {
          fIndex: 12,
          title: this.$t("cases.createDiagnosis.qyfh"),
          pList: [
            {
              filed: "frontToothInfo",
              inquireKey: "front_tooth_info_b"
            },
            {
              filed: "correctFrontReverse",
              inquireKeys: [
                "correct_front_reverse",
                "correct_front_reverse_two"
              ],
              parentText: "",
              sublevel: true
            }
          ]
        },
        {
          fIndex: 13,
          title: this.$t("cases.createDiagnosis.txt11"),
          pList: [
            {
              filed: "backToothInfo",
              inquireKey: "back_tooth_info"
            },
            {
              filed: "backToothInfoCorrect",
              inquireKey: "back_tooth_info_correct_b",
              sublevel: true
            }
          ]
        },
        {
          fIndex: 14,
          title: this.$t("cases.createPreview.zx"),
          pList: [
            {
              filed: "middleLine",
              inquireKey: "middle_line"
            },
            {
              filed: "upjawMiddleLineNote",
              inquireKey: "improve_middle_move",
              describeInputKey: "upjawMiddleLine",
              superiorText: this.$t("cases.createDiagnosis.se"),
              isShowMM: true,
              sublevel: true
            },

            {
              filed: "downjawMiddleLineNote",
              inquireKey: "improve_middle_move",
              describeInputKey: "downjawMiddleLine",
              isShowMM: true,
              superiorText: this.$t("cases.createDiagnosis.xe"),
              sublevel: true
            }
          ]
        },
        {
          fIndex: 15,
          title: this.$t("cases.createDiagnosis.txt13"),
          pList: [
            {
              tit: this.$t("cases.createDiagnosis.txt4"),
              filed: "teeth",
              key: "interval"
            }
          ]
        },
        {
          fIndex: 16,
          title: this.$t("cases.createPreview.yjzl"),
          pList: [
            {
              onlyHeaderText: true,
              isShow: this.needRdt,
              childOptions: [
                "upjawExt",
                "upjawLip",
                "upjawSide",
                "upjawSideAnterior",
                "upjawSideRight",
                "upjawSideLeft",
                "upjawFar"
              ]
              // superiorText: this.$t('cases.createDiagnosis.jcyjjzfs'),
            },

            {
              identification: this.$t("cases.createDiagnosis.se"),
              childOptions: [
                "upjawExt",
                "upjawLip",
                "upjawSide",
                "upjawSideAnterior",
                "upjawSideRight",
                "upjawSideLeft",
                "upjawFar"
              ],
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml34 preview_pos_2"
                    : "preview_pos_8"
                  : this.needRdt
                  ? "ml38 preview_pos0"
                  : "preview_pos_8"
            },
            {
              filed: "upjawExt",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.kk"),
              parentName: "body_up",
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 preview_pos8"
                  : "ml38 mt12",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? "preview_pos8"
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : ""
            },
            {
              filed: "upjawLip",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.cq"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 preview_pos8"
                  : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? "preview_pos8"
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : ""
            },
            {
              filed: "upjawSide",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.lmqz"),
              parentName: "body_up",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? "preview_pos8"
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : "",
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 preview_pos8"
                  : "ml38 mt12"
            },
            {
              filed: "upjawSideAnterior",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.qy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120 preview_pos8"
                  : "ml60",
              parentName: "body_up",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? ""
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : ""
            },
            {
              filed: "upjawSideRight",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.ychy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120 preview_pos8"
                  : "ml60",
              parentName: "body_up",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? ""
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : ""
            },
            {
              filed: "upjawSideLeft",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.zchy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120 preview_pos8"
                  : "ml60",
              parentName: "body_up",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? ""
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : ""
            },
            {
              filed: "upjawFar",
              inquireKey: "upjaw_far",
              superiorText: this.$t("cases.createDiagnosis.yymry"),
              parentName: "body_up",
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 mt12"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 preview_pos8"
                  : "ml38 mt12",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? ""
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : ""
            },

            {
              identification: this.$t("cases.createDiagnosis.xe"),
              childOptions: [
                "downjawExt",
                "downjawLip",
                "downjawSide",
                "downjawSideAnterior",
                "downjawSideRight",
                "downjawSideLeft",
                "downjawFar"
              ],
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml34 preview_pos_2"
                    : "preview_pos_8"
                  : this.needRdt
                  ? "ml38 preview_pos_8"
                  : "preview_pos_8"
            },
            {
              filed: "downjawExt",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.kk"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : ""
            },
            {
              filed: "downjawLip",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.cq"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : ""
            },
            {
              filed: "downjawSide",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.lmqz"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : ""
            },
            {
              filed: "downjawSideAnterior",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.qy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120"
                  : "ml60",
              parentName: "body_up"
            },
            {
              filed: "downjawSideRight",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.ychy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120"
                  : "ml60",
              parentName: "body_up"
            },
            {
              filed: "downjawSideLeft",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.zchy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120"
                  : "ml60",
              parentName: "body_up"
            },
            {
              filed: "downjawFar",
              inquireKey: "downjaw_far",
              superiorText: this.$t("cases.createDiagnosis.yymry"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 mt12"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up"
            },
            {
              filed: "crowdCorrect",
              inquireKey: "crowd_correct"
            },
            {
              filed: "crowdCorrectSide",
              superiorText: this.$t("cases.createDiagnosis.lmqz"),
              inquireKey: "choose_option_b",
              className: lang === "en_US" ? "ml34 mt12" : "ml38 mt12"
            },
            {
              key: "adjoin"
            }
          ]
        },
        {
          fIndex: 17,
          title: this.$t("cases.createDiagnosis.sfxyfzmy"),
          pList: [
            {
              filed: "eruptedCompensate",
              inquireKey: "erupted_compensate"
            },
            {
              filed: "eruptedCompensateNote",
              type: "note"
            }
          ]
        },
        {
          fIndex: 18,
          title: this.$t("cases.createDiagnosis.sfxybdjzq"),
          pList: [
            {
              filed: "needPassiveAppliance",
              inquireKey: "need_passive_appliance"
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "needPassiveApplianceNote",
              type: "note"
            }
          ]
        },
        {
          fIndex: 19,
          title: this.$t("cases.createDiagnosis.txt17"),
          pList: [
            {
              filed: "specialInstructions",
              type: "note"
            }
          ]
        }
      ];
    },
    braceList() {
      return [
        {
          fIndex: 1,
          title: this.$t("cases.createDiagnosis.exgjsfczyc"),
          pList: [
            {
              filed: "underjawAbnormalFlag",
              inquireKey: "underjaw_abnormal_flag"
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "underjawAbnormalFlagNote",
              type: "note"
            }
          ]
        },
        {
          fIndex: 2,
          title: this.$t("cases.createDiagnosis.yzsfzc"),
          pList: [
            {
              filed: "periodontalDisease",
              inquireKey: "periodontal_disease"
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "periodontalDiseaseNote",
              type: "note"
            }
          ]
        },
        {
          fIndex: 3,
          title: this.$t("cases.createDiagnosis.txt17"),
          pList: [
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "specialInstructions",
              type: "note"
            }
          ]
        }
      ];
    },
    veneerList() {
      return [
        {
          fIndex: 1,
          title: this.$t("cases.createPreview.zs"),
          pList: [
            {
              filed: "toothProblem",
              superiorText: this.$t("cases.createPreview.ycwt"),
              inquireKey: "tooth_problem"
            },
            {
              filed: "jawProblem",
              superiorText: this.$t("cases.createPreview.egwt"),
              inquireKey: "jaw_problem"
            },
            {
              filed: "toothOtherProblem",
              type: "note"
            }
          ]
        },
        {
          fIndex: 2,
          title: this.$t("cases.createDiagnosis.zyjzmb"),
          pList: [
            {
              filed: "toothProblemTwo",
              superiorText: this.$t("cases.createPreview.ycwt"),
              inquireKey: "tooth_problem_two"
            },
            {
              filed: "jawProblemTwo",
              superiorText: this.$t("cases.createPreview.egwt"),
              inquireKey: "jaw_problem_two"
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "toothOtherProblemTwo",
              type: "note"
            }
          ]
        },
        {
          fIndex: 3,
          title: this.$t("cases.createDiagnosis.txt2"),
          pList: [
            {
              filed: "angleClassification",
              superiorText: this.$t("cases.createDiagnosis.asfl"),
              inquireKey: "angle_classification"
            },
            {
              filed: "sagittalMaxillomandibularClassification",
              superiorText: this.$t("cases.createDiagnosis.gxfl"),
              inquireKey: "sagittal_maxillomandibular_classification"
            },
            {
              filed: "clinicInfo",
              superiorText: this.$t("cases.createDiagnosis.chlx"),
              inquireKey: "clinic_info"
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "clinicOtherInfo",
              type: "note"
            }
          ]
        },
        {
          fIndex: 4,
          title: this.$t("cases.createPreview.njzye"),
          pList: [
            {
              filed: "toCorrectToothJaw",
              inquireKey: "to_correct_tooth_jaw"
            },
            {
              filed: "toCorrectToothUpjaw",
              inquireKey: "to_correct_tooth_upjaw",
              sublevel: true
            },
            {
              filed: "toCorrectToothDownjaw",
              inquireKey: "to_correct_tooth_downjaw",
              sublevel: true
            }
          ]
        },
        {
          fIndex: 5,
          title: this.$t("cases.createPreview.ycxx"),
          key: "teeth",
          teethType: 1,
          type: "adult",
          pList: [
            {
              showTeeth: true,
              type: "adult",
              key: "teeth",
              teethType: 1
            }
          ]
        },
        {
          fIndex: 6,
          title: this.$t("cases.createDiagnosis.txt5"),
          teethType: 2,
          pList: [
            {
              filed: "nonMoveableTeeth",
              inquireKey: "non_moveable_teeth",
              type: "adult",
              key: "teeth",
              teethType: 2
            }
          ]
        },
        {
          fIndex: 7,
          title: this.$t("cases.createDiagnosis.txt6"),
          key: "teeth",
          teethType: 3,
          type: "adult",
          pList: [
            {
              filed: "notAvailableAttachments",
              inquireKey: "not_available_for_attachments",
              type: "adult",
              key: "teeth",
              teethType: 3
            }
          ]
        },

        {
          fIndex: 8,
          title: this.$t("cases.createPreview.jzgx"),
          pList: [
            {
              filed: "rightSagittalRelation",
              inquireKey: "right_sagittal_relation",
              secondLevel: this.$t("cases.createDiagnosis.you")
            },
            {
              filed: "rightSagittalRelationNote",
              inquireKey: "improve_tip",
              threeOptions: [
                {
                  key: "rightSagittalRelationFar",
                  inquireKey: "precision_cut",
                  parentKey: "far_grind"
                },
                {
                  key: "rightSagittalRelationBitJump",
                  inquireKey: "precision_cut",
                  parentKey: "bit_jump"
                },
                {
                  key: "rightSagittalRelationExtraction",
                  inquireKey: "tooth_extraction",
                  parentKey: "tooth_extraction"
                }
              ],
              sublevel: true
            },

            {
              filed: "leftSagittalRelation",
              inquireKey: "left_sagittal_relation",
              secondLevel: this.$t("cases.createPreview.zuo")
            },
            {
              filed: "leftSagittalRelationNote",
              inquireKey: "improve_tip",
              threeOptions: [
                {
                  key: "leftSagittalRelationFar",
                  inquireKey: "precision_cut",
                  parentKey: "far_grind"
                },
                {
                  key: "leftSagittalRelationBitJump",
                  inquireKey: "precision_cut",
                  parentKey: "bit_jump"
                },
                {
                  key: "leftSagittalRelationExtraction",
                  inquireKey: "tooth_extraction",
                  parentKey: "tooth_extraction"
                }
              ],
              sublevel: true
            }
          ]
        },
        {
          fIndex: 9,
          title: this.$t("cases.createPreview.fg"),
          pList: [
            {
              filed: "coverInfo",
              inquireKey: "cover_info"
            }
          ]
        },
        {
          fIndex: 10,
          title: this.$t("cases.createPreview.fh"),
          pList: [
            {
              filed: "overbiteInfo",
              inquireKey: "overbite_info"
            },

            {
              filed: "overbiteInfoOpen",
              inquireKey: "correct_open",
              sublevel: true
            },
            {
              filed: "overbiteInfoDeep",
              inquireKey: "correct_deep_cover",
              sublevel: true,
              threeOptions: [
                {
                  key: "overbiteInfoIntrudeAnterior",
                  inquireKey: "updown_jaw",
                  parentKey: "press_front"
                },
                {
                  key: "overbiteInfoPosteriorsExrusion",
                  inquireKey: "updown_jaw",
                  parentKey: "press_front_rise_back"
                }
              ]
            },

            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "overbiteOtherInfo",
              type: "note"
            }
          ]
        },
        {
          fIndex: 11,
          title: this.$t("cases.createPreview.yd"),
          pList: [
            {
              filed: "frontToothInfo",
              inquireKey: "front_tooth_info"
            },

            {
              filed: "correctFrontReverse",
              inquireKeys: [
                "correct_front_reverse",
                "correct_front_reverse_two"
              ],
              parentText: this.$t("cases.createPreview.jzqyfh"),
              sublevel: true
            },
            {
              filed: "correctFrontOpposite",
              inquireKeys: ["correct_front_opposite"],
              parentText: this.$t("cases.createPreview.jzqydrh"),
              sublevel: true
            }
          ]
        },
        {
          fIndex: 12,
          title: this.$t("cases.createPreview.hsh"),
          pList: [
            {
              filed: "backToothInfo",
              inquireKey: "back_tooth_info"
            },
            {
              filed: "backToothInfoCorrect",
              inquireKey: "back_tooth_info_correct",
              sublevel: true
            }
          ]
        },
        {
          fIndex: 13,
          title: this.$t("cases.createPreview.zx"),
          pList: [
            {
              filed: "middleLine",
              inquireKey: "middle_line"
            },
            {
              filed: "upjawMiddleLineNote",
              inquireKey: "improve_middle_move",
              describeInputKey: "upjawMiddleLine",
              superiorText: this.$t("cases.createDiagnosis.se"),
              isShowMM: true,
              sublevel: true
            },

            {
              filed: "downjawMiddleLineNote",
              inquireKey: "improve_middle_move",
              describeInputKey: "downjawMiddleLine",
              isShowMM: true,
              superiorText: this.$t("cases.createDiagnosis.xe"),
              sublevel: true
            }
          ]
        },
        {
          fIndex: 14,
          title: this.$t("cases.createPreview.jxzl"),
          pList: [
            {
              filed: "upjawGapCorrect",
              inquireKey: "updown_jaw_gap_correct",
              secondLevel: this.$t("cases.createPreview.she")
            },
            {
              filed: "downjawGapCorrect",
              inquireKey: "updown_jaw_gap_correct",
              secondLevel: this.$t("cases.createPreview.xhe")
            },
            {
              filed: "gapCorrect",
              inquireKey: "gap_correct",
              key: "reserve"
            },
            {
              tit: this.$t("cases.createDiagnosis.txt4"),
              filed: "teeth"
            }
          ]
        },
        {
          fIndex: 15,
          title: this.$t("cases.createPreview.yjzl"),
          pList: [
            {
              onlyHeaderText: true,
              isShow: this.needRdt,
              childOptions: [
                "acceptWisdomTeethExtraction",
                "acceptPremolarsExtraction",
                "acceptDecayedTeethExtraction",
                "acceptIpr"
              ],
              superiorText: "15.1 " + this.$t("cases.createDiagnosis.jcyjsx")
            },
            {
              filed: "acceptWisdomTeethExtraction",
              inquireKey: "accept_wisdom_teeth_extraction",
              superiorText: this.$t("cases.createDiagnosis.hzsfjsbczc"),
              className: lang === "en_US" ? "ml36" : "ml30"
            },
            {
              filed: "acceptPremolarsExtraction",
              inquireKey: "accept_premolars_extraction",
              superiorText: this.$t("cases.createDiagnosis.hzsfjsbcqmy"),
              className: lang === "en_US" ? "ml36" : "ml30"
            },
            {
              filed: "acceptDecayedTeethExtraction",
              inquireKey: "accept_decayed_teeth_extraction",
              superiorText: this.$t("cases.createDiagnosis.hzsfjsbchy"),
              className: lang === "en_US" ? "ml36" : "ml30"
            },
            {
              filed: "acceptIpr",
              inquireKey: "accept_ipr",
              superiorText: this.$t("cases.createDiagnosis.hzsfjslmqy"),
              className: lang === "en_US" ? "ml36" : "ml30"
            },

            {
              onlyHeaderText: true,
              isShow: this.needRdt,
              childOptions: [
                "undergoTeethRestoration",
                "implantAnchoragePlacement",
                "cbctPhotography",
                "orthodonticsTreatmentBefore"
              ],
              superiorText: "15.2 " + this.$t("cases.createDiagnosis.qtlczc")
            },
            {
              filed: "undergoTeethRestoration",
              inquireKey: "undergo_teeth_restoration",
              superiorText: this.$t("cases.createDiagnosis.sfyycxf"),
              className: lang === "en_US" ? "ml36" : "ml30"
            },
            {
              filed: "implantAnchoragePlacement",
              inquireKey: "implant_anchorage_placement",
              superiorText: this.$t("cases.createDiagnosis.zssfjb"),
              className: lang === "en_US" ? "ml36" : "ml30"
            },
            {
              filed: "cbctPhotography",
              inquireKey: "cbct_photography",
              superiorText: this.$t("cases.createDiagnosis.zssfjbps"),
              className: lang === "en_US" ? "ml36" : "ml30"
            },
            {
              filed: "orthodonticsTreatmentBefore",
              inquireKey: "orthodontics_treatment_before",
              superiorText: this.$t("cases.createDiagnosis.hzsfyzjs"),
              className: lang === "en_US" ? "ml36" : "ml30"
            },
            {
              onlyHeaderText: true,
              isShow: this.needRdt,
              childOptions: [
                "upjawExt",
                "upjawLip",
                "upjawSide",
                "upjawSideAnterior",
                "upjawSideRight",
                "upjawSideLeft",
                "upjawFar"
              ],
              superiorText: "15.3 " + this.$t("cases.createDiagnosis.jcyjjzfs")
            },

            {
              identification: this.$t("cases.createDiagnosis.se"),
              childOptions: [
                "upjawExt",
                "upjawLip",
                "upjawSide",
                "upjawSideAnterior",
                "upjawSideRight",
                "upjawSideLeft",
                "upjawFar"
              ],
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml34 preview_pos_2"
                    : "preview_pos_8"
                  : this.needRdt
                  ? "ml38 preview_pos0"
                  : "preview_pos_8"
            },
            {
              filed: "upjawExt",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.kk"),
              parentName: "body_up",
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 preview_pos8"
                  : "ml38 mt12",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? "preview_pos8"
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : ""
            },
            {
              filed: "upjawLip",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.cq"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 preview_pos8"
                  : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? "preview_pos8"
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : ""
            },
            {
              filed: "upjawSide",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.lmqz"),
              parentName: "body_up",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? "preview_pos8"
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : "",
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 preview_pos8"
                  : "ml38 mt12"
            },
            {
              filed: "upjawSideAnterior",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.qy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120 preview_pos8"
                  : "ml60",
              parentName: "body_up",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? ""
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : ""
            },
            {
              filed: "upjawSideRight",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.ychy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120 preview_pos8"
                  : "ml60",
              parentName: "body_up",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? ""
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : ""
            },
            {
              filed: "upjawSideLeft",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.zchy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120 preview_pos8"
                  : "ml60",
              parentName: "body_up",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? ""
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : ""
            },
            {
              filed: "upjawFar",
              inquireKey: "upjaw_far",
              superiorText: this.$t("cases.createDiagnosis.yymy"),
              parentName: "body_up",
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 mt12"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 preview_pos8"
                  : "ml38 mt12",
              answerClass:
                lang === "en_US"
                  ? this.needRdt
                    ? ""
                    : ""
                  : this.needRdt
                  ? "preview_pos8"
                  : ""
            },

            {
              identification: this.$t("cases.createDiagnosis.xe"),
              childOptions: [
                "downjawExt",
                "downjawLip",
                "downjawSide",
                "downjawSideAnterior",
                "downjawSideRight",
                "downjawSideLeft",
                "downjawFar"
              ],
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml34 preview_pos_2"
                    : "preview_pos_8"
                  : this.needRdt
                  ? "ml38 preview_pos_8"
                  : "preview_pos_8"
            },
            {
              filed: "downjawExt",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.kk"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : ""
            },
            {
              filed: "downjawLip",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.cq"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : ""
            },
            {
              filed: "downjawSide",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.lmqz"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 preview_pos8"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up",
              answerClass:
                lang === "en_US" ? (this.needRdt ? "preview_pos8" : "") : ""
            },
            {
              filed: "downjawSideAnterior",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.qy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120"
                  : "ml60",
              parentName: "body_up"
            },
            {
              filed: "downjawSideRight",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.ychy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120"
                  : "ml60",
              parentName: "body_up"
            },
            {
              filed: "downjawSideLeft",
              inquireKey: "choose_option",
              superiorText: this.$t("cases.createDiagnosis.zchy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml150"
                    : "ml120"
                  : this.needRdt
                  ? "ml120"
                  : "ml60",
              parentName: "body_up"
            },
            {
              filed: "downjawFar",
              inquireKey: "downjaw_far",
              superiorText: this.$t("cases.createDiagnosis.yymy"),
              className:
                lang === "en_US"
                  ? this.needRdt
                    ? "ml120 mt12"
                    : "ml90 mt12"
                  : this.needRdt
                  ? "ml70 mt12"
                  : "ml38 mt12",
              parentName: "body_up"
            },

            {
              filed: "crowdCorrect",
              inquireKey: "crowd_correct",
              className: lang === "en_US" ? "ml34 mt12" : "ml38 mt12"
            },
            {
              filed: "toothExtraction",
              key: "teeth",
              teethType: 4,
              type: "adult",
              superiorText: this.$t("cases.createDiagnosis.by"),
              inquireKey: "tooth_extraction_options"
            }
          ]
        },
        {
          fIndex: 16,
          title: this.$t("cases.createPreview.sfphz"),
          pList: [
            {
              filed: "growNailFlag",
              inquireKey: "grow_nail_flag"
            },
            {
              filed: "upGrowNailPosition",
              inquireKey: "grow_nail_position",
              superiorText:
                "16.1 " +
                this.$t("cases.createDiagnosis.nzrzzkd") +
                " - " +
                this.$t("cases.createDiagnosis.se")
            },
            {
              filed: "downGrowNailPosition",
              inquireKey: "grow_nail_position",
              superiorText:
                "16.1 " +
                this.$t("cases.createDiagnosis.nzrzzkd") +
                " - " +
                this.$t("cases.createDiagnosis.xe")
            },
            {
              filed: "growNailPurpose",
              inquireKey: "grow_nail_purpose",
              superiorText: "16.2 " + this.$t("cases.createDiagnosis.nzrzzkdzy")
            },
            {
              filed: "growNailFlagNote",
              type: "note"
            }
          ]
        },
        {
          fIndex: 17,
          title: this.$t("cases.createPreview.hgj"),
          pList: [
            {
              filed: "underjawAbnormalFlag",
              inquireKey: "underjaw_abnormal_flag"
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "underjawAbnormalFlagNote",
              type: "note"
            }
          ]
        },
        {
          fIndex: 18,
          title: this.$t("cases.createDiagnosis.sfxybdjzq"),
          pList: [
            {
              filed: "needPassiveAppliance",
              inquireKey: "need_passive_appliance"
            },
            {
              tit: this.$t("cases.createDiagnosis.txt17"),
              filed: "needPassiveApplianceNote",
              type: "note"
            }
          ]
        },
        {
          fIndex: 19,
          title: this.$t("cases.createDiagnosis.xfsj")
        },
        {
          fIndex: 19.1,
          title:
            this.$t("cases.createDiagnosis.xfsj") +
            " - " +
            this.$t("cases.createDiagnosis.sxyw"),
          pList: [
            {
              veneer: "veneer",
              showVeneer: true,
              type: "veneer",
              teethType: 10
            }
          ]
        },
        {
          fIndex: 19.2,
          title:
            this.$t("cases.createDiagnosis.xfsj") +
            " - " +
            this.$t("cases.createDiagnosis.yysx"),
          pList: [
            {
              filed: "originalTeethColor",
              inquireKey: "original_teeth_color"
            },
            {
              filed: "originalTeethColorNote",
              type: "note"
            }
          ]
        },
        {
          fIndex: 19.3,
          title:
            this.$t("cases.createDiagnosis.xfsj") +
            " - " +
            this.$t("cases.createDiagnosis.mbys"),
          pList: [
            {
              filed: "targetColor",
              inquireKey: "target_color"
            },
            {
              filed: "targetColorNote",
              type: "note"
            }
          ]
        },
        {
          fIndex: 19.4,
          title:
            this.$t("cases.createDiagnosis.xfsj") +
            " - " +
            this.$t("cases.createDiagnosis.ymwl"),
          pList: [
            {
              filed: "surfaceTexture",
              inquireKey: "surface_texture"
            }
          ]
        },
        {
          fIndex: 19.5,
          title:
            this.$t("cases.createDiagnosis.xfsj") +
            " - " +
            this.$t("cases.createDiagnosis.sjyq"),
          pList: [
            {
              filed: "designRequestOne",
              inquireKey: "design_request_one"
            },
            {
              filed: "designRequestTwo",
              inquireKey: "design_request_two"
            }
          ]
        },
        {
          fIndex: 20,
          title: this.$t("cases.createPreview.tssm"),
          pList: [
            {
              filed: "specialInstructions",
              type: "note"
            }
          ]
        }
      ];
    },
    yysdList() {
      return [
        {
          fIndex: 1,
          title: this.$t("cases.createDiagnosis.ycxxqy"),
          pList: [
            {
              showYysd: true,
              teethType: 1
            }
          ]
        },
        {
          fIndex: 2,
          title: this.$t("cases.createDiagnosis.ycxxjy"),
          pList: [
            {
              showYysd: true,
              teethType: 11
            }
          ]
        },
        {
          fIndex: 3,
          title: this.$t("cases.createDiagnosis.lczk"),
          pList: [
            {
              filed: "clinicInfo",
              superiorText: this.$t("cases.createDiagnosis.chlx"),
              inquireKey: "clinic_info_f"
            },
            {
              filed: "clinicOtherInfo",
              type: "note"
            },
            {
              filed: "occlusalRelationship",
              superiorText: this.$t("cases.createDiagnosis.yhgx"),
              inquireKey: "occlusal_relationship"
            },
            {
              filed: "adjacentGap",
              superiorText: this.$t("cases.createDiagnosis.ljx"),
              inquireKey: "adjacent_gap"
            },
            {
              filed: "toothProblemThree",
              superiorText: this.$t("cases.createDiagnosis.ycwt"),
              inquireKey: "tooth_problem_three"
            },
            {
              filed: "toothOtherProblemThree",
              type: "note"
            }
          ]
        },
        {
          fIndex: 4,
          title: this.$t("cases.createDiagnosis.sxyw"),
          pList: [
            {
              showYysd: true,
              childKey: true
            },
            {
              filed: "desiredToothPosition",
              type: "note"
            }
          ]
        },
        {
          fIndex: 5,
          title: this.$t("cases.createDiagnosis.yysx"),
          pList: [
            {
              filed: "originalTeethColor",
              inquireKey: "original_teeth_color_f"
            },
            {
              filed: "colorSystem",
              inquireKeys: ["ivoclar", "vita"],
              describeInputKey: "colorNumber",
              isShowMM: false
            },
            {
              filed: "originalTeethColorNote",
              type: "note"
            }
          ]
        },
        {
          fIndex: 6,
          title: this.$t("cases.createDiagnosis.mbyase"),
          pList: [
            {
              filed: "targetColor",
              inquireKey: "target_color"
            },
            {
              filed: "targetColorNote",
              type: "note"
            }
          ]
        },
        {
          fIndex: 7,
          title: this.$t("cases.createDiagnosis.mblx"),
          pList: [
            {
              filed: "targetType",
              inquireKey: "target_type"
            },
            {
              filed: "targetTypeNote",
              type: "note"
            }
          ]
        },
        {
          fIndex: 8,
          title: this.$t("cases.createDiagnosis.mbtmd"),
          pList: [
            {
              filed: "targetTransparency",
              inquireKey: "target_transparency"
            }
          ]
        },
        {
          fIndex: 9,
          title: this.$t("cases.createDiagnosis.cllx"),
          pList: [
            {
              filed: "materialType",
              inquireKey: "material_type"
            }
          ]
        },
        {
          fIndex: 10,
          title: this.$t("cases.createDiagnosis.sfby"),
          pList: [
            {
              filed: "whetherToPrepareTeeth",
              inquireKey: "whether_to_prepare_teeth",
              type: "yysd",
              teethType: 14,
              showYysd: true
            }
          ]
        },
        {
          fIndex: 11,
          title: this.$t("cases.createDiagnosis.tmhdyq"),
          pList: [
            {
              filed: "veneerThicknessRequirements",
              value: true
            }
          ]
        },
        {
          fIndex: 12,
          title: this.$t("cases.createDiagnosis.ymwl"),
          pList: [
            {
              filed: "surfaceTexture",
              inquireKey: "surface_texture"
            }
          ]
        },
        {
          fIndex: 13,
          title: this.$t("cases.createDiagnosis.sjyq"),
          pList: [
            {
              filed: "designRequestOne",
              inquireKey: "design_request_one_f"
            },
            {
              filed: "designRequestTwo",
              inquireKey: "design_request_two_f"
            }
          ]
        },
        {
          fIndex: 14,
          title: this.$t("cases.createDiagnosis.txt17"),
          pList: [
            {
              filed: "specialInstructions",
              type: "note"
            }
          ]
        }
      ];
    }
  },
  methods: {
    ...mapActions({
      getCaseCureDetail: "actionGetCaseCureDetail" //治疗表详情
    }),
    get,
    getPublicPlasticSheetList(productId) {
      PublicPlasticSheetList({
        productId,
        stage: "D"
      }).then(res => {
        this.plasticsSheetList = res;
      });
    },
    queryDictData() {
      getDictAllList([
        "treatment_status",
        "need",
        "aligner_based_on",
        "reserved_position",
        "true_false",
        "submit",
        "case.stage",
        "fit_status",
        "accessory_adjustment",
        "dentition_space",
        "underjaw_abnormal_flag",
        "non_moveable_teeth",
        "not_available_for_attachments"
      ]).then(data => {
        let diaKeyObj = {},
          dictObj = {};
        data.forEach(item => {
          if (!dictObj[item.parentKey]) {
            dictObj[item.parentKey] = [item];
          } else {
            dictObj[item.parentKey].push(item);
          }
          diaKeyObj[item.key] = item.value;
        });
        this.diaKeyObj = diaKeyObj;
        this.dictObj = dictObj;
      });
    },
    leftItemClick(item, index) {
      this.getDetail(item.id);
      this.leftListActive = index;
      this.caseStage = item.stage;
      this.dataType = item.type;
    },
    getLeftList() {
      let caseId = this.$route.query.caseId;
      return caseDiagnosisList({ caseId }).then(data => {
        this.leftList = data;
      });
    },
    tabsChange(key) {
      let route = this.$route;
      this.$router.replace({
        name: route.name,
        query: extend({}, route.query, { tabKey: key })
      });
    },
    getDiaOption() {
      let type = this.productType;
      this.casesData = {
        A: this.adult,
        B: this.youthList,
        C: this.babyList,
        D: this.braceList,
        E: this.veneerList,
        F: this.yysdList,
        G: this.youthList,
        H: this.youthList,
        I: this.gap
      }[type];
    },
    isJSON(str) {
      if (str == "") {
        return "";
      }
      if (typeof str == "string") {
        try {
          JSON.parse(str);
          let obj = JSON.parse(str);
          !obj.nanoId && (obj.nanoId = obj.ETag);
          !obj.degree && (obj.degree = 0);
          !obj.horizontal && (obj.horizontal = false);
          !obj.vertical && (obj.vertical = false);
          return obj;
        } catch (error) {
          return {
            ETag: str,
            nanoId: str,
            degree: 0,
            horizontal: false,
            vertical: false
          };
        }
      }
    },
    getCaseDetail(caseId) {
      casesDetail(caseId).then(res => {
        this.caseInfo = res;
        this.productType = res.cureType;
        this.userInfo = res["userInfo"];
        const plasticProductId =
          res["bindProductId"] !== "0"
            ? res["bindProductId"]
            : res["productId"];
        this.getPublicPlasticSheetList(plasticProductId);
        this.getDiaOption();
        this.getLeftList().then(() => {
          this.getDetail();
        });
      });
    },
    getDetail(itemId) {
      this.queryDictData();
      let id = itemId || this.leftList[0].id;
      this.caseStage = this.leftList[0].stage;
      this.dataType = this.leftList[0].type;
      this.loading = true;
      caseDiagnosisDetail(id)
        .then(obj => {
          this.isLoading = true;
          this.details = obj;
          this.loading = false;
          this.diagnosisType = obj["type"];
          let userAvatar;
          if (this.caseStage === "D" || this.caseStage === "B") {
            userAvatar = get(obj, "frontal") || get(obj, "frontFacePicture");
          } else if (this.caseStage === "A" || this.caseStage === "C") {
            userAvatar = get(obj, "frontFacePicture");
          }
          this.defaultAvatar = this.isJSON(userAvatar);
          const toothInfoList = obj.toothInfoList;
          if (Array.isArray(toothInfoList)) {
            const babyObj = {};
            const missingObj = {}; // missingTeethKeyObj
            const noMoveObj = {}; // noMoveTeethKeyObj
            const noDesignObj = {}; // noDesignTeethKeyObj
            const extractionObj = {}; // extractionTeethKeyObj
            toothInfoList.forEach(item => {
              if (item.type === "1") {
                missingObj[item.number] = {
                  type: "1",
                  number: item.number,
                  className: "teeth_missing"
                };
              } else if (item.type === "2") {
                noMoveObj[item.number] = {
                  type: "2",
                  number: item.number,
                  className: "teeth_no_move"
                };
              } else if (item.type === "3") {
                noDesignObj[item.number] = {
                  type: "3",
                  number: item.number,
                  className: "teeth_no_design"
                };
              } else if (item.type === "4") {
                extractionObj[item.number] = {
                  type: "4",
                  number: item.number,
                  className: "teeth_extraction"
                };
              } else if (item.type === "7") {
                babyObj[item.number] = {
                  id: item.id,
                  type: "7",
                  number: item.number
                };
              }
            });
            this.teethKeyObj = {
              missingTeethKeyObj: missingObj,
              noMoveTeethKeyObj: extend({}, missingObj, noMoveObj),
              noDesignTeethKeyObj: extend({}, missingObj, noDesignObj),
              extractionTeethKeyObj: extend(
                {},
                missingObj,
                extractionObj,
                noMoveObj
              )
            };
            this.babyTeethKeyObj = babyObj;
          }
          if (this.productType === "F") {
            const data = this.details;
            let lossTeeth = filter(
              data.toothInfoList,
              item => item.type === "1"
            );
            let dentureTeeth = filter(
              data.toothInfoList,
              item => item.type === "11"
            );
            let veneerTeeth = filter(
              data.toothInfoList,
              item => item.type === "10"
            );
            let crownTeeth = filter(
              data.toothInfoList,
              item => item.type === "12"
            );
            let inlayTeeth = filter(
              data.toothInfoList,
              item => item.type === "13"
            );
            let grindTeeth = filter(
              data.toothInfoList,
              item => item.type === "14"
            );
            let untreatedList = [
              ...lossTeeth,
              ...dentureTeeth,
              ...veneerTeeth,
              ...crownTeeth,
              ...inlayTeeth,
              ...grindTeeth
            ];
            let mainList = adultTeethList;
            if (untreatedList.length) {
              let teethTypeObj = {
                1: "LOSS", // 缺失牙
                10: "VENEER", // 贴面
                11: "DENTURE", // 假牙
                12: "CROWN", // 冠
                13: "INLAY", // 嵌体
                14: "GRIND" // 备牙
              };
              this.commonTeeth = filter(
                map(mainList, item => {
                  let obj;
                  each(untreatedList, it => {
                    if (item.number === it.number) {
                      obj = extend({}, item, obj, {
                        [teethTypeObj[it.type]]: teethTypeObj[it.type]
                      });
                    }
                  });
                  return obj ? obj : item;
                })
              );
            } else {
              this.commonTeeth = [];
            }
          } else {
            this.uploadIntervalList = filter(
              this.details.toothInfoList,
              item => item.type === "5"
            );
            this.crowdTeethList = filter(
              this.details.toothInfoList,
              item => item.type === "9"
            );

            let noMoveList = filter(
              this.details.toothInfoList,
              item => item.type === "2"
            );
            let noDesignList = filter(
              this.details.toothInfoList,
              item => item.type === "3"
            );
            let deficiencyList = map(
              filter(this.details.toothInfoList, item => item.type === "1"),
              it => extend({}, it, { deficiency: "deficiency" })
            );
            let removeList = filter(
              this.details.toothInfoList,
              item => item.type === "4"
            );
            let commonVeneerList = filter(
              this.details.toothInfoList,
              item => item.type == "10"
            );
            if (commonVeneerList.length) {
              this.commonVeneerList = commonVeneerList;
            }
            let mainList = ["A", "E", "I"].includes(this.productType)
              ? filter(
                  this.details.toothInfoList,
                  item => item.type === "7"
                ).concat(adultTeethList)
              : filter(this.details.toothInfoList, item => item.type === "8");
            let untreatedList = [
              ...noMoveList,
              ...noDesignList,
              ...removeList,
              ...deficiencyList
            ];
            if (
              untreatedList.length ||
              ["A", "E", "I"].includes(this.productType)
            ) {
              let teethTypeObj = {
                2: "noMove",
                3: "noDesign",
                4: "remove",
                1: "deficiency"
              };
              this.commonTeeth = map(mainList, item => {
                let obj;
                each(untreatedList, it => {
                  if (item.number === it.number && !item.nextNumber) {
                    obj = extend({}, item, obj, {
                      [teethTypeObj[it.type]]: teethTypeObj[it.type]
                    });
                  }
                });
                return obj ? obj : item;
              });
            } else {
              this.commonTeeth =
                filter(this.details.toothInfoList, item => !item.nextNumber) ||
                [];
            }
          }
          let {
            anteriorOverjetPicture,
            upToothPicture,
            downToothPicture,
            mouthRightPicture,
            mouthFrontPicture,
            mouthLeftPicture,
            xrayCurvePicture,
            xraySidePicture,
            sideFacePicture,
            frontFacePicture,
            smilePicture,
            openAndClosePicture,
            bitePicture
          } = obj;
          this.carouselList = [
            anteriorOverjetPicture
              ? {
                  href: this.$PicPrefix + anteriorOverjetPicture,
                  filed: "anteriorOverjetPicture",
                  name: this.$t("cases.createImage.qyfgx")
                }
              : "",
            sideFacePicture
              ? {
                  href: this.$PicPrefix + sideFacePicture,
                  filed: "sideFacePicture",
                  name: this.$t("cases.createImage.cmx")
                }
              : "",
            frontFacePicture
              ? {
                  href: this.$PicPrefix + frontFacePicture,
                  filed: "frontFacePicture",
                  name: this.$t("cases.createImage.zmx")
                }
              : "",
            smilePicture
              ? {
                  href: this.$PicPrefix + smilePicture,
                  filed: "smilePicture",
                  name: this.$t("cases.createImage.wxx")
                }
              : "",
            upToothPicture
              ? {
                  href: this.$PicPrefix + upToothPicture,
                  filed: "upToothPicture",
                  name: this.$t("cases.createImage.sylx")
                }
              : "",
            downToothPicture
              ? {
                  href: this.$PicPrefix + downToothPicture,
                  filed: "downToothPicture",
                  name: this.$t("cases.createImage.xylx")
                }
              : "",
            mouthRightPicture
              ? {
                  href: this.$PicPrefix + mouthRightPicture,
                  filed: "mouthRightPicture",
                  name: this.$t("cases.createImage.knzcyx")
                }
              : "",
            mouthFrontPicture
              ? {
                  href: this.$PicPrefix + mouthFrontPicture,
                  filed: "mouthFrontPicture",
                  name: this.$t("cases.createImage.knzwx")
                }
              : "",
            mouthLeftPicture
              ? {
                  href: this.$PicPrefix + mouthLeftPicture,
                  filed: "mouthLeftPicture",
                  name: this.$t("cases.createImage.knycyx")
                }
              : "",
            xrayCurvePicture
              ? {
                  href: this.$PicPrefix + xrayCurvePicture,
                  filed: "xrayCurvePicture",
                  name: this.$t("cases.createImage.dcp")
                }
              : "",
            xraySidePicture
              ? {
                  href: this.$PicPrefix + xraySidePicture,
                  filed: "xraySidePicture",
                  name: this.$t("cases.createImage.dwp")
                }
              : "",
            bitePicture
              ? {
                  href: this.$PicPrefix + bitePicture,
                  filed: "bitePicture",
                  name: this.$t("cases.createImage.yhx")
                }
              : "",
            openAndClosePicture
              ? {
                  href: this.$PicPrefix + openAndClosePicture,
                  filed: "openAndClosePicture",
                  name: this.$t("cases.createImage.khx")
                }
              : ""
          ].filter(item => !!item.href);
          this.carouselList = [
            ...this.carouselList,
            ...map(this.fileList, item => {
              return {
                href: this.$PicPrefix + item,
                filed: "otherPicture",
                name: this.$t("cases.createImage.yxzl")
              };
            })
          ];
          this.carouselList = map(this.carouselList, (item, index) => {
            return extend({}, item, { index: index + "" });
          });
        })
        .catch(() => {
          this.loading = false;
        });
    }
  },
  created() {
    // this.getLeftList().then(() => {
    //   this.getDetail();
    // });
    this.getCaseDetail(this.$route.query.caseId);
  },
  components: {
    BackStep,
    PublicCasesUser,
    PublicCasesDiagnosis,
    PublicCasesPic,
    PreviewDiagnosis,
    caseDonePreview,
    PublicTeethModel,
    stageAdjustmentPreview
  }
};
</script>

<style lang="scss" scoped>
.wrap {
  width: 16rem;
  margin: 0 auto;

  .wrap_top {
    width: 16rem;
    height: 50px;
    background-color: #efeff4;
    position: absolute;
    top: -0.5rem;
  }

  .header {
    position: fixed;
    z-index: 10;

    .transverse_line {
      width: 16rem;
      height: 4px;
      background-color: #efeff4;
      position: absolute;
      top: 151px;
      z-index: 999;
    }

    .content_header_info {
      width: 100%;
      background-color: white;
      padding: 15px 0px 20px 30px;
      position: relative;

      .user_pic {
        width: 50px;
        height: 50px;
        border-radius: 25px;
        overflow: hidden;
        position: relative;

        img {
          display: block;
          width: 100%;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          //height: 100%;
        }
      }

      .content_title {
        font-size: 0.18rem;
        color: $main_theme_color_333;
        margin-left: 0.1rem;
      }
    }

    .tabs {
      width: 16rem;
      display: flex;
      background-color: #fff;
      padding-left: 60px;

      div {
        font-size: 0.18rem;
        margin-right: 80px;
        color: $main_theme_color_999;
        padding-bottom: 0.18rem;
        text-align: center;
        transition: 0.1s all;
        cursor: pointer;
        border-bottom: 4px solid #efeff4;
        position: relative;
        top: 0;
        z-index: 1000;
      }

      .tab_active {
        border-bottom: 4px solid $main_theme_color;
        color: $main_theme_color_333;
      }
    }

    .wrap_back {
      position: relative;
      //top: -0.01rem;
    }
  }

  .content {
    width: 16rem;
    color: #555657;
    font-size: 0.16rem;
    background-color: white;
    min-height: 7rem;
    padding-top: 22px;
    padding-bottom: 60px;
    position: relative;
    overflow: hidden;
    top: 1.51rem;

    .content_left {
      overflow: hidden;
      width: 1.42rem;
      height: 55vh;
      position: fixed;

      .case_item {
        text-align: center;
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        height: 0.8rem;
        cursor: pointer;
        //border-right: 0.02rem solid #efeff4;
        font-size: 0.14rem;
      }

      .case_item_active {
        border-right: 0.02rem solid $main_theme_color !important;
        transition: all 0.2s;
        background: #f9f9f9;
        color: $main_theme_color;
      }
    }

    .content_right {
      margin-left: 172px;
    }
  }
}

/deep/ .el-tabs__item.is-active {
  color: $main_theme_color !important;
}

/deep/ .el-tabs__item:hover {
  color: $main_theme_color !important;
}

/deep/ .el-tabs__active-bar {
  background-color: $main_theme_color !important;
}

/deep/ .el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>
