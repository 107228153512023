<template>
  <el-dialog
    destroy-on-close
    ref="dia"
    :title="
      showTabs && (isComponent == 'MailModel' || isComponent == 'Location')
        ? ''
        : diaText
    "
    :visible.sync="show"
    :show-close="false"
    :height="diaHeight"
    :close-on-click-modal="indexType !== 'await'"
    :close-on-press-escape="indexType !== 'await'"
    :width="isSmall ? '7rem' : '6.52rem'"
    style="overflow: hidden"
    @close="handleDiaClose"
  >
    <div :style="{ height: diaHeight + 'rem' }" class="dia_wrap">
      <el-tabs
        v-if="
          showTabs && (isComponent == 'MailModel' || isComponent == 'Location')
        "
        class="ex_tabs"
        :value="activeName"
        @tab-click="handleTabChange"
      >
<!--        <el-tab-pane-->
<!--          label="上门取件"-->
<!--          name="silicagel_print_model"-->
<!--        ></el-tab-pane>-->
        <el-tab-pane label="自行邮寄" name="self_deliver"></el-tab-pane>
      </el-tabs>
      <img
        v-if="indexType !== 'await'"
        @click="backDia"
        class="dia_close"
        src="../../common/imgs/expressage_birdie/dia_close.png"
        alt=""
      />
<!--      <component
        v-show="!showTabs || activeName === 'silicagel_print_model'"
        :bindClinic="bindClinic"
        :caseDetail="caseDetail"
        :ref="isComponent"
        @reload="reloadPage"
        @close="closeDia"
        :is="isComponent"
        :isReissueFlag="isReissueFlag"
        :caseType="caseType"
        @getDiaTitle="getDiaTitle"
        @orderSuccess="orderSuccess"
      >
      </component>-->
      <TheDelivery
        :refType="refType"
        :caseDetail="caseDetail"
        :deliveryData="deliveryData"
        :deliveryDis="deliveryDis"
        @close="closeDia"
        :canCreate="canCreate"
        v-show="
          pathname !== '/stage-adjustment' &&
            showTabs &&
            activeName === 'self_deliver'
        "
        :show="show"
        @orderSuccess="orderSuccess"
        :isReissueFlag="isReissueFlag"
      />
      <StageAdjustmentDelivery
        :refType="refType"
        :show="show"
        @close="closeDia"
        :deliveryDis="deliveryDis"
        :caseDetail="caseDetail"
        :deliveryData="deliveryData"
        v-if="
          pathname === '/stage-adjustment' &&
            showTabs &&
            activeName === 'self_deliver'
        "
        :isReissueFlag="isReissueFlag"
        @orderSuccess="orderSuccess"
        :canCreate="canCreate"
      />
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { extend, get } from "lodash";
import Location from "./location/index";
import AddOrEditLocation from "./add_or_edit_location/index";
import MailModel from "./mail_model/index";
import OrganizationCode from "./organization_code/index";
import AddSend from "./add_send/index";
import ConfirmOrder from "./confirm_order/index";
import PlaceOrder from "./place_order/index";
import MailDetail from "./mail_detail/index";
import PlaceOrderAwait from "./place_order_await/index";
import {
  PublicCaseSendAddressDetail,
  queryClinicAddressDetail,
  addressDetailOss
} from "common/api/cases";
import TheDelivery from "../../pages/cases/cases-create/the-delivery.vue";
import StageAdjustmentDelivery from "components/stage-adjustment-delivery/index.vue";

export default {
  data() {
    return {
      show: false,
      diaHeight: "",
      diaText: "",
      isSmall: false,
      bindClinic: "",
      activeName: "self_deliver",
      deliveryData: {},
      deliveryDis: false,
      pathname: undefined
    };
  },
  props: {
    state: {
      type: String
    },
    caseDetail: {
      type: Object,
      default: () => ({})
    },
    isReissueFlag: {
      default: false
    },
    caseType: {
      default: "0"
    },
    defaultKey: {
      type: String
    },
    refType: {
      type: String
    },
    canCreate: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.pathname = window.location.pathname;
  },
  watch: {
    defaultKey: {
      handler: function(nval) {
        if (!nval) return;
        this.activeName = nval;
        if (this.show) {
          if (this.activeName === "self_deliver") {
            this.queryReceiverData();
          }
        } else {
          this.backDia();
        }
      }
    }
  },
  computed: {
    ...mapGetters({}),
    indexType() {
      return this.$route.query.indexType || "model";
    },
    showTabs() {
      if (this.caseDetail) {
        this.diaHeight = 7.8;
        return +get(this.caseDetail, "selfDeliverFlag") === 1 && true;
      } else {
        return false;
      }
    },
    isComponent() {
      return (
        {
          model: "MailModel",
          location: "AddOrEditLocation",
          index: "Location",
          code: "OrganizationCode",
          addSend: "AddSend",
          confirm: "ConfirmOrder",
          place: "PlaceOrder",
          detail: "MailDetail",
          await: "PlaceOrderAwait"
        }[this.indexType] || "model"
      );
    }
  },

  methods: {
    ...mapActions({}),
    reloadPage() {
      this.$emit("reload");
    },
    handleTabChange(tab) {
      if (tab["_props"].name == this.activeName) return;
      this.activeName = tab["_props"].name;
      if (this.activeName === "self_deliver") {
        this.queryReceiverData();
      } else {
        if (this.$route.query.caseId) {
          this.querySendAddressDetail();
        }
      }
      this.$emit("tabsChange", this.activeName);
    },
    closeDia() {
      this.backDia();
      this.$emit("reload");
    },
    handleDiaClose() {
      let indexType = this.$route.query.indexType;
      if (indexType === "await") return;
      if (
        ["model", undefined, ""].includes(indexType) ||
        (this.caseDetail.id && indexType === "index")
      ) {
        this.changeQuery({ indexType: "", resetId: "", relevanceId: "" });
        return (this.show = false);
      }
      this.show = true;
      this.$router.back();
    },
    backDia() {
      let indexType = this.$route.query.indexType;
      if (indexType === "await") return;
      if (
        ["model", undefined, ""].includes(indexType) ||
        (this.caseDetail.id && indexType === "index")
      ) {
        this.changeQuery({ indexType: "", resetId: "", relevanceId: "" });
        return (this.show = false);
      }
      this.show = true;
      this.$router.back();
    },
    getDiaTitle(text) {
      let winHeight = window.screen.height,
        indexType = this.$route.query.indexType;
      this.diaText = text;
      this.diaHeight = 7.8;
      if (!Object.keys(this.caseDetail).length) {
        this.diaHeight = 7.2;
      }
      this.isSmall = winHeight < 1000;
      if (["addSend"].includes(indexType) && winHeight > 1000) {
        this.diaHeight = 7.68;
      }
    },
    orderSuccess() {
      this.$emit("orderSuccess", 1);
    },
    openDia() {
      let indexType = this.$route.query.indexType;
      if (this.$route.query.caseId) {
        this.querySendAddressDetail();
      }
      if (indexType === "await") {
        this.changeQuery({ indexType: "index" });
        return (this.show = true);
      }
      this.show = true;
      return Promise.resolve();
    },
    querySendAddressDetail() {
      PublicCaseSendAddressDetail({
        caseId: this.$route.query.caseId,
        type: this.refType - 1
      }).then(data => {
        this.bindClinic = get(data, "sendAddressId");
        let sendAddressId = get(data, "sendAddressId");
        if (
          this.caseDetail.id &&
          !data &&
          +this.caseDetail.modelLogisticsNotFinishFlag === 1
        ) {
          return this.changeQuery({ indexType: "model" });
        }
        if (this.caseDetail.id && !sendAddressId) {
          return this.changeQuery({ indexType: "index" });
        }
      });
    },
    queryReceiverData() {
      if (this.isReissueFlag) {
        addressDetailOss()
          .then(data => {
            const {
              receiveUser,
              phone,
              province,
              city,
              area,
              address,
              provinceName,
              areaName,
              cityName,
              contactWay,
              phoneArea
            } = data;
            const resObj = {
              ...this.params,
              receiverName: receiveUser,
              receiverMobile: phone,
              receiverContactWay: contactWay,
              receiverPhoneArea: phoneArea,
              receiverProvince: province,
              receiverCity: city,
              receiverArea: area,
              receiverAddress: address,
              csqObj: {
                receiverProvince: provinceName,
                receiverCity: cityName,
                receiverArea: areaName
              }
            };
            this.deliveryData = resObj;
          })
          .catch(err => {
            if (err.errorCode === "AIS00070") {
              this.deliveryDis = true;
              setTimeout(() => {
                this.backDia();
              }, 3000);
            }
          });
      } else {
        queryClinicAddressDetail(this.caseDetail["clinicId"])
          .then(data => {
            const {
              receiveUser,
              phone,
              address,
              areaName,
              cityName,
              provinceName,
              province,
              city,
              area,
              contactWay,
              phoneArea
            } = data;
            const resObj = {
              receiverName: receiveUser,
              receiverMobile: phone,
              receiverContactWay: contactWay,
              receiverPhoneArea: phoneArea,
              receiverProvince: province,
              receiverCity: city,
              receiverArea: area,
              receiverAddress: address,
              csqObj: {
                receiverProvince: provinceName,
                receiverCity: cityName,
                receiverArea: areaName
              }
            };
            this.deliveryData = resObj;
          })
          .catch(err => {
            if (err.errorCode === "AIS00070") {
              this.deliveryDis = true;
              setTimeout(() => {
                this.backDia();
              }, 3000);
            }
          });
      }
    },
    resetClinic(id) {
      this.$refs.MailModel && this.$refs.MailModel.resetClinic(id);
    },
    changeQuery(arg) {
      let route = this.$route;
      this.$router.replace({
        path: route.path,
        query: extend({}, route.query, arg)
      });
    }
  },
  components: {
    Location,
    AddOrEditLocation,
    MailModel,
    OrganizationCode,
    AddSend,
    ConfirmOrder,
    PlaceOrder,
    MailDetail,
    PlaceOrderAwait,
    TheDelivery,
    StageAdjustmentDelivery
  }
};
</script>

<style lang="scss" scoped>
/dee/ .el-dialog__wrapper {
  overflow: hidden !important;
}

/deep/ .el-table th > .cell {
  color: #333333;
}

/deep/ .el-table td div {
  color: #333333;
}

/deep/ .el-dialog__title {
  color: #333333;
  font-size: 18px;
}

/deep/ .el-dialog {
  margin-top: 0.2rem !important;
  position: relative !important;
}

/deep/ .el-dialog__header {
  padding: 0;
  color: #333333;
  font-size: 0.18rem;
  padding: 0.2rem 0.3rem 0 0.3rem !important;
}

/deep/ .el-dialog__body {
  padding: 0 !important;
}

/deep/ .el-tabs__item {
  width: 132px;
  line-height: 58px;
  text-align: center;
  color: #333333;
  font-size: 18px;
}

/deep/ .el-tabs__item.is-active {
  color: $main_theme_color;
}

/deep/ .el-tabs__active-bar {
  background-color: $main_theme_color;
}

/deep/ .el-tabs__nav {
  height: 58px;
}

/deep/ .el-tabs__item:hover {
  color: $main_theme_color;
}

.dia_wrap {
  width: 100%;
}

.ex_tabs {
  height: 58px;
  transform: translateY(-0.2rem);
}

.dia_close {
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
  z-index: 999;
}
</style>
