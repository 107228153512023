import { setCookie, getCookie, delCookie } from "./cookie";
import html2canvas from "html2canvas";
import "./BigDecimal";
import CITY from "./city";
import Language from "../lang/language";
import axios from "axios";
import JSZip from "jszip";
import FileSaver from "file-saver";
import { PIC_PREFIX } from "common/js/config";
import { Message, Loading } from "element-ui";
import { extend } from "lodash";

// 日期格式化
export function formatDate(date, fmt = "yyyy-MM-dd hh:mm:ss") {
  if (!date) {
    return "-";
  }
  date = new Date(date);
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  let o = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "h+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds()
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + "";
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : padLeftZero(str)
      );
    }
  }
  return fmt;
}

function padLeftZero(str) {
  return ("00" + str).substr(str.length);
}

// 获取userId
export function getUserId() {
  return localStorage.getItem("userId") || "";
}

// 保存用户登录信息
export function setUser(data) {
  localStorage.setItem("token", data.token);
  localStorage.setItem("userId", data.userId);
}

// 获取userId
export function getUserToken() {
  return localStorage.getItem("token") || "";
}

// 获取腾讯云登录的sig
export function getSign() {
  return getCookie("__sign__") || "";
}

// 获取腾讯云登录的accountType
export function getAccountType() {
  return getCookie("__accountType__") || "";
}

// 获取腾讯云登录的txAppCode
export function getTxAppCode() {
  return getCookie("__txAppCode__") || "";
}

// 获取腾讯云登录的参数
export function getTencentParams() {
  return {
    sig: getSign(),
    accountType: getAccountType(),
    txAppCode: getTxAppCode()
  };
}

// 删除用户登录信息
export function clearUser() {
  delCookie("userId");
  delCookie("token");
  sessionStorage.clear();
  let language = localStorage.getItem("user_lang") || "zh_CN";
  const userId=localStorage.getItem('userId')
  const isView3dInitNotice =localStorage.getItem(`{"type":"view3d_init_notice","productId":"20241206"}`)
  const home_notice_key = localStorage.getItem("home_notice_key");
  const appVersion = localStorage.getItem("appVersion");
  localStorage.clear();
  localStorage.setItem("user_lang", language);
  localStorage.setItem(`{"type":"view3d_init_notice","productId":"20241206"}`, isView3dInitNotice);
  localStorage.setItem(`home_notice_key`, home_notice_key);
  localStorage.setItem(`appVersion`, appVersion);
}

// 跳转登陆
export function goLogin() {
  location.href =
    location.href.split("://")[0] + "://" + location.host + "/login";
}

// 是否登录
export function isLogin() {
  return !!getUserToken();
}

//获取cookie对象
export function getLocaleCookie() {
  let cookies = document.cookie.split(/;\s/g);
  let cookieObj = {};
  cookies.forEach(function(item) {
    var key = item.split("=")[0];
    cookieObj[key] = item.split("=")[1];
  });
  return cookieObj;
}

// 设置cookie
export function setLocaleCookie(key, val, time) {
  if (typeof key !== "string" || typeof val !== "string") {
    return false;
  }
  time = time || 7 * 24 * 3600;
  var exp = new Date();
  exp.setTime(exp.getTime() + time * 1000);
  document.cookie = key + "=" + val + ";expires=" + exp.toGMTString();
}

// 清空某个
export function clearCookieByKey(key) {
  setLocaleCookie(key, "", -1);
}

// 获取当前语言
export function getLangType() {
  let locale = window.localStorage.getItem("user_lang") || "zh";
  return locale;
}

// 获取当前语言
export function getTranslateText(text, lang) {
  return Language.getText(text, lang);
}

// 是否为空
export function isUnDefined(value) {
  if (value === undefined || value === null || value === "") {
    return true;
  }
  return false;
}

// 格式化图片地址
export function formatImg(imgs, suffix = "?imageMogr2/auto-orient") {
  if (!imgs) {
    return "";
  }
  let img = imgs.split(/\|\|/)[0];

  if (!/^http|^data:image/i.test(img)) {
    let index = img.indexOf("?imageMogr2");
    if (index !== -1) {
      suffix = img.substr(index);
      img = img.substr(0, index);
    }
    img = PIC_PREFIX + encodeURIComponent(img) + suffix;
  }
  return img;
}

// 格式化头像
/*export function formatAvatar(img, suffix = '?imageMogr2/auto-orient') {
  return isUnDefined(img) ? avatarDefault : formatImg(img, suffix);
}*/

// 格式化头像 - backgroundImage
/*export function formatAvatarSyl(imgs) {
  let img = isUnDefined(imgs) ? avatarDefault :  formatImg(imgs);
  return {
    backgroundImage: `url(${img})`
  };
}*/

// 获得分享图片
/*export function getShareImg(imgs) {
  if (!imgs) {
    let sharImg = require('../image/app.png');
    if (/data:image/.test(sharImg) || /http(?:s)?/.test(sharImg)) {
      return sharImg;
    }
    return location.origin + sharImg;
  }
  return formatImg(imgs);
}*/

/**
 * 金额格式转化 根据币种格式化金额
 * @param money
 * @param format
 * @param coin 币种
 * @param isRe 是否去零
 */
export function formatAmount(money, format, coin, isRe = false) {
  if (money === 0) {
    return 0;
  }
  let unit = coin && getCoinData()[coin] ? getCoinUnit(coin) : "1000";
  let flag = false; // 是否是负数
  if (isNaN(money)) {
    return "-";
  } else {
    money = Number(money);
  }
  if (money < 0) {
    money = -1 * money;
    flag = true;
  }
  if (coin && isUnDefined(format)) {
    format = 4;
  } else if (isUnDefined(format) || typeof format === "object") {
    // 默认格式为2位小数
    format = 2;
  }

  // 金额格式化 金额除以unit并保留format位小数
  money = new BigDecimal(money.toString());
  money = money
    .divide(new BigDecimal(unit), format, MathContext.ROUND_DOWN)
    .toString();

  // 是否去零
  if (isRe) {
    var re = /\d{1,3}(?=(\d{3})+$)/g;
    money = money.replace(
      /^(\d+)((\.\d+)?)$/,
      (s, s1, s2) => s1.replace(re, "$&,") + s2
    );
  }
  if (flag) {
    money = "-" + money;
  }
  return money;
}

/**
 * 把格式化金额去掉逗号
 * @param money
 */
export function moneyReplaceComma(money) {
  return ("" + money).replace(/,/g, "");
}

/**
 * 金额放大 根据币种的单位把金额放大
 * @param money
 * @param format
 * @param coin 币种
 */
export function formatMoneyMultiply(money, rate, coin) {
  let unit = coin && getCoinData()[coin] ? getCoinUnit(coin) : "1000";
  if (isUnDefined(money) || money === "") {
    return "-";
  } else {
    money = Number(money).toString();
  }
  rate = rate || new BigDecimal(unit);
  money = new BigDecimal(money);
  money = money.multiply(rate).toString();
  return money;
}

/**
 * 金额减法 s1 - s2
 * @param s1
 * @param s2
 * @param coin 币种
 */
export function formatMoneySubtract(s1, s2, format, coin) {
  if (isUnDefined(s1) || isUnDefined(s2) || s1 === "" || s2 === "") {
    return "-";
  } else {
    s1 = Number(s1);
    s2 = Number(s2);
  }
  let num1 = new BigDecimal(s1.toString());
  let num2 = new BigDecimal(s2.toString());
  return formatAmount(num1.subtract(num2).toString(), format, coin);
}

/**
 * 获取币种Data
 * return {
 *  'BTC': {
 *      'coin': 'BTC',
 *      'unit': '1e8',
 *      'name': '比特币',
 *      'type': '0',
 *      'status': '0'
 *  }
 *}
 */
export function getCoinData() {
  return JSON.parse(sessionStorage.getItem("coinData"));
}

// 获取币种unit
export function getCoinUnit(coin) {
  if (!coin) {
    console.log("coin不能为空");
    return;
  }
  const unit = getCoinData()[coin].unit;
  return unit;
}

//获取链接入参
export function getUrlParam(name, locat) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var locat = locat ? "?" + locat.split("?")[1] : "";
  var r = (locat ? locat : window.location.search).substr(1).match(reg);
  if (r != null) return decodeURIComponent(r[2]);
  return "";
}

//图片格式化
export function getPic(pic, suffix) {
  if (!pic) {
    return "";
  }
  pic = pic.split(/\|\|/)[0];
  if (!/^http|^data:image/i.test(pic)) {
    suffix = suffix || "?imageMogr2/auto-orient/interlace/1";
    pic = PIC_PREFIX + pic + suffix;
  }
  return {
    backgroundImage: `url(${pic})`
  };
}

//图片格式化-头像
var PHOTO_SUFFIX = "?imageMogr2/auto-orient/thumbnail/!150x150r";

export function getAvatar(pic, suffix) {
  var defaultAvatar = "";
  var suffix = suffix || PHOTO_SUFFIX;
  if (!pic) {
    pic = defaultAvatar;
  }
  return getPic(pic, suffix);
}

//计算百分比
export function getPercentum(n1, n2) {
  if (n1 == "0" && n2 == "0") {
    return "0";
  }
  var n = (n1 / n2) * 100;
  return parseInt(n) + "%";
}

// 判断是否 ios
export const ISIOS = /(iphone|ipod|ipad)/i.test(navigator.userAgent);

// 微信设置页面标题
export function setTitle(title) {
  if (document.title !== title) {
    document.title = title;
    if (ISIOS) {
      let iframe = document.createElement("iframe");
      iframe.src = "//m.baidu.com/favicon.ico";
      iframe.style.display = "none";
      iframe.onload = () => {
        setTimeout(() => {
          iframe.remove();
        }, 9);
      };
      document.body.appendChild(iframe);
    }
  }
}

// 延时函数的执行
export function debounce(func, delay) {
  let timer;
  return function(...args) {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

// 清除内容里的标签
export function clearTag(content) {
  return content.replace(/<[^>]+>|<\/[^>]+>|<[^>]+\/>|&nbsp;/gi, "");
}

// 是否是function
export function isFunction(fn) {
  var getType = {};
  return fn && getType.toString.call(fn) === "[object Function]";
}

// 获取图片base64
export function getImgData(fileType, img, dir, next) {
  let image = new Image();
  image.onload = function() {
    let degree = 0;
    let drawWidth;
    let drawHeight;
    let width;
    let height;
    drawWidth = this.naturalWidth;
    drawHeight = this.naturalHeight;
    let canvas = document.createElement("canvas");
    canvas.width = width = drawWidth;
    canvas.height = height = drawHeight;
    let context = canvas.getContext("2d");
    // 判断图片方向，重置canvas大小，确定旋转角度，iphone默认的是home键在右方的横屏拍摄方式
    switch (dir) {
      // iphone横屏拍摄，此时home键在左侧
      case 3:
        degree = 180;
        drawWidth = -width;
        drawHeight = -height;
        break;
      // iphone竖屏拍摄，此时home键在下方(正常拿手机的方向)
      case 6:
        canvas.width = height;
        canvas.height = width;
        degree = 90;
        drawWidth = width;
        drawHeight = -height;
        break;
      // iphone竖屏拍摄，此时home键在上方
      case 8:
        canvas.width = height;
        canvas.height = width;
        degree = 270;
        drawWidth = -width;
        drawHeight = height;
        break;
    }
    // 使用canvas旋转校正
    context.rotate((degree * Math.PI) / 180);
    context.drawImage(this, 0, 0, drawWidth, drawHeight);
    // 返回校正图片
    next(canvas.toDataURL(fileType, 0.8));
  };
  image.src = img;
}

// 设置微信登录时填写的手机号和验证码
export function setWxMobAndCapt(mobile, captcha) {
  sessionStorage.setItem("__mob__", mobile);
  sessionStorage.setItem("__capt__", captcha);
}

// 获取微信登录时填写的手机号和验证码
export function getWxMobAndCapt() {
  let mobile = sessionStorage.getItem("__mob__");
  let captcha = sessionStorage.getItem("__capt__");
  sessionStorage.removeItem("__mob__");
  sessionStorage.removeItem("__capt__");
  if (mobile && captcha) {
    return {
      mobile,
      captcha
    };
  }
  return null;
}

// 校验短信验证码
export function captValid(capt) {
  let result = {
    err: 0,
    msg: ""
  };
  if (isUnDefined(capt)) {
    result.err = 1;
    result.msg = "不能为空";
  } else if (!/^\d{4}$/.test(capt)) {
    result.err = 1;
    result.msg = "格式错误";
  }
  return result;
}

// 校验手机号
export function mobileValid(mobile) {
  let result = {
    err: 0,
    msg: ""
  };
  if (isUnDefined(mobile)) {
    result.err = 1;
    result.msg = "不能为空";
  } else if (!/^1[3|4|5|6|7|8|9]\d{9}$/.test(mobile)) {
    result.err = 1;
    result.msg = "格式错误";
  }
  return result;
}

// 检验邮箱格式
export function CheckMail(mail) {
  var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  if (filter.test(mail)) {
    return true;
  } else {
    alert("您的电子邮件格式不正确");
    return false;
  }
}

// 支付密码校验
export function tradeValid(trade) {
  let result = {
    err: 0,
    msg: ""
  };
  if (isUnDefined(trade)) {
    result.err = 1;
    result.msg = "不能为空";
  } else if (trade.length < 6) {
    result.err = 1;
    result.msg = "长度不能小于6位";
  }
  return result;
}

// 校验第二次密码是否和第一次相同
export function rePwdValid(rePwd, pwd) {
  let result = {
    err: 0,
    msg: ""
  };
  if (isUnDefined(rePwd)) {
    result.err = 1;
    result.msg = "不能为空";
  } else if (pwd !== rePwd) {
    result.err = 1;
    result.msg = "两次密码不同";
  }
  return result;
}

// 昵称校验
export function nicknameValid(nickname) {
  let result = {
    err: 0,
    msg: ""
  };
  if (isUnDefined(nickname)) {
    result.err = 1;
    result.msg = "昵称不能为空";
    // } else if (nickname.length > 20) {
    //   result.err = 1;
    //   result.msg = '昵称不能超过20位';
  }
  return result;
}

// 真实姓名校验
export function realNameValid(realName) {
  let result = {
    err: 0,
    msg: ""
  };
  if (isUnDefined(realName)) {
    result.err = 1;
    result.msg = "不能为空";
  } else if (realName.length > 16) {
    result.err = 1;
    result.msg = "不能超过16位";
  }
  return result;
}

// 银行名称校验
export function bankNameValid(bankName) {
  return realNameValid(bankName);
}

// 支行校验
export function subbranchValid(subbranch) {
  let result = {
    err: 0,
    msg: ""
  };
  if (isUnDefined(subbranch)) {
    result.err = 1;
    result.msg = "不能为空";
  } else if (subbranch.length > 255) {
    result.err = 1;
    result.msg = "不能超过255位";
  }
  return result;
}

// 银行卡号校验
export function bankcardNumberValid(bankcardNumber) {
  let result = {
    err: 0,
    msg: ""
  };
  if (isUnDefined(bankcardNumber)) {
    result.err = 1;
    result.msg = "不能为空";
  } else if (!/^(\d{16}|\d{19})$/.test(bankcardNumber)) {
    result.err = 1;
    result.msg = "格式错误";
  }
  return result;
}

// 金额校验
export function amountValid(amount) {
  let result = {
    err: 0,
    msg: ""
  };
  if (isUnDefined(amount)) {
    result.err = 1;
    result.msg = "不能为空";
  } else if (!/^\d+(?:\.\d{1,2})?$/.test(amount)) {
    result.err = 1;
    result.msg = "最多两位小数";
  }
  return result;
}

// 非空校验
export function emptyValid(value) {
  let result = {
    err: 0,
    msg: ""
  };
  if (isUnDefined(value)) {
    result.err = 1;
    result.msg = "不能为空";
  }
  return result;
}

// 地址校验
export function addressValid(value) {
  let result = {
    err: 0,
    msg: ""
  };
  if (isUnDefined(value)) {
    result.err = 1;
    result.msg = "不能为空";
  } else if (value.length > 50) {
    result.err = 1;
    result.msg = "长度不能超过50位";
  }
  return result;
}

/**
 * 获取用户登录系统的时间间隔
 * @param {yyyy-MM-dd-hh-mm} beforeTime
 */
export function calcSpace(beforeTime) {
  let now = formatDate(new Date().getTime(), "yyyy-MM-dd-hh-mm").split("-");
  let before = beforeTime.split("-");
  let nowYear = +now[0];
  let beforeYear = +before[0];
  let nowMonth = +now[1];
  let beforeMonth = +before[1];
  if (nowYear > beforeYear) {
    let diffY = nowYear - beforeYear;
    let diffM = diffY * 12 + nowMonth - beforeMonth;
    return diffM < 12 ? diffM + "月前来过" : diffY + "年前来过";
  }
  if (nowMonth > beforeMonth) {
    return nowMonth - beforeMonth + "月前来过";
  }
  let nowDay = now[2];
  let beforeDay = before[2];
  if (nowDay > beforeDay) {
    return nowDay - beforeDay + "天前来过";
  }
  let nowHour = now[3];
  let beforeHour = before[3];
  if (nowHour > beforeHour) {
    return nowHour - beforeHour + "小时前来过";
  }
  let nowMinute = now[4];
  let beforeMinute = before[4];
  let diffMinute = nowMinute - beforeMinute;
  return diffMinute <= 5 ? "刚刚来过" : diffMinute + "分钟前来过";
}

// 聊天日期格式化
export function formatChatDate(timeStamp, param) {
  let now = new Date();
  let nowStamp = now.getTime();
  let before = new Date(timeStamp);
  let week = [
    "星期日",
    "星期一",
    "星期二",
    "星期三",
    "星期四",
    "星期五",
    "星期六"
  ];
  let oneDay = 3600000 * 24;
  let sevenDay = 7 * oneDay;
  let twoDay = 2 * oneDay;
  if (nowStamp - timeStamp > sevenDay) {
    return param
      ? formatDate(timeStamp, "yyyy年MM月dd日 hh:mm")
      : formatDate(timeStamp, "yyyy/MM/dd");
  }
  if (nowStamp - timeStamp > twoDay) {
    return param
      ? week[before.getDay()] + " " + formatDate(timeStamp, "hh:mm")
      : week[before.getDay()];
  }
  if (now.getDate() === before.getDate()) {
    return formatDate(timeStamp, "hh:mm");
  }
  let _before = new Date(timeStamp);
  _before.setDate(_before.getDate() + 1);
  if (_before.getDate() === now.getDate()) {
    return param ? "昨天 " + formatDate(timeStamp, "hh:mm") : "昨天";
  }
  return param
    ? week[before.getDay()] + " " + formatDate(timeStamp, "hh:mm")
    : week[before.getDay()];
}

//计算日期相隔时间
export function calculateDays(start, end) {
  if (!start || !end) return 0;
  start = new Date(start);
  end = new Date(end);
  return (end - start) / (60 * 1000);
}

// 深拷贝
export function deepCopy(initalObj, finalObj) {
  var obj = finalObj || {};
  for (var i in initalObj) {
    var prop = initalObj[i];
    // 避免相互引用对象导致死循环，如initalObj.a = initalObj的情况
    if (prop === obj) {
      continue;
    }
    if (typeof prop === "object") {
      obj[i] = prop.constructor === Array ? [] : Object.create(prop);
    } else {
      obj[i] = prop;
    }
  }
  return obj;
}

// k线请求时间
export function periodLengthSeconds(resolution, requiredPeriodsCount) {
  let daysCount = 0;
  if (resolution === "D" || resolution === "1D") {
    daysCount = requiredPeriodsCount;
  } else if (resolution === "M" || resolution === "1M") {
    daysCount = 31 * requiredPeriodsCount;
  } else if (resolution === "W" || resolution === "1W") {
    daysCount = 7 * requiredPeriodsCount;
  } else {
    daysCount = (requiredPeriodsCount * parseInt(resolution)) / 2;
  }
  return daysCount * 24 * 60 * 60;
}

// 操作成功
export function notifyMsg(that, type, message, duration = 2000) {
  return new Promise(resolve => {
    that.$notify({
      type,
      message,
      duration
    });
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

// 处理省市区
export function provincesData() {
  let provinceList = [];
  const list = CITY.map(item => {
    provinceList.push(item.name);
    return {
      [item.name]: item.sub.map(sItem => {
        if (Array.isArray(sItem.sub)) {
          return {
            [sItem.name]: sItem.sub
          };
        } else sItem;
      })
    };
  });
  return {
    provinceList,
    list
  };
}

// 下载图片
export function downImage(src, name) {
  const image = new Image();
  image.setAttribute("crossOrigin", "anonymous");
  image.onload = function() {
    const canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    const context = canvas.getContext("2d");
    context.drawImage(image, 0, 0, image.width, image.height);
    const url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
    const a = document.createElement("a"); // 生成一个a元素
    const event = new MouseEvent("click"); // 创建一个单击事件
    a.download = name; // 设置图片名称
    a.href = url; // 将生成的URL设置为a.href属性
    a.dispatchEvent(event); // 触发a的单击事件
  };
  image.src = src;
}

// 文字格式化换行
export function splitText(tt) {
  if (!tt || typeof tt !== "string") {
    return [];
  }
  return tt.split(/[\r\n]/);
}

// base64 转 Blob
export function dataURLtoBlob(dataUrl) {
  const arr = dataUrl.split(",");
  // 注意base64的最后面中括号和引号是不转译的
  const _arr = arr[1].substring(0, arr[1].length - 2);
  let mime = arr[0].match(/:(.*?);/)[1],
    bStr = atob(_arr),
    n = bStr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bStr.charCodeAt(n);
  }
  return new Blob([u8arr], {
    type: mime
  });
}

// 生成canvas图片
export function toHtml2canvas(refHtm, config = {}) {
  if (refHtm) {
    window.pageYoffset = 0;
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    return new Promise((resolve, reject) => {
      html2canvas(
        refHtm,
        extend(
          {},
          {
            useCORS: true, // 允许图片跨域
            scrollY: 0,
            scrollX: 0
          },
          config
        )
      )
        .then(canvas => {
          const picUrl = canvas.toDataURL("image/png");
          resolve(picUrl.slice(picUrl.indexOf(",") + 1));
        })
        .catch(err => {
          reject();
        });
    });
  }
}

export function putb64(basePic, token) {
  return new Promise(resolve => {
    const url = "https://up.qiniup.com/putb64/-1";
    const xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.onreadystatechange = function() {
      if (+xhr.readyState === 4) {
        resolve(JSON.parse(xhr.response));
      }
    };
    xhr.setRequestHeader("Content-Type", "application/octet-stream");
    xhr.setRequestHeader("Authorization", "UpToken " + token);
    xhr.send(basePic);
  });
}

// 清除页面病例缓存
export function removeCreate() {
  sessionStorage.removeItem("casesTabKey");
  sessionStorage.removeItem("createUserForm");
  sessionStorage.removeItem("diagnosisParams");
  sessionStorage.removeItem("curePlanId");
  sessionStorage.removeItem("casesId");
  sessionStorage.removeItem("operationKey");
  sessionStorage.removeItem("casesDetail");
  localStorage.removeItem("casesDetail");
  localStorage.removeItem("curePlanId");
  localStorage.removeItem("casesId");
}

export const downloadFileList = async (
  attachmentInfoList = [],
  isZip,
  downloadName = "压缩文件.zip",
  $PicPrefix
) => {
  const mission = attachmentInfoList.map(({ fileName, realFileName }) => {
    return axios.get(`${$PicPrefix}${encodeURIComponent(fileName)}`, {
      responseType: "blob",
      // 非axios配置 携带后续需要的数据
      otherInfo: {
        fileName,
        realFileName
      }
    });
  });
  const res = await Promise.all(mission);
  if (isZip) {
    const zip = new JSZip();
    res.forEach(({ config: { otherInfo }, data }) => {
      const { fileName } = otherInfo;
      zip.file(fileName, data, { binary: true });
    });
    zip.generateAsync({ type: "blob" }).then(blob => {
      FileSaver.saveAs(blob, downloadName);
    });
    Message.success("下载完成");
  } else {
    res.forEach(({ config: { otherInfo }, data }) => {
      const { fileName } = otherInfo;
      FileSaver.saveAs(data, fileName);
    });
    Message.success("下载完成");
  }
};

export const changeLocalStorageNoticeData = (key, id) => {
  const jsonToKey = JSON.stringify(key);
  let value = JSON.parse(localStorage.getItem(jsonToKey)) || [];
  if ((typeof value)!=='object'){
    value = [value]
  }
  const list = [...new Set(value)];
  localStorage.setItem(jsonToKey, JSON.stringify([...list, id]));
};

export const shouldShowNotice = (key, id) => {
  const jsonToKey = JSON.stringify(key);
  const value = JSON.parse(localStorage.getItem(jsonToKey));
  if (value !== null) {
    if ((typeof value)==='object'){
      return !value.includes(id)
    }else {
      return true
    }
  } else {

    changeLocalStorageNoticeData(key, id);
    return true;
  }
};
