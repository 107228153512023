<template>
  <div class="proposal_history">
    <ul class="his_ul" v-if="operatorLog.length">
      <li
        class="his_single"
        v-for="(item, index) in operatorLog"
        :key="item.id"
      >
        <div class="his_left">
          {{item.curePlanFirstName}}
        </div>
        <div class="his_right">
          <h5 class="his_r_h5"><span class="his_h_icon">{{operatorLog.length - index}}</span> {{item.curePlanSecondName}}</h5>
          <div class="his_r_box">
            <p class="his_r_p">{{item.createDatetime}}</p>
            <div class="his_con">
              <p class="his_c_txt" v-for="(cItem, cIndex) in item.content" :key="`c_${cIndex}`">
                {{cItem}}
              </p>
            </div>
            <div class="his_r__yj">
              <div class="yj_left">
                <span class="yj_sp">{{$t('casesDetail.casesChildren.operationChildren.xgyj')}}：</span>
              </div>
              <div class="yj_right">
                <p class="yj_single" v-for="(cItem, cIndex) in item.operateRemark" :key="`c_${cIndex}`">{{cItem}}</p>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="no_his" v-else>
      <div class="no_his_pic"></div>
      <p class="no_his_txt">{{$t('casesDetail.casesChildren.operationChildren.zswjy')}}～</p>
    </div>
  </div>
</template>

<script>
  import {cureSuggestList} from 'common/api/cases';
  import {splitText} from 'common/js/util';
  export default {
    data() {
      return {
        operatorLog: []
      }
    },
    created() {
      const curePlanId = localStorage.getItem('curePlanId') || sessionStorage.getItem('curePlanId') || '';
      sessionStorage.setItem('curePlanId', curePlanId);
      localStorage.removeItem('curePlanId');
      if(curePlanId) {
        cureSuggestList({curePlanId, type: 'text_plan'}).then(logList => {
          this.operatorLog = logList.map(item => ({
            id: item.id,
            content: splitText(item.content),
            createDatetime: item.createDatetime,
            operateRemark: splitText(item.suggest),
            curePlanFirstName: item.planFirstName || '-',
            curePlanSecondName: item.planSecondName || '-'
          }));
        });
      }
    }
  }
</script>

<style scoped lang="scss">
  .proposal_history{
    color: $main_theme_color_333;
    font-size: 0.16rem;
    background-color: #fff;
    padding: 0.4rem 0;
    .his_ul{
      .his_single{
        display: flex;
        .his_left{
          width: 2.3rem;
          padding-right: 0.2rem;
          text-align: right;
          padding-top: 0.03rem;
          font-size: 0.16rem;
        }
        .his_right{
          flex: 1;
          padding-right: 1.5rem;
          .his_r_h5{
            display: flex;
            align-items: center;
            font-family:Microsoft YaHei;
            font-weight:bold;
            font-size: 0.18rem;
            .his_h_icon{
              display: inline-block;
              width: 0.2rem;
              height: 0.2rem;
              line-height: 0.2rem;
              text-align: center;
              border-radius: 100%;
              background-color: #FCC80E;
              color: #fff;
              font-size: 0.12rem;
              transform: translateX(-50%);
            }
          }
          .his_r_box{
            padding: 0.08rem 0 0.3rem 0.2rem;
            border-left: 0.01rem solid #DADADA;
            .his_r_p{
              color: #999999;
              font-size: 0.14rem;
              margin-bottom: 0.21rem;
            }
            .his_con{
              margin-bottom: 0.19rem;
              position: relative;
              .his_c_txt{
                /*overflow: hidden;*/
                /*text-overflow: ellipsis;*/
                /*display: -webkit-box;*/
                /*-webkit-line-clamp: 3;*/
                /*-webkit-box-orient: vertical;*/
                line-height: 0.3rem;
                color: #999999;
              }
              .his_c_sp{
                position: absolute;
                right: 0;
                bottom: 0;
                transform: translate(110%, -50%);
                color: $main_theme_color;
                cursor: pointer;
              }
            }
            .his_r__yj{
              background-color: #eeeeee;
              font-size: 0.14rem;
              padding: 0.22rem 0 0.23rem 0.29rem;
              display: flex;
            }
            .yj_left{
              margin-right: 0.12rem;
              padding-top: 0.08rem;
              font-size: 0.18rem;
            }
            .yj_right{
              flex: 1;
              .yj_single{
                line-height: 0.3rem;
                color: #999999;
                &:last-child{
                  margin-bottom: 0;
                }
              }
            }
          }
        }
        &:last-child .his_r_box{
          border: none;
        }
      }
    }
    .no_his{
      padding: 0.87rem 0 1.04rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      color: #666666;
      .no_his_pic{
        width: 2.85rem;
        height: 1.8rem;
        margin-bottom: 0.27rem;
        background-size: 100% 100%;
        background-image: url("/static/images/createCases/web_doc_no_proposal.png");
      }
    }
  }
</style>
