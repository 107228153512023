<template>
  <div class="ceshi">
    <div style="display: flex">
      <div ref="listDom" class="item" :class="{ spreadSty: isSpread }">
        <div v-if="showBtn" class="btn" @click="isSpread = !isSpread">
          {{ isSpread ? "收起>" : "展开>" }}
        </div>
        <div class="desc" v-html="test"></div>
      </div>
    </div>
  </div>
</template>

<script>
import TextOverflow from "./toggle-text.vue";
export default {
  data() {
    return {
      test:'<p>以下是一段关于 CUE3 的描述，你可以根据它实际所指的对象进行调整，这里假定 CUE3 是一款电子产品以下是一段关于 CUE3 的描述，你可以根据它实际所指的对象进行调整，这里假定 CUE3 是一款电子产品</p>',
      showBtn: true, // 是否显示【展开/收起】按钮，按所需条件显示
      isSpread: false, // 是否展开文字动态判断
      cs_text:
        "以下是一段关于 CUE3 的描述，你可以根据它实际所指的对象进行调整，这里假定 CUE3 是一款电子产品以下是一段关于 CUE3 的描述，你可以根据它实际所指的对象进行调整，这里假定 CUE3 是一款电子产品"
    };
  },
  components: {
    TextOverflow
  }
};
</script>

<style lang="scss" scoped>
.ceshi {
  width: 300px;
  padding: 5px;

  .item {
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 20px;
    .desc {
      word-break: break-all; /*英文换行*/
    }
    /*重点1 使用伪元素进行浮动*/
    &::before {
      content: "";
      float: right;
      /*动态高度，使用负margin, 性能比calc 好一点*/
      height: 100%;
      margin-top: -20px;
      /*height: calc(100% - 20px);*/
    }

    .btn {
      position: relative;
      /*重点2 按钮设置浮动，并使用clear: both 将按钮移到文本右下角*/
      float: right;
      clear: both;
      font-size: 14px;
      height: 20px;
      line-height: 20px;
      padding: 0 4px;
      cursor: pointer;
      color: #FCC80E;
      margin-left: 10px;
    }

    /*重点3  展开时的样式*/
    &.spreadSty {
      -webkit-line-clamp: 6; /*设置一个足够大的行数就可以了*/
    }
  }
}
</style>
