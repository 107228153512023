<template>
  <div v-if="visible" class="preview_single">
    <div class="preview_s_left">
      <h5 class="s_l_h5">
        <span class="s_l_icon"></span>
        {{ $t("casesDetail.publicCases.casesPic.yxzl") }}
      </h5>
      <div
          v-if="isArray(surfacePicList) && surfacePicList.length"
          class="pre_list"
      >
        <p class="s_l_txt">{{ $t("casesDetail.publicCases.casesPic.mxkq") }}</p>
        <ul class="pic_list_space">
          <div v-for="(item, index) in surfacePicList" :key="index">
            <li
                v-if="picObj[item.key]"
                @click="
                showCarousel({
                  name: item.name,
                  index: getPicIndex(item.key),
                })
              "
            >
              <div class="img_box">
                <img
                    :src="`${$PicPrefix}${picObj[item.key]['nanoId']}`"
                    :style="{
                    transform: `rotate(${
                      picObj[item.key]['degree']
                    }deg) scaleY(${
                      picObj[item.key]['vertical'] ? -1 : 1
                    }) scaleX(${picObj[item.key]['horizontal'] ? -1 : 1})`,
                  }"
                    alt=""
                    class="li_pic"
                />
              </div>
              <p v-show="picObj[item.key]" class="pic_look">
                <img alt="" src="/static/images/createCases/check_icon.png"/>
                <span>{{ $t("cases.createImage.ck") }}</span>
              </p>
              <p class="label_text mt10 fz16 main_theme_color_333">
                {{ item.name }}
              </p>
            </li>
          </div>
        </ul>
      </div>
      <div
          v-if="picObj['xrayCurvePicture'] || picObj['xraySidePicture']"
          class="pre_list"
      >
        <p class="s_l_txt">
          {{ $t("casesDetail.publicCases.casesPic.xpg") }}
        </p>
        <ul class="pic_list">
          <li
              v-for="item in xPicList"
              :key="item.key"
              @click="
              showCarousel({ name: item.name, index: getPicIndex(item.key) })
            "
          >
            <div class="img_box">
              <img
                  v-if="picObj[item.key]"
                  :src="`${$PicPrefix}${picObj[item.key]['nanoId']}`"
                  :style="{
                  transform: `rotate(${picObj[item.key]['degree']}deg) scaleY(${
                    picObj[item.key]['vertical'] ? -1 : 1
                  }) scaleX(${picObj[item.key]['horizontal'] ? -1 : 1})`,
                }"
                  alt=""
                  class="li_pic"
              />
            </div>
            <p v-if="picObj[item.key]" class="pic_look">
              <img alt="" src="/static/images/createCases/check_icon.png"/>
              <span>{{ $t("cases.createImage.ck") }}</span>
            </p>
            <p
                v-if="picObj[item.key]"
                class="label_text mt10 fz16 main_theme_color_333"
            >
              {{ item.name }}
            </p>
          </li>
        </ul>
      </div>
      <div
          v-if="isArray(picObj['otherPicture']) && picObj['otherPicture'].length"
          class="pre_list"
      >
        <p class="s_l_txt">
          {{ $t("casesDetail.publicCases.casesPic.qtyx") }}
        </p>
        <ul v-show="picObj['otherPicture']" class="pic_list">
          <template>
            <li
                v-for="(item, index) in picObj['otherPicture']"
                :key="index"
                @click="showCarousel({ name: item.name, index: item.index })"
            >
              <div class="img_box">
                <img
                    :src="`${$PicPrefix}${item['nanoId']}`"
                    :style="{
                    transform: `rotate(${item['degree']}deg) scaleY(${
                      item['vertical'] ? -1 : 1
                    }) scaleX(${item['horizontal'] ? -1 : 1})`,
                  }"
                    alt=""
                    class="li_pic"
                />
              </div>
              <p class="pic_look">
                <img alt="" src="/static/images/createCases/check_icon.png"/>
                <span>{{ $t("cases.createImage.ck") }}</span>
              </p>
            </li>
          </template>
        </ul>
      </div>
    </div>
    <PreviewImg
        ref="previewImg"
        :carouselList="carouselList"
        @imgChange="imgChange"
    />
  </div>
</template>

<script>
import {getQiniuToken, modifyPicture} from "common/api/public";
import {
  downImage,
  toHtml2canvas,
  dataURLtoBlob,
  putb64,
} from "common/js/util";
import {mapState} from "vuex";
import {map, isArray, extend, each, filter, find, get, split} from "lodash";
import LookPic from "components/lookPic-copy/index";
import PreviewImg from "components/preview-image/preview-image.vue";

export default {
  data() {
    return {
      carouselName: this.$t("cases.createImage.cmx"),
      carouselFiled: "sideFacePicture",
      dialogVisible: false,
      picNum: 1,
      rotateNumX: 0,
      rotateNumY: 0,
      rotateNum: 0,
      carouselList: [],
      uploadToken: "",
      id: "",
      picObj: {},
      visible: false,
    };
  },
  props: {
    surfacePicList: {
      type: Array,
      default: () => [],
    },
    xPicList: {
      type: Array,
      default: () => [],
    },
    details: {
      type: Object,
      default: () => ({}),
    },
    caseInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    isArray,
    handleClose() {
      this.dialogVisible = false;
    },
    imgChange(obj, idx) {
      const current = this.carouselList[idx];
      if (obj.filed !== "otherPicture") {
        this.picObj[current.filed] = {
          nanoId: this.picObj[current.filed].nanoId,
          degree: obj.degree,
          horizontal: obj.horizontal,
          vertical: obj.vertical,
          ETag: obj.ETag,
        };
      } else {
        this.picObj["otherPicture"].map((item) => {
          if (item.key == current.key) {
            this.$set(item, "degree", obj.degree);
            this.$set(item, "horizontal", obj.horizontal);
            this.$set(item, "vertical", obj.vertical);
          }
        });
      }
    },
    getPicIndex(filed) {
      return filter(this.carouselList, (item) => item.filed === filed)[0].index;
    },
    isJSON(str) {
      if (str == "") {
        return "";
      }
      if (typeof str == "string") {
        try {
          JSON.parse(str);
          let obj = JSON.parse(str);
          !obj.nanoId && (obj.nanoId = obj.ETag);
          !obj.degree && (obj.degree = 0);
          !obj.horizontal && (obj.horizontal = false);
          !obj.vertical && (obj.vertical = false);
          return obj;
        } catch (error) {
          return {
            ETag: str,
            nanoId: str,
            degree: 0,
            horizontal: false,
            vertical: false,
          };
        }
      }
    },
    showCarousel(item) {
      let {name, index} = item;
      this.$nextTick(() => {
        this.$refs["previewImg"] && this.$refs["previewImg"].show(name, index);
      });
    },
  },
/*
  watch: {
    details: {
      handler(obj) {
        const {
          sideFacePicture,
          frontFacePicture,
          smilePicture,
          upToothPicture,
          downToothPicture,
          mouthRightPicture,
          mouthFrontPicture,
          mouthLeftPicture,
          xrayCurvePicture,
          xraySidePicture,
          otherPicture,
          id,
          anteriorOverjetPicture,
          bitePicture,
          openAndClosePicture,
        } = obj;
        this.picObj = {
          sideFacePicture,
          frontFacePicture,
          smilePicture,
          anteriorOverjetPicture,
          upToothPicture,
          downToothPicture,
          mouthRightPicture,
          mouthFrontPicture,
          mouthLeftPicture,
          xrayCurvePicture,
          xraySidePicture,
          bitePicture,
          openAndClosePicture,
        };
        this.picObj["sideFacePicture"] = this.isJSON(
            this.picObj["sideFacePicture"]
        );
        this.picObj["frontFacePicture"] = this.isJSON(
            this.picObj["frontFacePicture"]
        );
        this.picObj["smilePicture"] = this.isJSON(this.picObj["smilePicture"]);
        this.picObj["upToothPicture"] = this.isJSON(
            this.picObj["upToothPicture"]
        );
        this.picObj["downToothPicture"] = this.isJSON(
            this.picObj["downToothPicture"]
        );
        this.picObj["mouthRightPicture"] = this.isJSON(
            this.picObj["mouthRightPicture"]
        );
        this.picObj["mouthFrontPicture"] = this.isJSON(
            this.picObj["mouthFrontPicture"]
        );
        this.picObj["mouthLeftPicture"] = this.isJSON(
            this.picObj["mouthLeftPicture"]
        );
        this.picObj["xrayCurvePicture"] = this.isJSON(
            this.picObj["xrayCurvePicture"]
        );
        this.picObj["xraySidePicture"] = this.isJSON(
            this.picObj["xraySidePicture"]
        );
        this.picObj["anteriorOverjetPicture"] = this.isJSON(
            this.picObj["anteriorOverjetPicture"]
        );
        this.picObj["bitePicture"] = this.isJSON(this.picObj["bitePicture"]);
        this.picObj["openAndClosePicture"] = this.isJSON(
            this.picObj["openAndClosePicture"]
        );
        try {
          JSON.parse(otherPicture);
          let fileList = [];
          map(JSON.parse(otherPicture), (it) => {
            fileList.push(it);
          });
          this.picObj["otherPicture"] = fileList;
        } catch (error) {
          const arr = otherPicture ? otherPicture.split(",") : [];
          let fileList = [];
          map(arr, (it) => {
            fileList.push({ETag: it, nanoId: it, filed: "otherPicture"});
          });
          this.picObj["otherPicture"] = fileList;
        }
        this.id = id;
        this.carouselList = [
          sideFacePicture
              ? {
                href: this.$PicPrefix + this.isJSON(sideFacePicture)["ETag"],
                filed: "sideFacePicture",
                name:
                    obj.caseInfo.cureType !== "F"
                        ? this.$t("cases.createImage.cmx")
                        : this.$t("cases.createImage.zcmwxx"),
                ETag:
                    this.isJSON(sideFacePicture)["ETag"] ||
                    this.isJSON(sideFacePicture)["nanoId"],
                nanoId: this.isJSON(sideFacePicture)["nanoId"],
                degree: this.isJSON(sideFacePicture).degree || 0,
                horizontal: this.isJSON(sideFacePicture).horizontal || false,
                vertical: this.isJSON(sideFacePicture).vertical || false,
              }
              : "",
          frontFacePicture
              ? {
                href: this.$PicPrefix + this.isJSON(frontFacePicture)["ETag"],
                filed: "frontFacePicture",
                name:
                    obj.caseInfo.cureType !== "F"
                        ? this.$t("cases.createImage.zmx")
                        : this.$t("cases.createImage.zmwxx"),
                ETag:
                    this.isJSON(frontFacePicture)["ETag"] ||
                    this.isJSON(frontFacePicture)["nanoId"],
                nanoId: this.isJSON(frontFacePicture)["nanoId"],
                degree: this.isJSON(frontFacePicture).degree || 0,
                horizontal: this.isJSON(frontFacePicture).horizontal || false,
                vertical: this.isJSON(frontFacePicture).vertical || false,
              }
              : "",
          smilePicture
              ? {
                href: this.$PicPrefix + this.isJSON(smilePicture)["ETag"],
                filed: "smilePicture",
                name:
                    obj.caseInfo.cureType !== "F"
                        ? this.$t("cases.createImage.wxx")
                        : this.$t("cases.createImage.ycmwxx"),
                ETag:
                    this.isJSON(smilePicture)["ETag"] ||
                    this.isJSON(smilePicture)["nanoId"],
                nanoId: this.isJSON(smilePicture)["nanoId"],
                degree: this.isJSON(smilePicture).degree || 0,
                horizontal: this.isJSON(smilePicture).horizontal || false,
                vertical: this.isJSON(smilePicture).vertical || false,
              }
              : "",
          upToothPicture
              ? {
                href: this.$PicPrefix + this.isJSON(upToothPicture)["ETag"],
                filed: "upToothPicture",
                name: this.$t("cases.createImage.sylx"),
                ETag:
                    this.isJSON(upToothPicture)["ETag"] ||
                    this.isJSON(upToothPicture)["nanoId"],
                nanoId: this.isJSON(upToothPicture)["nanoId"],
                degree: this.isJSON(upToothPicture).degree || 0,
                horizontal: this.isJSON(upToothPicture).horizontal || false,
                vertical: this.isJSON(upToothPicture).vertical || false,
              }
              : "",
          anteriorOverjetPicture
              ? {
                href:
                    this.$PicPrefix + this.isJSON(anteriorOverjetPicture)["ETag"],
                filed: "anteriorOverjetPicture",
                name: this.$t("cases.createImage.qyfgx"),
                ETag:
                    this.isJSON(anteriorOverjetPicture)["ETag"] ||
                    this.isJSON(anteriorOverjetPicture)["nanoId"],
                nanoId: this.isJSON(anteriorOverjetPicture)["nanoId"],
                degree: this.isJSON(anteriorOverjetPicture).degree || 0,
                horizontal:
                    this.isJSON(anteriorOverjetPicture).horizontal || false,
                vertical: this.isJSON(anteriorOverjetPicture).vertical || false,
              }
              : "",
          downToothPicture
              ? {
                href: this.$PicPrefix + this.isJSON(downToothPicture)["ETag"],
                filed: "downToothPicture",
                name: this.$t("cases.createImage.xylx"),
                ETag:
                    this.isJSON(downToothPicture)["ETag"] ||
                    this.isJSON(downToothPicture)["nanoId"],
                nanoId: this.isJSON(downToothPicture)["nanoId"],
                degree: this.isJSON(downToothPicture).degree || 0,
                horizontal: this.isJSON(downToothPicture).horizontal || false,
                vertical: this.isJSON(downToothPicture).vertical || false,
              }
              : "",
          mouthRightPicture
              ? {
                href: this.$PicPrefix + this.isJSON(mouthRightPicture)["ETag"],
                filed: "mouthRightPicture",
                name: this.$t("cases.createImage.knzcyx"),
                ETag:
                    this.isJSON(mouthRightPicture)["ETag"] ||
                    this.isJSON(mouthRightPicture)["nanoId"],
                nanoId: this.isJSON(mouthRightPicture)["nanoId"],
                degree: this.isJSON(mouthRightPicture).degree || 0,
                horizontal: this.isJSON(mouthRightPicture).horizontal || false,
                vertical: this.isJSON(mouthRightPicture).vertical || false,
              }
              : "",
          mouthFrontPicture
              ? {
                href: this.$PicPrefix + this.isJSON(mouthFrontPicture)["ETag"],
                filed: "mouthFrontPicture",
                name: this.$t("cases.createImage.knzwx"),
                ETag:
                    this.isJSON(mouthFrontPicture)["ETag"] ||
                    this.isJSON(mouthFrontPicture)["nanoId"],
                nanoId: this.isJSON(mouthFrontPicture)["nanoId"],
                degree: this.isJSON(mouthFrontPicture).degree || 0,
                horizontal: this.isJSON(mouthFrontPicture).horizontal || false,
                vertical: this.isJSON(mouthFrontPicture).vertical || false,
              }
              : "",
          mouthLeftPicture
              ? {
                href: this.$PicPrefix + this.isJSON(mouthLeftPicture)["ETag"],
                filed: "mouthLeftPicture",
                name: this.$t("cases.createImage.knycyx"),
                ETag:
                    this.isJSON(mouthLeftPicture)["ETag"] ||
                    this.isJSON(mouthLeftPicture)["nanoId"],
                nanoId: this.isJSON(mouthLeftPicture)["nanoId"],
                degree: this.isJSON(mouthLeftPicture).degree || 0,
                horizontal: this.isJSON(mouthLeftPicture).horizontal || false,
                vertical: this.isJSON(mouthLeftPicture).vertical || false,
              }
              : "",
          xrayCurvePicture
              ? {
                href: this.$PicPrefix + this.isJSON(xrayCurvePicture)["ETag"],
                filed: "xrayCurvePicture",
                name: this.$t("cases.createImage.dcp"),
                ETag:
                    this.isJSON(xrayCurvePicture)["ETag"] ||
                    this.isJSON(xrayCurvePicture)["nanoId"],
                nanoId: this.isJSON(xrayCurvePicture)["nanoId"],
                degree: this.isJSON(xrayCurvePicture).degree || 0,
                horizontal: this.isJSON(xrayCurvePicture).horizontal || false,
                vertical: this.isJSON(xrayCurvePicture).vertical || false,
              }
              : "",
          xraySidePicture
              ? {
                href: this.$PicPrefix + this.isJSON(xraySidePicture)["ETag"],
                filed: "xraySidePicture",
                name: this.$t("cases.createImage.dwp"),
                ETag:
                    this.isJSON(xraySidePicture)["ETag"] ||
                    this.isJSON(xraySidePicture)["nanoId"],
                nanoId: this.isJSON(xraySidePicture)["nanoId"],
                degree: this.isJSON(xraySidePicture).degree || 0,
                horizontal: this.isJSON(xraySidePicture).horizontal || false,
                vertical: this.isJSON(xraySidePicture).vertical || false,
              }
              : "",
          bitePicture
              ? {
                href: this.$PicPrefix + this.isJSON(bitePicture)["ETag"],
                filed: "bitePicture",
                name: this.$t("cases.createImage.yhx"),
                ETag:
                    this.isJSON(bitePicture)["ETag"] ||
                    this.isJSON(bitePicture)["nanoId"],
                nanoId: this.isJSON(bitePicture)["nanoId"],
                degree: this.isJSON(bitePicture).degree || 0,
                horizontal: this.isJSON(bitePicture).horizontal || false,
                vertical: this.isJSON(bitePicture).vertical || false,
              }
              : "",
          openAndClosePicture
              ? {
                href:
                    this.$PicPrefix + this.isJSON(openAndClosePicture)["ETag"],
                filed: "openAndClosePicture",
                name: this.$t("cases.createImage.khx"),
                ETag:
                    this.isJSON(openAndClosePicture)["ETag"] ||
                    this.isJSON(openAndClosePicture)["nanoId"],
                nanoId: this.isJSON(openAndClosePicture)["nanoId"],
                degree: this.isJSON(openAndClosePicture).degree || 0,
                horizontal:
                    this.isJSON(openAndClosePicture).horizontal || false,
                vertical: this.isJSON(openAndClosePicture).vertical || false,
              }
              : "",
        ].filter((item) => !!item.href);
        this.carouselList = [
          ...this.carouselList,
          ...map(this.fileList, (item) => {
            return {
              href: this.$PicPrefix + item,
              filed: "otherPicture",
              name: this.$t("cases.createImage.yxzl"),
            };
          }),
        ];
        let otherIndexList = map(this.picObj["otherPicture"], (item, index) => {
          return {
            name: this.$t("casesDetail.casesChildren.operationChildren.yxzl"),
            href: this.$PicPrefix + item["ETag"],
            nanoId: item["nanoId"],
            ETag: item["ETag"],
            filed: "otherPicture",
            degree: item.degree,
            horizontal: item.horizontal,
            vertical: item.vertical,
            key: index,
          };
        });
        this.carouselList = [...this.carouselList, ...otherIndexList];
        this.carouselList = map(this.carouselList, (item, index) => {
          return extend({}, item, {index: index + ""});
        });
        this.picObj["otherPicture"] = filter(
            this.carouselList,
            (item) => item.filed === "otherPicture"
        );
      },
      deep: true,
    },
  },
*/
  computed: {
    ...mapState({
      language: (state) => state.language,
    }),
  },
  created() {
    getQiniuToken().then((data) => {
      this.uploadToken = data;
    });
  },
  mounted() {
    setTimeout(() => {
      this.visible = true;
      const {
        sideFacePicture,
        frontFacePicture,
        smilePicture,
        upToothPicture,
        downToothPicture,
        mouthRightPicture,
        mouthFrontPicture,
        mouthLeftPicture,
        xrayCurvePicture,
        xraySidePicture,
        otherPicture,
        id,
        anteriorOverjetPicture,
        bitePicture,
        openAndClosePicture,
      } = this.details;
      this.picObj = {
        sideFacePicture,
        frontFacePicture,
        smilePicture,
        anteriorOverjetPicture,
        upToothPicture,
        downToothPicture,
        mouthRightPicture,
        mouthFrontPicture,
        mouthLeftPicture,
        xrayCurvePicture,
        xraySidePicture,
        bitePicture,
        openAndClosePicture,
      };
      this.picObj["sideFacePicture"] = this.isJSON(
          this.picObj["sideFacePicture"]
      );
      this.picObj["frontFacePicture"] = this.isJSON(
          this.picObj["frontFacePicture"]
      );
      this.picObj["smilePicture"] = this.isJSON(this.picObj["smilePicture"]);
      this.picObj["upToothPicture"] = this.isJSON(
          this.picObj["upToothPicture"]
      );
      this.picObj["downToothPicture"] = this.isJSON(
          this.picObj["downToothPicture"]
      );
      this.picObj["mouthRightPicture"] = this.isJSON(
          this.picObj["mouthRightPicture"]
      );
      this.picObj["mouthFrontPicture"] = this.isJSON(
          this.picObj["mouthFrontPicture"]
      );
      this.picObj["mouthLeftPicture"] = this.isJSON(
          this.picObj["mouthLeftPicture"]
      );
      this.picObj["xrayCurvePicture"] = this.isJSON(
          this.picObj["xrayCurvePicture"]
      );
      this.picObj["xraySidePicture"] = this.isJSON(
          this.picObj["xraySidePicture"]
      );
      this.picObj["anteriorOverjetPicture"] = this.isJSON(
          this.picObj["anteriorOverjetPicture"]
      );
      this.picObj["bitePicture"] = this.isJSON(this.picObj["bitePicture"]);
      this.picObj["openAndClosePicture"] = this.isJSON(
          this.picObj["openAndClosePicture"]
      );
      try {
        JSON.parse(otherPicture);
        let fileList = [];
        map(JSON.parse(otherPicture), (it) => {
          fileList.push(it);
        });
        this.picObj["otherPicture"] = fileList;
      } catch (error) {
        const arr = otherPicture ? otherPicture.split(",") : [];
        let fileList = [];
        map(arr, (it) => {
          fileList.push({ETag: it, nanoId: it, filed: "otherPicture"});
        });
        this.picObj["otherPicture"] = fileList;
      }
      this.id = id;
      this.carouselList = [
        sideFacePicture
            ? {
              href: this.$PicPrefix + this.isJSON(sideFacePicture)["ETag"],
              filed: "sideFacePicture",
              name:
                  this.details.cureType !== "F"
                      ? this.$t("cases.createImage.cmx")
                      : this.$t("cases.createImage.zcmwxx"),
              ETag:
                  this.isJSON(sideFacePicture)["ETag"] ||
                  this.isJSON(sideFacePicture)["nanoId"],
              nanoId: this.isJSON(sideFacePicture)["nanoId"],
              degree: this.isJSON(sideFacePicture).degree || 0,
              horizontal: this.isJSON(sideFacePicture).horizontal || false,
              vertical: this.isJSON(sideFacePicture).vertical || false,
            }
            : "",
        frontFacePicture
            ? {
              href: this.$PicPrefix + this.isJSON(frontFacePicture)["ETag"],
              filed: "frontFacePicture",
              name:
                  this.details.cureType !== "F"
                      ? this.$t("cases.createImage.zmx")
                      : this.$t("cases.createImage.zmwxx"),
              ETag:
                  this.isJSON(frontFacePicture)["ETag"] ||
                  this.isJSON(frontFacePicture)["nanoId"],
              nanoId: this.isJSON(frontFacePicture)["nanoId"],
              degree: this.isJSON(frontFacePicture).degree || 0,
              horizontal: this.isJSON(frontFacePicture).horizontal || false,
              vertical: this.isJSON(frontFacePicture).vertical || false,
            }
            : "",
        smilePicture
            ? {
              href: this.$PicPrefix + this.isJSON(smilePicture)["ETag"],
              filed: "smilePicture",
              name:
                  this.details.cureType !== "F"
                      ? this.$t("cases.createImage.wxx")
                      : this.$t("cases.createImage.ycmwxx"),
              ETag:
                  this.isJSON(smilePicture)["ETag"] ||
                  this.isJSON(smilePicture)["nanoId"],
              nanoId: this.isJSON(smilePicture)["nanoId"],
              degree: this.isJSON(smilePicture).degree || 0,
              horizontal: this.isJSON(smilePicture).horizontal || false,
              vertical: this.isJSON(smilePicture).vertical || false,
            }
            : "",
        upToothPicture
            ? {
              href: this.$PicPrefix + this.isJSON(upToothPicture)["ETag"],
              filed: "upToothPicture",
              name: this.$t("cases.createImage.sylx"),
              ETag:
                  this.isJSON(upToothPicture)["ETag"] ||
                  this.isJSON(upToothPicture)["nanoId"],
              nanoId: this.isJSON(upToothPicture)["nanoId"],
              degree: this.isJSON(upToothPicture).degree || 0,
              horizontal: this.isJSON(upToothPicture).horizontal || false,
              vertical: this.isJSON(upToothPicture).vertical || false,
            }
            : "",
        anteriorOverjetPicture
            ? {
              href:
                  this.$PicPrefix + this.isJSON(anteriorOverjetPicture)["ETag"],
              filed: "anteriorOverjetPicture",
              name: this.$t("cases.createImage.qyfgx"),
              ETag:
                  this.isJSON(anteriorOverjetPicture)["ETag"] ||
                  this.isJSON(anteriorOverjetPicture)["nanoId"],
              nanoId: this.isJSON(anteriorOverjetPicture)["nanoId"],
              degree: this.isJSON(anteriorOverjetPicture).degree || 0,
              horizontal:
                  this.isJSON(anteriorOverjetPicture).horizontal || false,
              vertical: this.isJSON(anteriorOverjetPicture).vertical || false,
            }
            : "",
        downToothPicture
            ? {
              href: this.$PicPrefix + this.isJSON(downToothPicture)["ETag"],
              filed: "downToothPicture",
              name: this.$t("cases.createImage.xylx"),
              ETag:
                  this.isJSON(downToothPicture)["ETag"] ||
                  this.isJSON(downToothPicture)["nanoId"],
              nanoId: this.isJSON(downToothPicture)["nanoId"],
              degree: this.isJSON(downToothPicture).degree || 0,
              horizontal: this.isJSON(downToothPicture).horizontal || false,
              vertical: this.isJSON(downToothPicture).vertical || false,
            }
            : "",
        mouthRightPicture
            ? {
              href: this.$PicPrefix + this.isJSON(mouthRightPicture)["ETag"],
              filed: "mouthRightPicture",
              name: this.$t("cases.createImage.knzcyx"),
              ETag:
                  this.isJSON(mouthRightPicture)["ETag"] ||
                  this.isJSON(mouthRightPicture)["nanoId"],
              nanoId: this.isJSON(mouthRightPicture)["nanoId"],
              degree: this.isJSON(mouthRightPicture).degree || 0,
              horizontal: this.isJSON(mouthRightPicture).horizontal || false,
              vertical: this.isJSON(mouthRightPicture).vertical || false,
            }
            : "",
        mouthFrontPicture
            ? {
              href: this.$PicPrefix + this.isJSON(mouthFrontPicture)["ETag"],
              filed: "mouthFrontPicture",
              name: this.$t("cases.createImage.knzwx"),
              ETag:
                  this.isJSON(mouthFrontPicture)["ETag"] ||
                  this.isJSON(mouthFrontPicture)["nanoId"],
              nanoId: this.isJSON(mouthFrontPicture)["nanoId"],
              degree: this.isJSON(mouthFrontPicture).degree || 0,
              horizontal: this.isJSON(mouthFrontPicture).horizontal || false,
              vertical: this.isJSON(mouthFrontPicture).vertical || false,
            }
            : "",
        mouthLeftPicture
            ? {
              href: this.$PicPrefix + this.isJSON(mouthLeftPicture)["ETag"],
              filed: "mouthLeftPicture",
              name: this.$t("cases.createImage.knycyx"),
              ETag:
                  this.isJSON(mouthLeftPicture)["ETag"] ||
                  this.isJSON(mouthLeftPicture)["nanoId"],
              nanoId: this.isJSON(mouthLeftPicture)["nanoId"],
              degree: this.isJSON(mouthLeftPicture).degree || 0,
              horizontal: this.isJSON(mouthLeftPicture).horizontal || false,
              vertical: this.isJSON(mouthLeftPicture).vertical || false,
            }
            : "",
        xrayCurvePicture
            ? {
              href: this.$PicPrefix + this.isJSON(xrayCurvePicture)["ETag"],
              filed: "xrayCurvePicture",
              name: this.$t("cases.createImage.dcp"),
              ETag:
                  this.isJSON(xrayCurvePicture)["ETag"] ||
                  this.isJSON(xrayCurvePicture)["nanoId"],
              nanoId: this.isJSON(xrayCurvePicture)["nanoId"],
              degree: this.isJSON(xrayCurvePicture).degree || 0,
              horizontal: this.isJSON(xrayCurvePicture).horizontal || false,
              vertical: this.isJSON(xrayCurvePicture).vertical || false,
            }
            : "",
        xraySidePicture
            ? {
              href: this.$PicPrefix + this.isJSON(xraySidePicture)["ETag"],
              filed: "xraySidePicture",
              name: this.$t("cases.createImage.dwp"),
              ETag:
                  this.isJSON(xraySidePicture)["ETag"] ||
                  this.isJSON(xraySidePicture)["nanoId"],
              nanoId: this.isJSON(xraySidePicture)["nanoId"],
              degree: this.isJSON(xraySidePicture).degree || 0,
              horizontal: this.isJSON(xraySidePicture).horizontal || false,
              vertical: this.isJSON(xraySidePicture).vertical || false,
            }
            : "",
        bitePicture
            ? {
              href: this.$PicPrefix + this.isJSON(bitePicture)["ETag"],
              filed: "bitePicture",
              name: this.$t("cases.createImage.yhx"),
              ETag:
                  this.isJSON(bitePicture)["ETag"] ||
                  this.isJSON(bitePicture)["nanoId"],
              nanoId: this.isJSON(bitePicture)["nanoId"],
              degree: this.isJSON(bitePicture).degree || 0,
              horizontal: this.isJSON(bitePicture).horizontal || false,
              vertical: this.isJSON(bitePicture).vertical || false,
            }
            : "",
        openAndClosePicture
            ? {
              href:
                  this.$PicPrefix + this.isJSON(openAndClosePicture)["ETag"],
              filed: "openAndClosePicture",
              name: this.$t("cases.createImage.khx"),
              ETag:
                  this.isJSON(openAndClosePicture)["ETag"] ||
                  this.isJSON(openAndClosePicture)["nanoId"],
              nanoId: this.isJSON(openAndClosePicture)["nanoId"],
              degree: this.isJSON(openAndClosePicture).degree || 0,
              horizontal:
                  this.isJSON(openAndClosePicture).horizontal || false,
              vertical: this.isJSON(openAndClosePicture).vertical || false,
            }
            : "",
      ].filter((item) => !!item.href);
      this.carouselList = [
        ...this.carouselList,
        ...map(this.fileList, (item) => {
          return {
            href: this.$PicPrefix + item,
            filed: "otherPicture",
            name: this.$t("cases.createImage.yxzl"),
          };
        }),
      ];
      let otherIndexList = map(this.picObj["otherPicture"], (item, index) => {
        return {
          name: this.$t("casesDetail.casesChildren.operationChildren.yxzl"),
          href: this.$PicPrefix + item["ETag"],
          nanoId: item["nanoId"],
          ETag: item["ETag"],
          filed: "otherPicture",
          degree: item.degree,
          horizontal: item.horizontal,
          vertical: item.vertical,
          key: index,
        };
      });
      this.carouselList = [...this.carouselList, ...otherIndexList];
      this.carouselList = map(this.carouselList, (item, index) => {
        return extend({}, item, {index: index + ""});
      });
      this.picObj["otherPicture"] = filter(
          this.carouselList,
          (item) => item.filed === "otherPicture"
      );
    }, 1000);

  },
  components: {
    LookPic,
    PreviewImg,
  },
};
</script>

<style lang="scss" scoped>
.s_l_icon {
  margin-right: 0.1rem;
  display: inline-block;
  width: 0.24rem;
  height: 0.24rem;
  background-size: 100% 100%;
  background-image: url("../../../common/imgs/case/case_pic_icon.png");
}

.preview_single {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.2rem;
}

.preview_s_left {
  .s_l_h5 {
    display: flex;
    align-items: center;
    margin-bottom: 0.2rem;
    font-size: 16px;
    color: $main_theme_color_333;
  }

  .s_l_box {
    color: #666;
    padding-left: 0.24rem;

    .s_l_box_p {
      display: flex;
      align-items: center;
      margin-bottom: 0.21rem;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        margin-right: 0.2rem;
      }
    }

    .s_l_con {
      display: flex;
      margin-bottom: 0.31rem;
      position: relative;

      .s_l_con_left {
        margin-right: 0.1rem;
        white-space: nowrap;
      }

      .s_l_con_right {
        flex: 1;

        .p {
          margin-bottom: 0.21rem;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .note_box {
          height: 0.6rem;
        }

        .s_l_con_note {
          position: absolute;
          left: 0;
          width: 100%;
          padding: 0.2rem 0.1rem;
          background-color: #f3f3f3;
        }

        .r_strong {
          font-weight: 600;
          margin-left: 0.1rem;
          color: #111;
        }
      }

      .s_l_con_teeth {
        padding-top: 0.4rem;
        padding-bottom: 0.2rem;
      }
    }
  }

  .pre_list {
    margin-bottom: 0.2rem;

    .s_l_txt {
      color: $main_theme_color_333;
      font-size: 14px;
      margin-bottom: 0.12rem;
    }

    li {
      width: 3.44rem;
      height: 2.4rem;
      border-radius: 0.06rem;
      margin-bottom: 0.2rem;
      text-align: center;

      &:last-child {
        margin-right: 0;
      }
    }

    .img_box {
      width: 100%;
      height: 100%;
      text-align: center;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }

    .label_text {
      position: absolute;
      bottom: -0.3rem;
      left: 50%;
      transform: translateX(-50%);
    }

    .li_pic {
      max-height: 100%;
      max-width: 100%;
    }

    .pic_list_space {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;


      li {
        cursor: pointer;
        position: relative;
        margin-bottom: 0.4rem;
        margin-right: 45px;
        background-color: #f0f0f0;

        &:nth-child(3n + 3) {
          margin-right: 0px;
        }

        &:hover .pic_look {
          opacity: 1;
        }
      }
    }

    .pic_list {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;

      li {
        background-color: #f0f0f0;
        cursor: pointer;
        margin-bottom: 0.4rem;
        margin-right: 45px;
        position: relative;
        overflow: hidden;

        &:nth-child(3n + 3) {
          margin-right: 0px;
        }

        &:hover .pic_look {
          opacity: 1;
        }
      }
    }

    .pic_look {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 0.5rem;
      background-color: rgba(0, 0, 0, 0.6);
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.16rem;
      opacity: 0;
      transition: opacity 0.6s;

      img {
        min-width: 0.24rem;
        width: 0.24rem;
        height: 0.16rem;
        margin-right: 0.1rem;
      }
    }
  }
}

.preview_s_right {
  width: 6.3rem;
  height: 0.85rem;
  display: flex;
  align-items: center;
  padding-left: 0.28rem;
  box-sizing: border-box;
  background-color: #f3f3f3;
  border-radius: 0.06rem;

  .preview_s_r_strong {
    font-size: 0.2rem;
    margin-left: 0.1rem;
    margin-right: 0.09rem;
  }

  .preview_s_r_icon {
    display: inline-block;
    width: 0.18rem;
    height: 0.18rem;
    background-color: yellow;
    background-size: 100% 100%;
    cursor: pointer;
  }
}

.carousel_option {
  margin-top: 0.25rem;
  padding-bottom: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .o_m {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin-right: 0.6rem;

    &:last-child {
      margin-right: 0;
    }

    img {
      min-width: 0.24rem;
      width: 0.24rem;
      height: 0.24rem;
      margin-bottom: 0.05rem;
    }

    span {
      color: #999999;
      font-size: 0.16rem;
    }
  }
}
</style>
