<template>
  <div class="view_logistics">
    <back-step
      :goList="[{
        url: '/cases',
        name: $t('casesDetail.casesChildren.common.bl')
      }, {
        url: `/cases/detail?caseId=${$route.query.caseId}&curePlanId=${$route.query.curePlanId}`,
        name: $t('casesDetail.casesChildren.common.blxq')
      }]"
      :currentTxt="$t('casesDetail.casesChildren.viewLogistics.ckwl')"
    />
    <div class="view_logistics_header">
      <div class="head_con">
        <div class="h_l_icon">
          <img :src="`http://file.aismile.cn/express/${get(logisticsMsg.logisticsInfo,'companyCode')}.png`" alt="">
        </div>
        <div class="head_con_box">
          <div class="head__box_l">
            <h5>{{get(logisticsMsg.logisticsInfo, 'logisticsCompany') || '-'}}</h5>
            <p>{{$t('casesDetail.casesChildren.viewLogistics.ydh')}}：{{get(logisticsMsg.logisticsInfo, 'logisticsNo') || '-'}}</p>
          </div>
          <div class="head__box_c">{{$t('casesDetail.casesChildren.viewLogistics.wjnr')}}：{{logisticsMsg.logisticsName || '-'}}</div>
          <div class="head__box_r">
            <p class="head__box_r_p1" style="opacity: 0;">{{get(logisticsMsg.logisticsInfo, 'senderProvinceName') || '-'}}
              <span class="h_top_icon"></span> {{get(logisticsMsg.logisticsInfo, 'receiverProvinceName') || '-'}}</p>
            <p class="head__box_r_p2">{{get(logisticsMsg.logisticsInfo, 'createDatetime')}}</p>
            <p class="head__box_r_p3 tac">{{statusObj[logisticsMsg.status]}}</p>
          </div>
        </div>
        <div class="main_big_btn main_theme_color_btn ml20" :style="{width: lang === 'en_US' ? '2.56rem' : '2rem'}" @click="isShowConfirm = true" v-if="logisticsMsg.status === '0'">
          {{$t('casesDetail.casesChildren.viewLogistics.qrsh')}}
        </div>
      </div>
    </div>
    <div class="look_wearing_box">
      <ul class="look_box_ul" v-if="currentList.length" style="opacity: 0;">
        <li class="right_li" v-for="(item, index) in currentList" :key="`${item.key}_${index}`">
          <div class="r_li_left">
            <span v-for="(itemTime, index) in item.value" :key="`${itemTime.acceptTime}_${index}`">{{itemTime.acceptTime}}</span>
          </div>
          <div class="r_li_right">
            <h5 class="right_li_h5">
              <span class="r_li_icon_ok"></span>
              {{item.key}}
            </h5>
            <div class="right_li_box">
              <p class="right_li_p" v-for="(itemName, index) in item.value" :key="`${itemName.action}_${index}`">
                {{itemName.acceptStation}}
              </p>
            </div>
          </div>
        </li>
      </ul>
      <div class="no_data" v-else style="opacity: 0;">
        <img src="/static/images/createCases/web_doc_no_logistics.png" alt="">
        <p>{{$t('casesDetail.casesChildren.viewLogistics.zswwl')}}～</p>
      </div>
    </div>
    <confirm-modal
      :confirmObj="{
        tipText: $t('casesDetail.casesChildren.common.qrjx'),
        backText: $t('casesDetail.casesChildren.common.qx'),
        okText: $t('casesDetail.casesChildren.common.qd')
      }"
      @confirmSubmit="confirmSubmit"
      @confirmHide="confirmHide"
      v-show="isShowConfirm"
    />
    <full-loading v-show="isLoading"/>
  </div>
</template>

<script>
  import BackStep from '../childrenPublic/backStep';
  import FullLoading from 'components/full-loading/full-loading';
  import ConfirmModal from 'components/confirm-modal/confirm';
  import {logisticsDetail, PublicLogisticsCaseDetail, logisticsCaseReceive, logisticsCurrent} from 'common/api/cases';
  import {getDictList} from 'common/api/public';
  import {notifyMsg} from 'common/js/util';
  import {get} from 'lodash';
  export default {
    data() {
      return {
        statusObj: {
          '0': this.$t('casesDetail.casesChildren.viewLogistics.dsh'),
          '1': this.$t('casesDetail.casesChildren.viewLogistics.ysh')
        },
        logisticsMsg: {},
        isShowConfirm: false,
        isLoading: false,
        currentList: [],
        logisticsStatus: {},
        lang: localStorage.getItem('user_lang') || 'zh_CN',
      }
    },
    methods: {
      get,
      getLogisticsDetail(id) {
        this.isLoading = true;
        PublicLogisticsCaseDetail(id).then(data => {
          this.isLoading = false;
          this.logisticsMsg = data;
          const {logisticsInfo} = data;
          logisticsCurrent({
            companyCode: logisticsInfo.companyCode,
            logisticsId: logisticsInfo.id
          }).then(data => {
            if(Array.isArray(data.traces)) {
              const obj = {};
              const list = [];
              data.traces.reverse().forEach(item => {
                if(!obj[item.action]) {
                  obj[item.action] = [item];
                  list.push({
                    key: this.logisticsStatus[item.action],
                    value: obj[item.action]
                  });
                }else {
                  obj[item.action].push(item);
                }
              });
              this.currentList = list;
            }
          });
        }).catch(() => {
          this.isLoading = false;
        });
      },
      confirmSubmit() {
        const id = this.$route.query.logisticsId;
        if(!id) {
          this.isShowConfirm = false;
          return false;
        }
        this.isLoading = true;
        logisticsCaseReceive({
          id
        }).then(() => {
          this.isLoading = false;
          this.isShowConfirm = false;
          notifyMsg(this, 'success', this.$t('casesDetail.casesChildren.common.czcg')).then(() => {
            this.getLogisticsDetail(id);
          });
        }).catch(() => {
          this.isLoading = false;
          this.isShowConfirm = false;
        });
      },
      confirmHide() {
        this.isShowConfirm = false;
      }
    },
    components: {
      BackStep,
      ConfirmModal,
      FullLoading
    },
    created() {
      document.title = this.$t('casesDetail.casesChildren.viewLogistics.ckwl');
      const id = this.$route.query.logisticsId;
      if(id) {
        this.isLoading = true;
        getDictList('kdn_logistics_statusex').then(data => {
          const obj = {};
          data.forEach(item => {
            obj[item.key] = item.value;
          });
          this.logisticsStatus = obj;
          this.getLogisticsDetail(id);
        });
      }
    },
  }
</script>

<style scoped lang="scss">
  .view_logistics{
    width: 16rem;
    margin: 0 auto;
    color: $main_theme_color_333;
    span{
      display: inline-block;
    }
    .view_logistics_header{
      margin-bottom: 0.2rem;
      border: 0.01rem #C9C9C9 dashed;
      border-radius: 0.08rem;
      background-color: #fff;
      padding: 0.27rem 0.5rem 0.35rem 0.4rem;
      .head_con{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .h_l_icon{
          min-width: 0.56rem;
          width: 0.56rem;
          height: 0.56rem;
          border-radius: 100%;
          margin-right: 0.11rem;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .head_con_box{
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .head__box_l{
            h5{
              font-size: 0.22rem;
              margin-bottom: 0.13rem;
              color: $main_theme_color_333;
            }
            p{
              font-size: 0.18rem;
              color: $main_theme_color_333;
            }
          }
          .head__box_c{
            font-size: 0.18rem;
            max-width: 45%;
            line-height: 0.22rem;
          }
          .head__box_r{
            .head__box_r_p1{
              font-size: 0.2rem;
              margin-bottom: 0.12rem;
              display: flex;
              align-items: center;
              font-weight: bold;
            }
            .head__box_r_p2{
              font-size: 0.18rem;
              color: #999999;
              margin-bottom: 0.14rem;
            }
            .head__box_r_p3{
              font-size: 0.18rem;
              color: #999999;
            }
          }
        }
        .h_top_icon{
          margin: 0 0.09rem;
          width: 0.38rem;
          height: 0.05rem;
          background-size: 100% 100%;
          background-image: url("/static/images/logistics_arrow.png");
        }
      }
    }
    .look_wearing_box{
      background-color: #fff;
      border-radius: 0.1rem;
      padding: 0.4rem 0.59rem 0.45rem;
      .right_li{
        display: flex;
        .r_li_left{
          min-width: 1.6rem;
          margin-right: 0.27rem;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          font-size: 0.16rem;
          span{
            margin-bottom: 0.04rem;
            line-height: 0.24rem;
            white-space: nowrap;
            &:first-child {
              margin-bottom: 0.16rem;
            }
          }
        }
        .r_li_right{
          flex: 1;
          .right_li_h5{
            display: flex;
            align-items: center;
            color: $main_theme_color_333;
            font-size: 0.18rem;
            font-weight:bold;
            margin-left: -0.1rem;
            span{
              color: #fff;
              font-weight:bold;
              width: 0.2rem;
              height: 0.2rem;
              margin-right: 0.1rem;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 100%;
              font-size: 0.14rem;
              background-size: 100% 100%;
            }
            .r_li_icon_ps{
              background-image: url("/static/images/logistics_user_h.png");
            }
            .r_li_icon_ps_ok{
              background-image: url("/static/images/logistics_user_ok.png");
            }
            .r_li_icon_ys{
              background-image: url("/static/images/logistics_car_h.png");
            }
            .r_li_icon_ys_ok{
              background-image: url("/static/images/logistics_car_ok.png");
            }
            .r_li_icon_qj{
              background-image: url("/static/images/logistics_tp_h.png");
            }
            .r_li_icon_qj_ok{
              background-image: url("/static/images/logistics_tp_ok.png");
            }
            .r_li_icon_ok{
              background-image: url("/static/images/logistics__ok.png");
            }
          }
          .right_li_box{
            border-left: 0.01rem solid #DADADA;
            padding: 0.2rem 0 0.37rem 0.2rem;
            color: #999999;
            box-sizing: border-box;
            .right_li_p{
              margin-bottom: 0.04rem;
              line-height: 0.24rem;
              font-size: 0.14rem;
            }
          }
        }
        &:last-child .r_li_right .right_li_box{
          border: none
        }
      }
      .no_data{
        padding: 0.6rem 0 1rem;
        text-align: center;
        color: #666666;
        font-size: 0.16rem;
        img{
          width: 2.85rem;
          height: 1.8rem;
          margin-bottom: 0.15rem;
        }
      }
    }
  }
</style>
