var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"address-edit"},[_c('el-dialog',{staticClass:"address-edit-dia",attrs:{"destroy-on-close":true,"width":"588px","visible":_vm.visible,"center":"","show-close":false},on:{"close":_vm.cancel}},[_c('div',{staticClass:"dia-header"},[_c('span',[_vm._v(_vm._s(_vm.isEdit ? _vm.$t("yssh.bjdz") : _vm.$t("yssh.xzdz")))]),_c('img',{staticClass:"close-dia",attrs:{"src":require("../../common/imgs/expressage_birdie/dia_close.png"),"alt":""},on:{"click":function($event){$event.stopPropagation();return _vm.cancel($event)}}})]),_c('div',{staticClass:"dia-content"},[_c('el-form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"Form",attrs:{"model":_vm.form,"status-icon":"","rules":_vm.rules,"label-width":_vm.language === 'en_US' ? '140px' : '105px'}},[_c('el-form-item',{attrs:{"label":_vm.$t('birdie.xm'),"prop":"sender"}},[_c('el-input',{staticClass:"input_width",attrs:{"placeholder":_vm.$t('birdie.qtxlxr'),"autocomplete":"off"},model:{value:(_vm.form.sender),callback:function ($$v) {_vm.$set(_vm.form, "sender", $$v)},expression:"form.sender"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('birdie.lxdh'),"prop":_vm.form.contactWay === '1' ? 'phone' : 'phone_'}},[_c('el-select',{staticClass:"input_width",attrs:{"placeholder":_vm.$t('login.retrievePaw.sjh')},on:{"change":_vm.optionsChange},model:{value:(_vm.form.contactWay),callback:function ($$v) {_vm.$set(_vm.form, "contactWay", $$v)},expression:"form.contactWay"}},_vm._l((_vm.iphoneTypeOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('div',{staticClass:"flex-y-c"},[_c('div',{staticClass:"c-red",style:({
                position: 'absolute',
                left: _vm.language === 'en_US' ? '-120px' : '-82px',
                top: 0,
                zIndex: 999
              })},[_vm._v(" * ")]),_c('el-input',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.contactWay === '1'),expression:"form.contactWay === '1'"}],staticClass:"input_width mt16",attrs:{"placeholder":_vm.$t('birdie.qtxsjh'),"autocomplete":"off"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.contactWay === '2'),expression:"form.contactWay === '2'"}],staticClass:"flex-y-c"},[_c('el-form',{staticClass:"flex-y-c",attrs:{"model":_vm.form}},[_c('div',{staticClass:"c-red",style:({
                  position: 'absolute',
                  left: _vm.language === 'en_US' ? '-120px' : '-82px',
                  top: 0,
                  zIndex: 999
                })},[_vm._v(" * ")]),_c('el-form-item',{staticStyle:{"margin-bottom":"0"},attrs:{"prop":"phoneArea"}},[_c('el-input',{staticClass:"qh_input_width mt16",attrs:{"placeholder":_vm.$t('birdie.qh'),"autocomplete":"off"},model:{value:(_vm.form.phoneArea),callback:function ($$v) {_vm.$set(_vm.form, "phoneArea", $$v)},expression:"form.phoneArea"}})],1),_c('div',{staticClass:"iphone_wire"}),_c('el-form-item',{staticClass:"mt16 ml18",staticStyle:{"margin-bottom":"0"},attrs:{"prop":"phone"}},[_c('el-input',{staticClass:"input_width_two",attrs:{"placeholder":_vm.$t('birdie.dhhm'),"autocomplete":"off"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1)],1)],1)],1),_c('el-form-item',{attrs:{"label":_vm.$t('birdie.xzdq'),"prop":"provinces"}},[_c('el-cascader',{staticClass:"input_width",attrs:{"props":{
              value: 'id',
              label: 'name',
              children: 'sub'
            },"placeholder":_vm.$t('birdie.qxzdq'),"options":_vm.cityData},model:{value:(_vm.form.provinces),callback:function ($$v) {_vm.$set(_vm.form, "provinces", $$v)},expression:"form.provinces"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('cases.createUser.xxdz'),"prop":"address"}},[_c('el-input',{staticClass:"input_width",attrs:{"placeholder":_vm.$t('birdie.qxzjddz'),"autocomplete":"off"},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('yssh.sfmr'),"prop":"defaultFlag"}},[_c('el-switch',{attrs:{"active-color":"#32bbd5"},model:{value:(_vm.form.defaultFlag),callback:function ($$v) {_vm.$set(_vm.form, "defaultFlag", $$v)},expression:"form.defaultFlag"}})],1)],1)],1),_c('div',{staticClass:"dia-footer"},[_c('div',{staticClass:"cancel",on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t("casesDetail.casesChildren.casesDoKeep.qx"))+" ")]),_c('el-button',{staticClass:"confirm",class:[_vm.isDis && 'form_wrap_content_btn_dis'],attrs:{"type":"primary","disabled":_vm.isDis,"loading":_vm.confirmLoading},on:{"click":_vm.confirm}},[_vm._v(_vm._s(_vm.$t("casesDetail.casesChildren.casesDoKeep.qd"))+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }