var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"top_flex"},[(_vm.isShowHead == 2)?_c('Head'):_vm._e(),(_vm.isShowHead == 2)?_c('div',{staticClass:"realTime_news",class:{ false_show: !_vm.isShowNews }},[(_vm.isShowNews)?_c('div',{staticClass:"news_box",on:{"click":_vm.toAnnounDetail}},[_c('img',{attrs:{"src":"/static/images/header/laba.png","alt":""}}),_c('vueSeamless',{staticClass:"seamless-warp",staticStyle:{"flex":"1"},attrs:{"data":_vm.seamlessList,"class-option":_vm.option}},[_c('ul',{staticClass:"item"},_vm._l((_vm.seamlessList),function(item,index){return _c('li',{key:index,staticClass:"curp"},[_c('span',{staticClass:"title",domProps:{"textContent":_vm._s(item.title)},on:{"click":function($event){return _vm.informDetails(item.id)}}}),_c('span',{staticClass:"date",domProps:{"textContent":_vm._s(item.date)}})])}),0)])],1):_vm._e(),(_vm.seamlessList[0])?_c('img',{staticClass:"stay_cord curp",class:{ stay_cord_location: !_vm.isShowNews },attrs:{"src":"/static/images/home/lasheng.png","alt":""},on:{"click":_vm.showNews}}):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"app_con",class:{
        app_con_news:
          _vm.isShowNews &&
          !['/login', '/cases/view3d', '/retrievePaw', '/exocad'].includes(
            _vm.$route.path
          ),
        app_con_68:
          !_vm.isShowNews &&
          !['/login', '/cases/view3d', '/retrievePaw', '/exocad'].includes(
            _vm.$route.path
          )
      }},[_c('router-view')],1),_c('Toast',{ref:"toast",attrs:{"text":_vm.textMsg}}),_c('FullLoading',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],attrs:{"id":"fullLoading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }