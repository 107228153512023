<template>
  <!--删除了banner-->
  <!--<div :class="{header_home: $route.path === '/home'}" class="header">-->
  <div class="header">
    <div class="head_con">
      <div style="display: flex;justify-content: space-between">
        <img
          alt=""
          class="logo curp"
          src="../../common/imgs/home/logo.png"
          @click="goPage('/home')"
        />
        <ul style="margin-left: 80px;" class="nav" @click="selectedMenu">
          <li
            :class="
              $route.path === '/' || $route.path.substring(0, 5) === '/home'
                ? 'border li_active'
                : ''
            "
            data-url="/"
          >
            {{ $t("common.header.zy") }}
          </li>
          <li
            :class="
              $route.path.substring(0, 6) === '/cases' ? 'border li_active' : ''
            "
            data-url="/cases?tab=3"
          >
            {{ $t("common.header.bl") }}
          </li>
          <li
              :class="
                    $route.path.substring(0, 11) === '/helpCenter'
                      ? 'border li_active'
                      : ''
                  "
              data-url="/helpCenter"
          >
            {{ $t("common.header.bzzx") }}
          </li>
        </ul>
      </div>

      <ul class="nav" @click="selectedMenu">
        <!--        <li-->
        <!--          :class="-->
        <!--            $route.path.substring(0, 11) === '/helpCenter'-->
        <!--              ? 'border li_active'-->
        <!--              : ''-->
        <!--          "-->
        <!--          data-url="/helpCenter"-->
        <!--        >-->
        <!--          {{ $t("common.header.bzzx") }}-->
        <!--        </li>-->
        <li class="h_right">
          <div v-if="isShowAuto" class="case_autocomplete">
            <div class="mark_loading" v-if="isShowAuto && autocompleteLoading">
              <i class="el-icon-loading" />
            </div>
            <div
              v-if="isShowAuto && !caseList.length"
              class="default_case_page"
            >
              <div class="default_case_icon">
                <div>
                  暂无数据
                </div>
              </div>
            </div>
            <ul v-infinite-scroll="load">
              <li
                @click="selectAuto(item)"
                class="auto_item_li"
                v-for="item in caseList"
                :key="item.id"
              >
                <div class="auto_item_top">
                  <div style="display:flex;">
                    <div>
                      <el-avatar
                        :src="$PicPrefix + item.userInfo.photo"
                        style="width: 0.40rem;height: 0.40rem;"
                      />
                    </div>
                    <div style="margin-left: 10px;">
                      <div class="txt-ellipsis2" style="color: #32bbd5">
                        {{ item.userName }}
                      </div>
                      <div>30岁/男</div>
                    </div>
                  </div>
                  <div style="color: #32bbd5">{{ item.caseNumber }}</div>
                </div>
                <div class="auto_item_bot" style="margin-left: 50px;">
                  <div>{{ nextStatusObj[item.nextStatus] }}</div>
                  <div>{{ item.createDatetime }}</div>
                </div>
              </li>
            </ul>
          </div>
          <el-input
            class="autocomplete_input"
            :placeholder="$t('common.header.ssts')"
            v-model="autocompleteValue"
            ref="autoInputRef"
            @focus="searchFocus"
            @blur="searchBlur"
          />
          <span class="search_icon">
            <img alt="" src="/static/images/header/web_doc_search1.png" />
          </span>
        </li>
        <div class="head_top_con_r">
          <div class="top_c_r_left">
<!--            <span-->
<!--              :class="lang === 'zh_CN' ? 'r_left_icon01' : 'r_left__icon01'"-->
<!--              class="r_left_icon"-->
<!--              @click="changeLanguage"-->
<!--            ></span>-->
            <div class="icon02_box mr20 ml20 curp">
              <img
                alt=""
                class="header_message"
                src="../../common/imgs/home/header_message.png"
                @click="goPage('/home/moreHomePage')"
              />
              <!--<span class="r_left_icon02"></span>-->
              <div
                v-if="msgList.total"
                :class="{
                  expressage_header_tabs_total: +msgList.total < 10,
                  expressage_header_tabs_total_two:
                    +msgList.total <= 99 && +msgList.total > 9,
                  expressage_header_tabs_total_three: +msgList.total > 99
                }"
                class="expressage_header_tabs_total_main tag_b flex-x-y-c"
              >
                {{ +msgList.total > 99 ? "99+" : msgList.total }}
              </div>
              <!--<div class="" >-->
              <!--{{msgList.total || ''}}-->
              <!--</div>-->
              <ul
                :class="{ 'flex-x-y-c': msgList.list.length < 1 }"
                class="mg_box"
              >
                <template v-if="msgList.list.length">
                  <li
                    v-for="item in msgList.list"
                    :key="item.id"
                    class="msg_single"
                    @click="msgOperation(item)"
                  >
                    <p class="msg_content">{{ item.content }}</p>
                    <div class="msg_u_box">
                      <div class="u_b_left">
                        <p v-if="item.fromUserInfo" class="u__l_pic">
                          <img
                            :src="
                              item.fromUserInfo.photo
                                ? $PicPrefix + item.fromUserInfo.photo
                                : defPhoto
                            "
                          />
                        </p>
                        <div class="u__l_r">
                          <h5 class="u__l_r_h5">
                            {{
                              item.fromUserInfo
                                ? `${
                                    item.fromUserInfo.realName
                                      ? item.fromUserInfo.realName
                                      : "-"
                                  }${
                                    item.fromUserInfo.mobile
                                      ? `_${item.fromUserInfo.mobile}`
                                      : ""
                                  }`
                                : ""
                            }}
                          </h5>
                          <p class="u__l_r_p">{{ item.createDatetime }}</p>
                        </div>
                      </div>
                      <div
                        style="cursor:pointer;"
                        class="u_b_right"
                        @click.stop="msgOperation(item)"
                      >
                        {{
                          noteObj[item.node]
                            ? noteObj[item.node].text
                            : $t("common.header.wzdl")
                        }}
                        <img
                          v-if="item.type !== 'case_chat'"
                          alt=""
                          src="../../common/imgs/home/message_icon.png"
                        />
                      </div>
                    </div>
                  </li>
                  <li class="w100 flex-x-y-c" style="height: 0.68rem">
                    <div
                      v-if="msgList.list.length"
                      class="look_all_btn flex-x-y-c curp fz14"
                      @click="goPage('/home/moreHomePage')"
                    >
                      {{ $t("common.header.ckqb") }}
                    </div>
                  </li>
                </template>
                <template v-else>
                  <div
                    class="not_message"
                    style="
                      display: flex;
                      flex-flow: column;
                      align-items: center;
                    "
                  >
                    <img
                      alt=""
                      src="../../common/imgs/home/not_message.png"
                      style="display: block; width: 142px; height: 90px"
                    />
                    <p class="mt12 fz14 main_theme_color_666">
                      {{ $t("home.home.nzshmyxxx") }}
                    </p>
                  </div>
                </template>
                <li class="top_arrows"></li>
              </ul>
            </div>
          </div>
          <div
            class="top_c_r_right"
            @mouseleave="showDropDown = false"
            @mouseover="showDropDown = true"
            @click.stop="goPage('/home/personal')"
          >
            <img
              :src="`${userPhoto ? $PicPrefix + userPhoto : defPhoto}`"
              alt=""
              class="user-avatar"
            />
            <div v-show="showDropDown" class="drop_down" @click.stop>
              <p class="top_arrows_user"></p>
              <!--              <div>-->
              <!--                {{ "loginName" in userMsg ? userMsg.loginName : "" }}-->
              <!--              </div>-->
              <div class="user_wrap" @click="goPage('/home/personal')">
                <div class="icon_user"></div>
                {{ $t("home.personal.personal.grzx") }}
              </div>
              <div
                class="preference_wrap"
                @click="goPage('/cases/clinicalPreferences')"
              >
                <div class="icon_preference"></div>
                {{ $t("home.personal.personal.lcbl") }}
              </div>
              <div
                class="help_wrap"
                @click="
                  () => {
                    showDropDown = false;
                    goPage('/helpCenter?type=14');
                  }
                "
              >
                <div class="icon_help"></div>
                {{ $t("common.header.bzzx") }}
              </div>
              <div class="out_wrap" @click.stop="loginOut">
                <div class="icon_out"></div>
                {{ $t("common.header.tcdl") }}
              </div>
            </div>
          </div>
        </div>
      </ul>
    </div>
    <div v-if="showTour" class="tour-wrap">
      <div class="step-current">
        <div class="icon_preference"></div>
        {{ $t("home.personal.personal.lcbl") }}
      </div>
      <div class="tour-container">
        <img
          alt=""
          class="close-tour"
          src="../../common/imgs/home/close.png"
          @click="setLocalClinicalRrefReminder()"
        />
        <div class="tour-header"></div>
        <div class="tour-content">
          <div>
            <div class="step-title">
              {{ $t("home.personal.personal.lcbl") }}
            </div>
            <div class="step-content">
              {{ $t("home.personal.personal.lcphtx") }}
            </div>
          </div>
          <div class="step-footer">
            <div class="no-reminder" @click="noReminder">
              {{ $t("home.personal.personal.bztx") }}
            </div>
            <div class="to-set" @click="toSetClinic">
              {{ $t("home.personal.personal.szlcph") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <Confirm
      :showPic="false"
      :confirmObj="whyObj"
      @confirmHide="whyHide"
      @confirmSubmit="whySubmit"
      v-show="isShowWhy"
    />
  </div>
</template>

<script>
import {
  case_page,
  caseChatHandle,
  casesDetail,
  queryCasesPage
} from "common/api/cases";
import { getUser, closeClinicalPrefReminder } from "common/api/user";
import { defPhoto } from "common/js/requireImage";
import {
  clearUser,
  isLogin,
  getUserId,
  removeCreate,
  splitText
} from "common/js/util";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import LangStorage from "common/js/cookie";
import { getCityList, getDictList } from "common/api/public";
import $ from "jquery";
import { notifyMsg } from "common/js/util";
import { extend } from "lodash";
import Confirm from "components/confirm-modal/confirm.vue";

export default {
  components: { Confirm },
  data() {
    return {
      whyObj: {
        tipText: [],
        backText: this.$t("common.message.qx"),
        okText: this.$t("common.header.wzdl")
      },
      isShowWhy: false,
      isBlurredBecauseClick: false,
      autocompleteLoading: false,
      defPhoto,
      pathUrl: "",
      qiniuDomain: "",
      showDropDown: false,
      showTour: false,
      userMsg: {},
      autocompleteValue: "",
      caseList: [],
      nextStatusObj: {},
      userPhoto: "",
      conditionForQuery: sessionStorage.getItem("conditionForQuery") || "",
      noteObj: {
        to_improve_plan: {
          text: this.$t("common.header.qtj"),
          href: "/cases/create"
        },
        to_data_approve: {
          text: this.$t("common.header.qtj"),
          href: "/cases/create"
        },
        to_submit: {
          text: this.$t("common.header.qtj"),
          href: "/cases/create"
        },
        to_text_plan: {
          text: this.$t("common.header.qpz"),
          href: "/cases/reviewTextPlan?schemeType=target"
        },
        to_sign: {
          text: this.$t("common.header.qpz"),
          href: "/cases/view3d?schemeType=cure"
        },
        to_doctor_confirm_plan: {
          text: this.$t("common.header.qpz"),
          href: "/cases/view3d?schemeType=cure"
        },
        reject: {
          text: this.$t("common.header.ckyy"),
          isModal: true
        },
        to_receive: {
          text: this.$t("casesDetail.casesDetail.jghx"),
          href: "/cases/detail?delivery=1"
        },
        to_approve_plan: {
          text: this.$t("common.header.qck"),
          href: "/cases/view3d",
          type: "cure"
        },
        to_check_order: {
          text: this.$t("common.header.qtj"),
          href: "/cases/create"
        },
        to_recheck_order: {
          text: this.$t("common.header.qtj"),
          href: "/cases/create"
        },
        to_scan_y: {
          text: this.$t("common.header.qtj"),
          href: "/cases/create"
        },
        to_rescan_y: {
          text: this.$t("common.header.qtj"),
          href: "/cases/create"
        },
        to_done: {
          text: this.$t("common.header.qtj"),
          href: "/cases/maintain"
        }
      },
      lang: localStorage.getItem("user_lang") || "zh_CN",
      modifyPhoto: sessionStorage.getItem("modifyPhoto"),
      isShowAuto: false,
      loadPageSize: 10
    };
  },
  computed: {
    ...mapGetters({
      msgList: "getMsgList" //病例详情
    }),
    ...mapState({
      menuUrl: state => state.menuUrl,
      isLogin: state => state.isLogin,
      isHandleMsg: state => state.isHandleMsg,
      isModifyUserPhoto: state => state.isModifyUserPhoto
    }),
    isShowHead() {
      let str = this.$route.path;
      return str.substring(0, 5) === "/case" || str.substring(0, 5) === "/help";
    }
  },
  methods: {
    ...mapActions({
      getMsgList: "actionGetMsgList" //病例详情
    }),
    searchFocus() {
      this.caseList = [];
      this.isShowAuto = [];
      if (this.autocompleteValue) {
        this.autocompleteLoading = true;
        this.getNewCaseList(this.autocompleteValue, this.loadPageSize);
      }
    },
    searchBlur() {
      setTimeout(() => {
        this.isShowAuto = false;
        this.caseList = [];
        this.autocompleteValue = "";
      }, 300);
    },
    selectAuto(item) {
      window.open(
        `/cases/detail?caseId=${item.id}&curePlanId=${item.curePlanId}`,
        "_blank"
      );
    },
    load() {
      if (this.autocompleteValue) {
        this.loadPageSize += 10;
        this.getNewCaseList(this.autocompleteValue, this.loadPageSize);
      }
    },
    getNewCaseList(conditionForQuery, pageSize) {
      queryCasesPage({
        conditionForQuery,
        clinicIdList: [],
        doctorId: getUserId() || "",
        pageNum: 1,
        pageSize,
        tab: "0"
      }).then(data => {
        this.autocompleteLoading = false;
        this.caseList = data.list;
      });
    },
    changeLanguage() {
      const obj = {
        zh_CN: "en_US",
        en_US: "zh_CN"
      };
      this.lang = obj[this.lang];
      this.$i18n.locale = this.lang;
      LangStorage.setLang(this.$i18n.locale);
      sessionStorage.removeItem("cityData");
      window.location.reload();
    },
    caseMessagePage() {
      this.getMsgList({
        toUserId: getUserId(),
        status: "to_handle"
      });
    },
    msgOperation(item) {
      const {
        node,
        status,
        id,
        refCode: cureNoteId,
        caseId,
        cureType,
        curePlanId
      } = item;
      if (id) {
        if (!node) {
          this.isLoading = true;
          caseChatHandle(id)
            .then(() => {
              this.isLoading = false;
              this.HANDLE_MSG();
            })
            .catch(() => {
              this.isLoading = false;
            });
        } else {
          let query = `?caseId=${caseId}&curePlanId=${curePlanId || ""}`;
          if (node === "reissue") {
            caseChatHandle(id)
              .then(() => {
                this.isLoading = false;
                this.HANDLE_MSG();
                setTimeout(() => {
                  window.open("/cases/bracesReissue" + query + "&tabIdx=1");
                }, 1000);
              })
              .catch(() => {
                this.isLoading = false;
              });
          }

          if (
            ["to_sign", "to_doctor_confirm_plan", "to_approve_plan"].includes(
              node
            )
          ) {
            casesDetail(caseId).then(res => {
              const { cureType } = { ...res };
              if (cureType === "F") {
                window.open(
                  `/exocad?schemeType=cure&cureNoteId=${cureNoteId}&caseId=${caseId}&curePlanId=${curePlanId ||
                    ""}`
                );
              } else {
                window.open(
                  `/cases/view3d?schemeType=cure&cureNoteId=${cureNoteId}&caseId=${caseId}&curePlanId=${curePlanId ||
                    ""}`
                );
              }
            });
          }
          if (node === "to_text_plan") {
            window.open(
              `/cases/view3d?schemeType=target&cureNoteId=${cureNoteId}&` +
                query
            );
          }
          if (node === "to_receive") {
            window.open(
              "/cases/detail?delivery=1&" +
                `caseId=${caseId}&curePlanId=${curePlanId || ""}`
            );
          }
          if (
            [
              "to_submit",
              "to_data_approve",
              "precuring",
              "to_scan",
              "to_agent_scan",
              "to_check_order",
              "to_recheck_order",
              "to_scan_y",
              "to_rescan_y",
              "to_improve_plan"
            ].includes(node)
          ) {
            casesDetail(caseId).then(res => {
              const purePlanInfo = res["curePlanInfo"];
              if (purePlanInfo.phaseAdjustmentNumber !== 0) {
                window.open(`/stage-adjustment` + query);
              } else {
                const href = "/cases/create";
                let query = `?caseId=${caseId}&curePlanId=${curePlanId}`;
                if (node === "to_submit") {
                  query += `&submitTabKey=0`;
                }
                localStorage.setItem("casesTabKey", "0")
                window.open(href + query, "_blank");
              }
            });
          }
          if (node === "to_draw_line" && status === "to_handle") {
            window.open(
              "/cases/maintain?" + `caseId=${caseId}&curePlanId=${curePlanId}`,
              "_blank"
            );
          }
          if (node === "reject") {
            console.log(item);
            this.whyObj.tipText = splitText(item.contentDetail);
            this.removeId = id;
            this.isShowWhy = true;
            // this.listIndex = index;
          }
        }
      }
    },
    whyHide() {
      this.isShowWhy = false;
    },
    whySubmit() {
      caseChatHandle(this.removeId).then(() => {
        this.isShowWhy = false;
      });
    },
    toSearchCases() {
      if (!this.conditionForQuery) {
        sessionStorage.removeItem("conditionForQuery");
      } else {
        sessionStorage.setItem("conditionForQuery", this.conditionForQuery);
      }
      this.HEAD_SEARCH(this.conditionForQuery);
      this.goPage("/cases");
    },
    selectedMenu(ev) {
      this.conditionForQuery = "";
      const target = ev.target;
      const URL = target.getAttribute("data-url");
      if (!URL) return;
      sessionStorage.setItem("menuUrl", URL);
      sessionStorage.removeItem("selectedKey");
      sessionStorage.removeItem("conditionForQuery");
      removeCreate();
      let route = this.$route;
      if (URL === "/helpCenter") {
        this.$router.replace({
          name: "helpCenter",
          query: extend({}, { type: "14" })
        });
      } else {
        this.$router.replace({ path: URL });
      }
      this.GET_MENU_URL(URL);
    },
    loginOut() {
      setTimeout(() => {
        clearUser();
        this.$router.push("/login");
      }, 200);
    },
    goPage(url) {
      if (url) {
        this.caseMessagePage();
        this.$router.push({
          path: url
        });
      }
    },
    setLocalClinicalRrefReminder() {
      localStorage.setItem("clinicalPrefReminder", "0");
      this.showDropDown = false;
      this.showTour = false;
    },
    noReminder() {
      closeClinicalPrefReminder().then(() => {
        notifyMsg(
          this,
          "success",
          this.$t("casesDetail.casesChildren.common.czcg")
        );
        this.setLocalClinicalRrefReminder();
      });
    },
    toSetClinic() {
      this.goPage("/cases/clinicalPreferences");
      this.setLocalClinicalRrefReminder();
    },
    getUserInfo() {
      getUser().then(data => {
        this.userMsg = data;
        this.userPhoto = data["photo"];
        localStorage.setItem("userName", data.loginName);
        const localClinicalReminder = localStorage.getItem(
          "clinicalPrefReminder"
        );
        if (localClinicalReminder !== null) {
          if (localClinicalReminder === "0") return;
          if (data.clinicalPrefReminder === "1") {
            this.showDropDown = true;
            this.showTour = true;
          }
        } else {
          if (data.clinicalPrefReminder === "1") {
            localStorage.setItem(
              "clinicalPrefReminder",
              data.clinicalPrefReminder
            );
            this.showDropDown = true;
            this.showTour = true;
          }
        }
      });
    },
    ...mapMutations([
      "GET_LOGIN_STATUS",
      "GET_MENU_URL",
      "HANDLE_MSG",
      "HEAD_SEARCH"
    ]),
    ...mapActions(["getUserMsg"])
  },
  created() {
    this.pathUrl = window.location.pathname;
    const URL = sessionStorage.getItem("menuUrl") || "";
    if (URL) {
      this.GET_MENU_URL(URL);
    }
    this.caseMessagePage();
    getDictList("case.next_status").then(data => {
      const obj = {};
      data.forEach(item => {
        obj[item.key] = item.value;
      });
      this.nextStatusObj = obj;
    });
    const cityData = sessionStorage.getItem("cityData") || "";
    if (!cityData) {
      getCityList();
    }
  },
  mounted() {
    if (isLogin()) {
      this.getUserInfo();
    }
  },

  watch: {
    autocompleteValue: {
      handler(val) {
        this.autocompleteLoading = true;
        this.isShowAuto = val;
        if (val) {
          this.getNewCaseList(val, 10);
        } else {
          this.caseList = [];
        }
      },
      deep: true
    },
    userMsg: {
      handler() {
        this.qiniuDomain = window.QINIU_DOMAIN;
      },
      deep: true
    },
    isModifyUserPhoto() {
      this.getUserInfo();
    },
    isHandleMsg() {
      this.caseMessagePage();
    }
  }
};
</script>

<style lang="scss" scoped>
.tour-wrap {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.2);
  z-index: 1001;

  .step-current {
    width: 182px;
    height: 52px;
    position: absolute;
    background-color: #fff;
    top: 120px;
    right: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.01rem;
    font-size: 14px;

    .icon_preference {
      width: 18px;
      height: 18px;
      background-image: url("../../common/imgs/home/icon_preference.png");
      background-size: 100% 100%;
      margin-right: 8px;
    }
  }

  .tour-container {
    position: absolute;
    width: 404px;
    height: 368px;
    right: 230px;
    top: 60px;
    display: flex;
    flex-direction: column;

    .close-tour {
      cursor: pointer;
      width: 24px;
      height: 24px;
      transform: translateX(50%);
      position: absolute;
      right: 0;
      top: 0;
      z-index: 12;
    }

    .tour-header {
      width: 414px;
      height: 141px;
      z-index: 10;
      background-image: url("../../common/imgs/home/tour-header.png");
      background-size: 100% 100%;
    }

    .tour-content {
      flex: 1;
      transform: translateY(-20px);
      border-radius: 0 0 12px 12px;
      background-color: #ffffff;
      padding: 16px;
      display: flex;
      flex-direction: column;
      align-items: space-between;
      justify-content: space-between;

      .step-title {
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        margin: 12px 0 17px;
        color: #333;
      }

      .step-content {
        font-size: 14px;
        line-height: 20px;
        color: #333;
      }

      .step-footer {
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .no-reminder {
          cursor: pointer;
          font-size: 14px;
          line-height: 20px;
          color: $main_theme_color;
        }

        .to-set {
          cursor: pointer;
          min-width: 124px;
          padding: 0 20px;
          height: 36px;
          border-radius: 4px;
          background-color: $main_theme_color;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}

.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  background-color: #38383a;

  .head_con {
    width: 16rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 0.62rem;

    .logo {
      display: block;
      width: 1.2rem !important;
    }

    .nav {
      display: flex;
      color: white;
      align-items: center;

      .top_c_r_right {
        width: 32px;
        height: 32px;
        font-size: 0.12rem;
        position: relative;
        cursor: pointer;

        .user-avatar {
          width: 32px;
          height: 32px;
          border-radius: 50%;
        }

        .drop_down {
          width: 182px;
          height: 227px;
          position: absolute;
          bottom: -0.1rem;
          left: -0.7rem;
          transition: all 0.3s;
          transform: translateY(100%);
          letter-spacing: 0.01rem;
          background-color: #fff;
          border-radius: 0.04rem;
          color: $main_theme_color_333;
          cursor: pointer;
          text-align: center;
          padding-top: 0.1rem;
          font-size: 0.14rem;
          //border: 1px solid #c0c4cc;
          box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.12);

          & > div:nth-child(1) {
            height: 42px;
          }

          div:hover {
            background: #f9f9f9;
            transition: all 0.2s;
            color: $main_theme_color;
          }

          div {
            height: 52px;
            transition: all 0.2s;
            display: flex;
            align-items: center;
            justify-content: center;

            > div {
              width: 16px;
              height: 16px;
              background-size: 100% 100%;
            }

            .icon_user {
              background-image: url("../../common/imgs/home/icon_user.png");
              margin-right: 8px;
            }

            .icon_preference {
              background-image: url("../../common/imgs/home/icon_preference.png");
              margin-right: 8px;
            }

            .icon_out {
              background-image: url("../../common/imgs/home/icon_out.png");
              margin-right: 8px;
            }
            .icon_help {
              background-image: url("../../common/imgs/home/icon_help.png");
              margin-right: 8px;
            }
          }

          .help_wrap:hover .icon_help {
            background-image: url("../../common/imgs/home/icon_help_y.png") !important;
          }

          .user_wrap:hover .icon_user {
            background-image: url("../../common/imgs/home/icon_uesr_y.png") !important;
          }

          .preference_wrap:hover .icon_preference {
            background-image: url("../../common/imgs/home/icon_preference_y.png");
          }

          .out_wrap:hover .icon_out {
            background-image: url("../../common/imgs/home/icon_out_y.png");
          }

          .top_arrows_user {
            display: block;
            width: 0.2rem;
            height: 0.1rem;
            background: url("/static/images/header/jiantou.png");
            position: absolute;
            left: 0.76rem;
            top: -0.09rem;
          }

          &:hover {
            opacity: 1;
          }

          i {
            display: inline-block;
            width: 0.1rem;
            height: 0.1rem;
            position: absolute;
            top: -4%;
            left: 40%;
            background-color: #fff;
            transform: rotate(45deg);
            z-index: 1;
          }
        }
      }

      & > li:nth-child(1) {
        padding: 0 0.3rem 0;
        font-size: 0.2rem;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        margin-right: 40px;

        &.border::after {
          content: "";
          width: 100%;
          height: 0.06rem;
          background-color: $main_theme_color;
          position: absolute;
          top: -0.22rem;
          left: 0;
          border-radius: 0 0 5px 5px;
        }
      }

      & > li:nth-child(2) {
        padding: 0 0.3rem 0;
        font-size: 0.2rem;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        margin-right: 40px;

        &.border::after {
          content: "";
          width: 100%;
          height: 0.06rem;
          background-color: $main_theme_color;
          position: absolute;
          top: -0.22rem;
          left: 0;
          border-radius: 0 0 5px 5px;
        }
      }

      & > li:nth-child(3) {
        padding: 0 0.3rem 0;
        font-size: 0.2rem;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;

        &.border::after {
          content: "";
          width: 100%;
          height: 0.06rem;
          background-color: $main_theme_color;
          position: absolute;
          top: -0.22rem;
          left: 0;
          border-radius: 0 0 5px 5px;
        }
      }

      & > li:nth-child(4) {
        padding: 0 0.3rem 0;
        font-size: 0.2rem;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;

        &.border::after {
          content: "";
          width: 100%;
          height: 0.06rem;
          background-color: $main_theme_color;
          position: absolute;
          top: -0.22rem;
          left: 0;
          border-radius: 0 0 5px 5px;
        }
      }

      .li_active {
        color: $main_theme_color;
      }

      .h_right {
        height: 0.34rem;
        width: 3.18rem;
        //line-height: 0.38rem;
        //background-color: white;
        color: $main_theme_color_333;
        display: flex;
        padding: 0 0.1rem 0 0.13rem;
        border-radius: 0.04rem;
        align-items: center;
        //margin-left: 2.2rem;
        position: relative;
        .case_autocomplete {
          position: absolute;
          top: 40px;
          width: 336px;
          height: 368px;
          background-color: #fff;
          border-radius: 4px;
          box-shadow: 0px 0px 8px 0px rgba(51, 51, 51, 0.12);
          //border: 1px solid #e5e5e5;
          overflow: hidden;

          .mark_loading {
            position: absolute;
            width: 336px;
            height: 368px;
            border-radius: 4px;
            z-index: 9999;
            text-align: center;
            font-size: 30px;
            line-height: 352px;
            background-color: rgba(0, 0, 0, 0.04);
            color: #999;
          }
          > ul {
            padding-top: 8px;
            overflow: auto;
            height: 100%;
            .auto_item_li {
              font-size: 14px;
              line-height: 20px;
              color: #666;
              padding: 12px;
              box-sizing: border-box;
              transition: 0.2s all;
              min-height: 88px;

              .auto_item_top,
              .auto_item_bot {
                display: flex;
                align-items: center;
                justify-content: space-between;
                justify-items: center;
              }
              //.auto_item_top {
              //  height: 40px !important;
              //}
              .auto_item_bot {
                margin-left: 48px;
                font-size: 12px;
                color: #666666;
              }
            }
            .auto_item_li:hover {
              background: #f9f9f9;
              transition: 0.2s all;
            }
          }
        }
        input {
          padding-right: 20%;
          line-height: 0.38rem;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          flex: 1;
        }

        .search_icon {
          display: flex;
          border-radius: 0 0.05rem 0.05rem 0;
          background-color: $main_theme_color;
          width: 0.4rem;
          //height: 100%;
          background-size: 100% 100%;
          cursor: pointer;
          position: absolute;
          right: 30px;
          justify-content: center;
          align-items: center;
          height: 36px;

          img {
            display: block;
            width: 0.24rem;
            height: 0.24rem;
          }
        }
      }

      .head_top_con_r {
        display: flex;
        align-items: center;
        margin-left: 0.4rem;

        .top_c_r_left {
          display: flex;
          align-items: center;

          span {
            display: inline-block;
            width: 0.32rem;
            height: 0.32rem;
            background-size: 100% 100%;
            border-radius: 100%;
          }

          .r_left_icon01 {
            cursor: pointer;
            width: 0.32rem;
            height: 0.32rem;
            background-image: url("../../common/imgs/home/header_yw.png");
          }

          .r_left__icon01 {
            width: 0.32rem;
            height: 0.32rem;
            cursor: pointer;
            background-image: url("../../common/imgs/home/header_zw.png");
          }

          .icon02_box {
            width: 0.32rem;
            position: relative;
            height: 0.62rem;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 32px;
              height: 32px;
            }

            &:hover .mg_box {
              opacity: 1;
              transition: all 0.2s;
              display: inline-block;
            }

            &:hover .not_message {
              margin-top: 100px;
            }

            .tag_b {
              position: absolute;
              right: 0.1rem;
              top: 0.15rem;
              color: white;
              font-size: 0.12rem;
              display: flex;
              align-items: center;
              justify-content: center;
              transform: translateX(80%);
            }
          }

          .mg_box {
            transition: all 0.2s;
            position: absolute;
            width: 4.4rem;
            height: 374px;
            background-color: #fff;
            bottom: 0;
            left: -3.6rem;
            transform: translateY(100%);
            padding: 0.14rem 0 0;
            color: #333;
            font-size: 0.16rem;
            border-radius: 0.05rem;
            box-shadow: 0 0 0.06rem 0 rgba(164, 164, 164, 0.28);
            display: none;

            .top_arrows {
              display: block;
              width: 0.2rem;
              height: 0.1rem;
              background: url("/static/images/header/jiantou.png");
              position: absolute;
              right: 0.53rem;
              top: -0.09rem;
            }

            .msg_single {
              padding: 16px 20px;
              height: 102px;
              border-bottom: 0.01rem solid #e5e5e5;
              box-sizing: border-box;
              position: relative;

              .click-mok {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 9;
                cursor: pointer;
              }

              .msg_content {
                line-height: 0.22rem;
                color: $main_theme_color_333;
                font-size: 0.14rem;
              }

              .msg_u_box {
                margin-top: 14px;
                display: flex;
                align-items: center;
                justify-content: space-between;
              }

              .u_b_left {
                display: flex;
                align-items: center;

                .u__l_pic {
                  min-width: 0.44rem;
                  width: 0.44rem;
                  height: 0.44rem;
                  border-radius: 100%;
                  margin-right: 0.12rem;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }

                .u__l_r_p {
                  color: $main_theme_color_999;
                  font-size: 0.12rem;
                }
              }

              .u_b_right {
                color: $main_theme_color;
                cursor: pointer !important;
                font-size: 14px;
                display: flex;
                align-items: center;

                img {
                  display: block;
                  width: 14px;
                  height: 14px;
                  margin-left: 4px;
                }
              }
            }

            .look_all_btn {
              width: 96px;
              height: 36px;
              background: $main_theme_color;
              border-radius: 4px;
              color: #fff;
            }
          }

          .more_box {
            height: 0.48rem;
            line-height: 0.48rem;
            text-align: center;
            cursor: pointer;
            color: $main_theme_color;
            background-color: #f2f2f2;
            box-shadow: 0 0 0.1rem 0 rgba(164, 164, 164, 0.28);
          }
        }
      }
    }
  }
}

.header_home {
  top: 90px !important;
}

.expressage_header_tabs_total_main {
  height: 14px;
  background: #ed4027;
  border-radius: 7px;
  font-size: 12px;
  color: white;
  //padding-left: 4px;
  text-align: center;
}

.expressage_header_tabs_total {
  width: 14px;
}

.expressage_header_tabs_total_two {
  width: 22px;
}

.expressage_header_tabs_total_three {
  width: 28px;
}
/* 修改 el-autocomplete 的输入框高度 */
::v-deep .el-autocomplete input {
  width: 318px;
  height: 36px; /* 调整输入框的高度 */
  padding: 0 10px; /* 调整输入框内边距 */
  font-size: 14px; /* 设置字体大小 */
}

/* 修改下拉框的高度 */
::v-deep .el-autocomplete-suggestion {
  max-height: 200px; /* 设置最大高度 */
  overflow-y: auto; /* 超出部分显示滚动条 */
  font-size: 14px; /* 调整下拉框内文本的字体大小 */
}

/* 可选：修改输入框和按钮之间的间距 */
::v-deep .el-autocomplete .el-input__suffix {
  margin-top: 0; /* 移除按钮和输入框之间的间距 */
  height: 100%;
}

/* 可选：修改下拉框每一项的高度 */
::v-deep .el-autocomplete-suggestion__list li {
  height: 36px; /* 调整每个列表项的高度 */
  line-height: 40px; /* 调整文本的垂直居中 */
}
/* 修改按钮的宽度为 36px */
::v-deep .el-autocomplete .el-input-group.el-input-group__append .el-button {
  width: 36px !important; /* 设置按钮的宽度为 36px */
  height: 36px !important; /* 设置按钮的高度为 36px，保持按钮的形状 */
  padding: 0 !important; /* 移除按钮内的默认内边距 */
  font-size: 18px !important; /* 调整图标的大小，使其适应按钮 */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
/* 自定义 el-autocomplete 输入框的高度 */
::v-deep .autocomplete_input .el-input__inner {
  height: 36px; /* 您想要的高度 */
  line-height: 36px; /* 保持垂直居中 */
  width: 282px !important;
}

/* 自定义 el-autocomplete 建议列表项的 padding */
::v-deep
  .autocomplete_case
  .el-autocomplete-suggestion__list
  .el-autocomplete-suggestion__item {
  padding: 0 !important; /* 使用 !important 来确保覆盖 */
}

.default_case_page {
  padding-top: 100px;
  position: absolute;
  width: 336px;
  height: 368px;
  border-radius: 4px;
  z-index: 99;

  .default_case_icon {
    margin: auto;
    width: 120px;
    height: 90px;
    background-image: url("../../common/imgs/case-list/default_case_icon.png");
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    > div {
      width: 100%;
      text-align: center;
      position: absolute;
      bottom: -20px;
      color: #999999;
      font-size: 14px;
    }
  }
}
::v-deep .el-avatar {
  img {
    display: inline-block;
    margin: auto;
    width: 44px !important;
  }
}
</style>
