<template>
  <div
    class="mail_model"
    v-loading="clinic.state === 'loading' || detail.loading"
  >
    <div class="fix_top">
      <div
        :class="[
          caseDetail['selfDeliverFlag'] == 1 ? 'how_use' : 'how_use_fix',
          'flex-y-c'
        ]"
      >
        <div class="title" v-if="caseDetail['selfDeliverFlag'] == 1">
          {{ $t("birdie.djsmx") }}
        </div>
        <div class="flex-y-c">
          <img
            src="../../../common/imgs/expressage_birdie/how_use.png"
            alt=""
          />
          <p @click="jumpHowUse" class="ml8">{{ $t("birdie.rhsy") }} ?</p>
        </div>
      </div>
      <p class="mail_model_init">{{ $t("birdie.qwbbz") }}</p>
      <p
        :class="[
          caseDetail['selfDeliverFlag'] == 1
            ? 'placed_order'
            : 'placed_order_fix',
          'curp'
        ]"
        @click="
          jumpPage({
            indexType: 'place'
          })
        "
      >
        {{ $t("birdie.yxdmx") }}
      </p>
    </div>
    <div class="mail_model_content">
      <p class="mail_model_content_title">{{ $t("birdie.xzjg") }}：</p>
      <div class="flex-y-c select_wrap">
        <el-select
          ref="organizationSelect"
          type="text"
          @change="clinicChange"
          class="select_width"
          v-model="value"
          :placeholder="$t('casesDetail.casesChildren.common.qxz')"
        >
          <el-option
            v-for="item in clinic.list"
            :key="item.id"
            :label="item.clinicName"
            :value="item.id"
          >
            <div class="select_item">
              <div style="float: left">{{ item.clinicName }}</div>
              <div
                v-if="item.caseSendAddressNum"
                :class="{
                  right_init_two: +item.caseSendAddressNum > 9,
                  right_init_three: +item.caseSendAddressNum >= 99
                }"
                class="right_init flex-x-y-c"
              >
                {{ item.caseSendAddressNum }}
                {{ item.caseSendAddressNum >= 99 ? "+" : "" }}
              </div>
            </div>
          </el-option>
        </el-select>
        <!--<div class="flex-y-c mr20">-->
        <!--<img @click="jumpPage({-->
        <!--indexType: 'code'-->
        <!--})" class="two_code" src="../../../common/imgs/expressage_birdie/two_code.png" alt="">-->
        <!--<p @click="jumpPage({-->
        <!--indexType: 'code'-->
        <!--})" class="two_code_text">{{$t('birdie.jgewm')}}</p>-->
        <!--</div>-->
      </div>
      <div class="wire"></div>
      <div class="mail_model_content_site">
        <el-scrollbar style="height: 100%">
          <p class="mail_model_content_site_title">
            {{ $t("birdie.qxzjjdz") }}：
          </p>
          <div class="mail_model_content_site_header flex-y-c">
            <div class="mail_model_content_site_header_init"></div>
            <p class="mail_model_content_site_header_text">
              {{
                detail.refType === "CLINIC"
                  ? $t("birdie.dqjgdz")
                  : $t("birdie.dqgrdz")
              }}
            </p>
          </div>
          <div
            @click="
              jumpPage({
                indexType: 'index',
                addClinicId: currentItem.clinicId
              })
            "
            class="mail_location"
          >
            <div class="mail_location_header flex-y-c">
              <p class="mail_location_header_provinces">
                {{ detail.provinceName }}-{{ detail.cityName }}-{{
                  detail.areaName
                }}
              </p>
              <p class="mail_location_header_number">
                {{
                  detail.contactWay == "2"
                    ? detail.phoneArea + " - " + detail.phone
                    : detail.phone
                }}
              </p>
            </div>
            <p class="mail_location_detail">{{ detail.address }}</p>
            <img
              class="arrows_right"
              src="../../../common/imgs/expressage_birdie/arrows_right.png"
              alt=""
            />
          </div>
          <p v-if="detail.refType === 'CLINIC'" class="mail_location_init">
            {{ $t("birdie.gtxd") }}
          </p>
          <p
            :class="{ fz14: language === 'en_US' }"
            v-if="detail.otherCaseSendAddressNum > 0"
            class="mail_location_init_red mt2"
          >
            {{ $t("birdie.yjg") }} {{ detail.otherCaseSendAddressNum }}
            {{
              detail.refType === "CLINIC"
                ? $t("birdie.yng")
                : $t("birdie.yjgdz")
            }}
          </p>
          <div class="wire"></div>
          <div class="table_wrap">
            <div class="fz14 all_select">{{ $t("birdie.qx") }}</div>
            <el-table
              :empty-text="$t('birdie.dqjgzwmx')"
              ref="multipleTable"
              :data="detail.caseSendAddressList"
              tooltip-effect="dark"
              style="width: 100%"
              @selection-change="handleSelectionChange"
            >
              <el-table-column type="selection" width="60"></el-table-column>
              <el-table-column show-overflow-tooltip width="50">
              </el-table-column>
              <el-table-column
                prop="userName"
                show-overflow-tooltip
                :label="$t('birdie.xm')"
                width="160"
              >
                <template slot-scope="scope">
                  <div class="flex-y-c">
                    <div class="w100 txt-ellipsis">
                      {{ scope.row.userName }}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="caseNumber"
                :label="$t('casesDetail.casesChildren.casesDone.blh')"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column label="" show-overflow-tooltip>
                <template slot-scope="scope">
                  <div>
                    {{
                      scope.row.type !== "0"
                        ? modelTypeRender[scope.row.type]
                        : ""
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="" show-overflow-tooltip>
                <template slot-scope="scope">
                  <div @click="deleteMail(scope.row)" class="delete_icon"></div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-scrollbar>
      </div>

      <div
        v-if="
          detail.caseSendAddressList && detail.caseSendAddressList.length > 0
        "
        class="mail_model_content_footer"
      >
        {{ $t("birdie.qgxbl") }}
      </div>
    </div>
    <div
      class="add_btn flex-x-y-c curp"
      @click="
        jumpPage({
          indexType: 'addSend',
          addClinicId: addClinicId,
          addAddressId: detail.id
        })
      "
    >
      {{ $t("birdie.jxtj") }}
    </div>
    <div
      :class="{ place_order_dis: addressListLength < 1 }"
      class="place_order flex-x-y-c curp"
      @click="placeOrder"
    >
      {{ $t("birdie.xd") }}
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import { extend, map, isArray, find, get } from "lodash";
import {
  clinicDoctorList,
  PublicSendAddressDetail,
  PublicCaseSendAddressRemove,
  PublicPickupRegionalCheck,
  PublicPickUpOrderCheck
} from "common/api/cases";
import { PIC_PREFIX } from "common/js/config";
import { notifyMsg } from "common/js/util";

export default {
  data() {
    return {
      value: "",
      PIC_PREFIX,
      clinic: {
        list: [],
        state: ""
      },
      tableList: [],
      detail: {
        loading: false
      },
      currentItem: "",
      ids: [],
      modelTypeRender: {
        "1": this.$t("stageAdjust.jjytmx"),
        "2": this.$t("stageAdjust.wcblmx"),
        "3": this.$t("stageAdjust.qtmx")
      },
      resetId: ""
    };
  },
  computed: {
    ...mapState({
      language: state => state.language
    }),
    ...mapGetters({}),
    addClinicId() {
      return find(this.clinic.list, item => item.id === this.value).clinicId;
    },
    addressListLength() {
      return (
        (this.detail.caseSendAddressList &&
          this.detail.caseSendAddressList.length) ||
        0
      );
    },
    addClinicName() {
      return get(
        find(this.clinic.list, item => item.id === this.value),
        "clinicName"
      );
    },
    selectVisible() {
      return this.$refs["organizationSelect"].visible;
    }
  },
  props: {
    bindClinic: {
      type: String || undefined
    },
    isReissueFlag: {
      default: false
    },
    caseDetail: {
      type: Object,
      default: () => ({})
    }
  },

  methods: {
    ...mapActions({}),
    isArray,
    jumpHowUse() {
      window.open("http://webview.aismile.cn/howUse.html");
    },
    placeOrder() {
      PublicPickupRegionalCheck({
        orderList: this.ids,
        clinicId: this.addClinicId,
        sendAddressId: this.detail.id
      }).then(res => {
        // sessionStorage.setItem("regionalCheckParams", JSON.stringify(res));
        // sessionStorage.setItem("orderList", JSON.stringify(this.ids))
        sessionStorage.setItem(
          "regionalCheckParams",
          JSON.stringify({
            result: res,
            clinicId: this.addClinicId,
            orderList: this.ids
          })
        );
        this.jumpPage({
          indexType: "confirm"
        });
        this.detail.loading = false;
      });
    },
    jumpPage(arg) {
      let route = this.$route;
      this.$router.push({
        path: route.path,
        query: extend({}, route.query, arg)
      });
    },
    handleSelectionChange(list) {
      let result = [];

      map(list?.filter(Boolean), item => {
        let obj = {
          caseId: item?.caseId,
          type: item?.type
        };
        if (item.type === "1") {
          obj["reissueId"] = item.reissueId;
        }
        result.push(obj);
      });
      this.ids = result;
    },
    clinicChange(id) {
      this.$refs["organizationSelect"].blur();
      let obj = find(this.clinic.list, item => id === item.id);
      this.currentItem = obj;
      let { sendAddressId, clinicId } = obj;
      this.getLocationDetail(sendAddressId, clinicId);
    },
    getLocationDetail(id, clinicId) {
      if (this.detail.loading === true) return true;
      this.detail.loading = true;
      PublicSendAddressDetail({ id: id, clinicId: clinicId }).then(data => {
        this.detail = extend({}, data, { loading: false });
        let caseId = this.$route.query.caseId;
        if (caseId) {
          let item;
          if (this.isReissueFlag) {
            item = find(
              data.caseSendAddressList,
              it => +caseId === +it.caseId && it.type == "1"
            );
          } else {
            item = find(data.caseSendAddressList, it => +caseId === +it.caseId);
          }
          let obj = {
            caseId: item?.caseId,
            type: item?.type
          };
          if (this.isReissueFlag) {
            obj.reissueId = item.reissueId;
          }
          this.ids = [obj];
          this.$nextTick(() => {
            this.$refs["multipleTable"]?.toggleRowSelection(item, true);
          });
        }
      });
    },
    getClinicList() {
      if (this.clinic.state === "loading") return;
      this.clinic.state = "loading";
      clinicDoctorList({
        doctorId: localStorage.getItem("userId"),
        status: "1",
        pageType: "deliver_model"
      })
        .then(data => {
          this.clinic.state = "loadend";
          this.clinic.list = data;
          if (isArray(data) && data.length) {
            this.currentItem = this.resetId
              ? find(this.clinic.list, item => +item.clinicId === +this.resetId)
              : data[0];
            this.value = this.currentItem.id;
            this.getLocationDetail(
              this.$route.query.relevanceId ||
                this.currentItem.sendAddressId ||
                this.bindClinic,
              this.currentItem.clinicId
            );
          }
        })
        .catch(() => {
          this.clinic.state = "loaderr";
        });
    },
    resetClinic(id) {
      this.jumpPage({
        resetId: this.$route.query.resetId ? this.$route.query.resetId : id
      });
      this.resetId = this.$route.query.resetId || id;
      this.getClinicList();
    },
    deleteMail(val) {
      this.$msgbox({
        title: this.$t("birdie.ts"),
        message: this.$t("cases.cases.qrsc"),
        showCancelButton: true,
        confirmButtonText: this.$t("common.common.qd"),
        cancelButtonText: this.$t("common.common.qx"),
        cancelButtonClass: "confirm_cancel_button main_theme_color_333",
        confirmButtonClass: "hvr-bc hvr-bd",
        center: true
      }).then(() => {
        PublicCaseSendAddressRemove({
          caseId: val.caseId,
          type: val.type
        }).then(() => {
          notifyMsg(
            this,
            "success",
            this.$t("casesDetail.casesChildren.common.czcg")
          );
          this.resetClinic();
        });
      });
    }
  },

  created() {
    this.$emit("getDiaTitle", this.$t("birdie.djsmx"));
    this.resetClinic();
  }
};
</script>

<style lang="scss" scoped>
.confirm_cancel_button {
  color: #333333 !important;
}

.mail_model {
  height: calc(100% - 58px);
  padding: 20px 30px;
  position: relative;
  padding-top: 0;

  .how_use {
    .title {
      font-size: 0.18rem;
      line-height: 26px;
      margin-right: 20px;
    }

    cursor: pointer;
    color: #333333;
    font-size: 12px;
  }

  .how_use_fix {
    position: absolute;
    left: 164px;
    top: -0.26rem;
    cursor: pointer;
    color: #333333;
    font-size: 12px;
  }

  .placed_order {
    color: #007aff;
    font-size: 14px;
    position: absolute;
    top: 6px;
    right: 120px;
  }

  .placed_order_fix {
    color: #007aff;
    font-size: 14px;
    position: absolute;
    top: -0.26rem;
    right: 120px;
  }

  .mail_model_init {
    width: 90%;
    color: #ed4027;
    font-size: 14px;
    margin-top: 10px;
  }

  .mail_model_content {
    width: 100%;
    height: 580px;
    border-radius: 8px;
    box-shadow: 0 0 8px 0 rgba(51, 51, 51, 0.12);
    background: #ffffff;
    margin-top: 10px;
    padding: 10px 0 10px 20px;
    position: relative;

    .select_wrap {
      width: 100%;
      position: relative;
      justify-content: space-between;

      .two_code {
        cursor: pointer;
        margin-left: 60px;
        position: relative;
      }

      .two_code_text {
        cursor: pointer;
        height: 22px;
        font-size: 16px;
        color: $main_theme_color;
        line-height: 22px;
        text-shadow: 0 0 8px rgba(51, 51, 51, 0.12);
      }
    }

    .mail_model_content_title {
      height: 22px;
      font-size: 16px;
      color: #666666;
      line-height: 22px;
      text-shadow: 0 0 8px rgba(51, 51, 51, 0.12);
    }

    .arrows {
      cursor: pointer;
      position: absolute;
      right: 36px;
      transition: all 0.2s;
    }

    .arrows_top {
      transform: rotate(180deg);
      transition: all 0.2s;
    }

    .select_width {
      width: 45%;
      display: inline-block; //这个看情况加
      white-space: nowrap; //必须
      overflow: hidden; //必须
      text-overflow: ellipsis; //必须
    }

    .wire {
      height: 1px;
      background: #e1e1e1;
      box-shadow: 0 0 8px 0 rgba(51, 51, 51, 0.12);
      border-radius: 1px;
      margin-right: 20px;
      margin-top: 10px;
    }

    .mail_model_content_site {
      width: 100%;
      height: 75%;
      margin-top: 0.19rem;

      .mail_model_content_site_title {
        font-size: 16px;
        color: #666666;
        line-height: 22px;
        text-shadow: 0 0 8px rgba(51, 51, 51, 0.12);
      }

      .mail_model_content_site_header {
        margin-top: 8px;

        .mail_model_content_site_header_init {
          width: 4px;
          height: 22px;
          background: $main_theme_color;
          box-shadow: 0 0 8px 0 rgba(51, 51, 51, 0.12);
          margin-right: 10px;
        }

        .mail_model_content_site_header_text {
          font-size: 14px;
          color: #333333;
          line-height: 22px;
          text-shadow: 0 0 8px rgba(51, 51, 51, 0.12);
        }
      }
    }

    .mail_location {
      width: 100%;
      height: 66px;
      border-radius: 4px;
      margin-top: 8px;
      padding: 8px 16px;
      transition: all 0.2s;
      position: relative;
      cursor: pointer;

      .arrows_right {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }

      .mail_location_header {
        color: #333333;
        font-size: 14px;
        height: 20px;
        line-height: 20px;

        .mail_location_header_provinces {
          width: 1.5rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 30px;
        }

        .mail_location_header_number {
          width: 1rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 30px;
        }
      }

      .mail_location_detail {
        width: 98%;
        height: 22px;
        line-height: 22px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 16px;
        color: #333333;
        text-shadow: 0 0 8px rgba(51, 51, 51, 0.12);
        margin-top: 8px;
      }
    }

    .mail_location:hover {
      background: #f4f4f4;
      transition: all 0.2s;
    }

    .mail_location_init {
      margin-top: 8px;
      height: 16px;
      font-size: 12px;
      color: #666666;
      line-height: 16px;
      text-shadow: 0 0 8px rgba(51, 51, 51, 0.12);
    }

    .mail_location_init_red {
      font-size: 12px;
      line-height: 16px;
      text-shadow: 0 0 8px rgba(51, 51, 51, 0.12);
      color: #ed4027;
    }

    .table_wrap {
      margin-right: 20px;
      position: relative;

      .all_select {
        position: absolute;
        left: 40px;
        top: 17px;
        z-index: 999;
        color: #333333;
      }
    }

    .mail_model_content_footer {
      width: 100%;
      height: 32px;
      box-shadow: 0px -1px 0px 0px #e1e1e1;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 8px 16px;
      color: #666666;
      font-size: 12px;
      display: flex;
      align-items: center;
    }
  }

  .add_btn {
    width: 216px;
    border: 1px solid #bbbbbb;
    border-radius: 6px;
    background: #ffffff;
    height: 42px;
    position: absolute;
    left: 60px;
    margin-top: 20px;
    color: #333333;
    transition: all 0.2s;
    font-size: 16px;
  }

  .add_btn:hover {
    color: $main_theme_color !important;
    border: 1px solid $main_theme_color !important;
    transition: all 0.2s;
  }

  .place_order {
    overflow: hidden;
    width: 216px;
    height: 42px;
    background: $main_theme_color;
    border-radius: 6px;
    position: absolute;
    margin-top: 20px;
    right: 60px;
    font-size: 16px;
    color: #333333;
  }

  .place_order_dis {
    background: #e5e5e5 !important;
    cursor: auto;
  }
}

.delete_icon {
  position: absolute;
  width: 0.24rem;
  height: 0.24rem;
  cursor: pointer;
  left: 50%;
  top: 15px;
  transform: translateX(-50%);
  background-size: 100% 100%;
  background-image: url("../../../common/imgs/delete_icon.png");

  &:hover {
    background-image: url("../../../common/imgs/delete_icon_y.png");
  }
}

/deep/ .el-table::before {
  display: none;
}

/deep/ .el-input__inner {
  border: none;
  padding-left: 0 !important;
  color: #333333 !important;
  text-shadow: 0 0 8px rgba(51, 51, 51, 0.12);
  font-size: 16px !important;
  font-weight: 500;
}

/deep/ .el-select .el-input.is-focus .el-input__inner {
  border: none !important;
}

/deep/ .el-select .el-input .el-select__caret {
  font-size: 0.16rem !important;
}

/deep/ .el-scrollbar__wrap {
  overflow-x: hidden;
}

/deep/ .el-table__body-wrapper {
  min-height: 1.35rem;
}

/deep/ .el-table__empty-text {
  margin-top: 0.4rem;
}

/deep/ .el-table td,
.el-table th.is-leaf {
  border-bottom: none;
}

/deep/ .has-gutter .el-checkbox {
  margin-left: 0.04rem;
}

/deep/ .el-checkbox__inner:hover {
  border-color: $main_theme_color;
}

/deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  border-color: $main_theme_color;
  background-color: $main_theme_color;
}

.select_item {
  .right_init {
    color: white !important;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #ed4027;
    margin-top: 0.1rem;
    float: right;
    margin-left: 48px;
    font-size: 12px !important;
  }

  .right_init_two {
    border-radius: 7px !important;
    width: 21px !important;
  }

  .right_init_three {
    width: 28px !important;
    border-radius: 7px !important;
  }
}
</style>
