<template>
  <div class="address-edit">
    <el-dialog
      class="address-edit-dia"
      :destroy-on-close="true"
      width="588px"
      :visible="visible"
      center
      :show-close="false"
      @close="cancel"
    >
      <div class="dia-header">
        <span>{{ isEdit ? $t("yssh.bjdz") : $t("yssh.xzdz") }}</span>
        <img
          @click.stop="cancel"
          class="close-dia"
          src="../../common/imgs/expressage_birdie/dia_close.png"
          alt=""
        />
      </div>
      <div class="dia-content">
        <el-form
          v-loading="loading"
          :model="form"
          status-icon
          :rules="rules"
          ref="Form"
          :label-width="language === 'en_US' ? '140px' : '105px'"
        >
          <el-form-item :label="$t('birdie.xm')" prop="sender">
            <el-input
              :placeholder="$t('birdie.qtxlxr')"
              class="input_width"
              v-model="form.sender"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('birdie.lxdh')"
            :prop="form.contactWay === '1' ? 'phone' : 'phone_'"
          >
            <el-select
              class="input_width"
              v-model="form.contactWay"
              @change="optionsChange"
              :placeholder="$t('login.retrievePaw.sjh')"
            >
              <el-option
                v-for="item in iphoneTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <div class="flex-y-c">
              <div
                :style="{
                  position: 'absolute',
                  left: language === 'en_US' ? '-120px' : '-82px',
                  top: 0,
                  zIndex: 999
                }"
                class="c-red"
              >
                *
              </div>
              <el-input
                v-show="form.contactWay === '1'"
                :placeholder="$t('birdie.qtxsjh')"
                class="input_width mt16"
                v-model="form.phone"
                autocomplete="off"
              ></el-input>
            </div>
            <div v-show="form.contactWay === '2'" class="flex-y-c">
              <el-form :model="form" class="flex-y-c">
                <div
                  :style="{
                    position: 'absolute',
                    left: language === 'en_US' ? '-120px' : '-82px',
                    top: 0,
                    zIndex: 999
                  }"
                  class="c-red"
                >
                  *
                </div>
                <el-form-item prop="phoneArea" style="margin-bottom: 0">
                  <el-input
                    :placeholder="$t('birdie.qh')"
                    class="qh_input_width mt16"
                    v-model="form.phoneArea"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <div class="iphone_wire"></div>
                <el-form-item
                  prop="phone"
                  class="mt16 ml18"
                  style="margin-bottom: 0"
                >
                  <el-input
                    :placeholder="$t('birdie.dhhm')"
                    class="input_width_two"
                    v-model="form.phone"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </el-form-item>
          <el-form-item :label="$t('birdie.xzdq')" prop="provinces">
            <el-cascader
              :props="{
                value: 'id',
                label: 'name',
                children: 'sub'
              }"
              :placeholder="$t('birdie.qxzdq')"
              class="input_width"
              v-model="form.provinces"
              :options="cityData"
            ></el-cascader>
          </el-form-item>
          <el-form-item :label="$t('cases.createUser.xxdz')" prop="address">
            <el-input
              :placeholder="$t('birdie.qxzjddz')"
              class="input_width"
              v-model="form.address"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('yssh.sfmr')" prop="defaultFlag">
            <el-switch
              v-model="form.defaultFlag"
              active-color="#32bbd5"
            ></el-switch>
          </el-form-item>
        </el-form>
      </div>
      <div class="dia-footer">
        <div class="cancel" @click="cancel">
          {{ $t("casesDetail.casesChildren.casesDoKeep.qx") }}
        </div>
        <el-button
          class="confirm"
          :class="[isDis && 'form_wrap_content_btn_dis']"
          type="primary"
          @click="confirm"
          :disabled="isDis"
          :loading="confirmLoading"
          >{{ $t("casesDetail.casesChildren.casesDoKeep.qd") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { pickBy, some } from "lodash";
import { notifyMsg } from "common/js/util";
import {
  PublicSendAddressModify,
  PublicSendAddressCreate,
  PublicSendAddressDetailId
} from "common/api/cases";

export default {
  props: {
    visible: {
      required: true
    },
    isEdit: {
      required: true
    },
    editId: {
      required: false
    }
  },
  watch: {
    visible: {
      handler: function(val) {
        if (val) {
          if (this.isEdit) {
            this.getDetail(this.editId);
          }
        } else {
          this.form = {
            sender: "",
            provinces: "",
            address: "",
            phone: "",
            phoneArea: "",
            contactWay: "1",
            defaultFlag: "0"
          };
        }
      }
    }
  },
  data() {
    return {
      form: {
        sender: "",
        provinces: "",
        address: "",
        phone: "",
        phoneArea: "",
        contactWay: "1",
        defaultFlag: "0"
      },
      confirmLoading: false,
      cityData: JSON.parse(sessionStorage.getItem("cityData")),
      rules: {
        sender: [
          {
            required: true,
            message: this.$t("birdie.qtxjjr"),
            trigger: "blur"
          }
        ],
        provinces: [
          { required: true, message: this.$t("birdie.qxzdq"), trigger: "blur" }
        ],
        address: [
          {
            required: true,
            message: this.$t("birdie.qxzjddz"),
            trigger: "blur"
          }
        ]
      },
      loading: false,
      iphoneTypeOptions: [
        {
          value: "1",
          label: this.$t("cases.createUser.sjhm")
        },
        {
          value: "2",
          label: this.$t("birdie.gddh")
        }
      ]
    };
  },
  computed: {
    ...mapState({
      language: state => state.language
    }),
    isDis() {
      let {
        sender,
        provinces,
        address,
        phone,
        phoneArea,
        contactWay
      } = this.form;
      let currentPhone = +contactWay === 1 ? phone : !!phoneArea && !!phone;
      let list = [sender, currentPhone, provinces, address];
      return some(list, item => !item);
    }
  },
  methods: {
    getDetail(val) {
      this.loading = true;
      PublicSendAddressDetailId(val)
        .then(data => {
          let {
            province,
            city,
            area,
            sender,
            address,
            contactWay,
            phone,
            phoneArea,
            defaultFlag
          } = data;
          this.form = {
            provinces: [province, city, area],
            sender: sender,
            address: address,
            contactWay,
            phone: phone,
            phoneArea: phoneArea,
            defaultFlag: defaultFlag === "1" ? true : false
          };
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    optionsChange() {
      this.form.phone = "";
      this.form.phoneArea = "";
    },
    cancel() {
      this.$emit("cancel");
    },
    confirm() {
      if (this.isDis) return;
      this.$refs["Form"].validate(val => {
        if (val) {
          let {
            provinces,
            address,
            sender,
            contactWay,
            defaultFlag
          } = this.form;
          let params = pickBy({
            refType: !this.isEdit ? "DOCTOR" : "",
            refCode: !this.isEdit ? localStorage.getItem("userId") : "",
            id: this.isEdit ? this.editId : "",
            province: provinces[0],
            city: provinces[1],
            area: provinces[2],
            address,
            sender,
            contactWay,
            defaultFlag: !defaultFlag ? "0" : "1"
          });
          const reg_phone = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
          if (contactWay == 1 && !reg_phone.test(this.form.phone)) {
            return notifyMsg(this, "error", this.$t("common.message.qzqtxsjh"));
          }
          if (contactWay !== 1) {
            if (this.form.phoneArea && this.form.phone) {
              const areaPhonePass = /^0\d{2,4}$/.test(this.form.phoneArea);
              const phonePass = /^\d{7,8}$/.test(this.form.phone);
              if (!areaPhonePass || !phonePass) {
                if (!areaPhonePass) {
                  notifyMsg(this, "warning", this.$t("birdie.qhsryw"));
                  return Promise.reject(this.$emit("saveError"));
                }
                if (!phonePass) {
                  notifyMsg(this, "warning", this.$t("birdie.dhhmsryw"));
                  return Promise.reject(this.$emit("saveError"));
                }
              }
            }
          }
          params.phoneArea = this.form.phoneArea;
          params.phone = this.form.phone;
          let api = this.isEdit
            ? PublicSendAddressModify
            : PublicSendAddressCreate;
          this.confirmLoading = true;
          api(params)
            .then(() => {
              this.$emit("confirm");
              this.confirmLoading = false;
              return notifyMsg(
                this,
                "success",
                this.$t("login.retrievePaw.czcg")
              );
            })
            .catch(() => {
              this.confirmLoading = false;
            });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.address-edit {
  .dia-header {
    height: 58px;
    margin-bottom: 20px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    border-bottom: 1px solid #e5e5e5;
    color: #333333;

    .close-dia {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }
  }

  .dia-content {
    overflow: hidden;
    height: 400px;
    padding-left: 20px;
  }

  .dia-footer {
    padding: 36px 0 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    .confirm,
    .cancel {
      cursor: pointer;
      width: 176px;
      height: 42px;
      display: flex;
      color: #333333;
      border-radius: 6px;
      align-items: center;
      justify-content: center;
    }

    .confirm {
      border: none;
      background-color: $main_theme_color;
    }

    .form_wrap_content_btn_dis {
      border: none !important;
      background: #e5e5e5 !important;
      cursor: auto;
      color: #999999;
    }

    .cancel {
      border: 1px solid #bbbbbb;
      margin-right: 32px;
    }
  }
}

/deep/ .el-input__suffix {
  display: none;
}

/deep/ .el-dialog {
  border-radius: 8px;
}

/deep/ .address-edit-dia .el-dialog {
  margin-top: 15vh !important;
}

/deep/ .el-dialog__header {
  padding: 0px !important;
}

/deep/ .el-dialog__body {
  padding: 0px !important;
}

/deep/ .el-form-item__content .input_width_two .el-form-item__error {
  left: 10px !important;
}

/deep/ .el-input__inner {
  width: 316px;
  padding-right: 0.3rem;
}

.input_width_two {
  /deep/ .el-input__inner {
    width: 210px;
  }
}

.qh_input_width {
  /deep/ .el-input__inner {
    width: 88px;
  }
}

/deep/ .el-form {
  width: 100% !important;
}

/deep/ .el-form-item {
  margin-bottom: 30px;
}

/deep/ .el-form-item__label {
  color: #333333;
  font-size: 14px;
}

/deep/ .el-form-item__error {
  color: #ed4027;
}
</style>
